import React from 'react';
import './DownloadAppCard.css'
import RedBtnSolid from '../../../../shared/components/redbtn-solid/RedBtnSolid';

const DownloadAppCard = () => {
  return (
    <div className="download-app-card">
      <img src="assets/icons/CompanyLogo.svg" alt="" />
      <span>Get More Feature in your Palm</span>
      <a href="https://play.google.com/store/apps/details?id=com.app.travlpoint">
        <RedBtnSolid btnName={"Download"} />
      </a>
    </div>
  )
}

export default DownloadAppCard;