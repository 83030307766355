export const TOAST_DELETING = '[toast] toast deleting';
export const TOAST_DELETED_SUCCESS = '[toast] toast deleted success';
export const TOAST_DELETED_FAILURE = '[toast] toast deleted failure';

export const toastDeleting = (id) =>({
    type:TOAST_DELETING,
    payload:id,
})

export const toastDeletedSuccess = (data) =>({
    type:TOAST_DELETED_SUCCESS,
    payload:data,
})

export const toastDeletedFailure = (error) =>({
    type:TOAST_DELETED_FAILURE,
    payload:error,
})