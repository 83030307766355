import "./Navbar.css";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { NavItem, NavItemMob } from "../navItem/NavItem";
import { NavItemsData } from "../assets/navItemData";
import { NavbarHandler } from "../utils/NavbarHandler";
import Logout from "../../../shared/components/Logout/logout";
import useShowToast from '../../../shared/hooks/useShowToast'

const Navbar = () => {
  const { xtoken, location, getComponent, scrolling, MobileNav, toggleNav } =
    NavbarHandler();
    const NavItemData = NavItemsData(xtoken);
    const {showToast} = useShowToast();

  return (
    <div
      className={
        !MobileNav
          ? scrolling
            ? "navbar-wrapper navscrolled"
            : "navbar-wrapper"
          : "navbar-wrapper navbar-wr-active"
      }
    >
      <div className="navbar align-nav">
        <div className="navbar-logo">
          <Link to={{ pathname: "/" }}>
            <img
              src="assets/icons/Main_Logo.svg"
              alt=""
              className={!MobileNav ? "" : "nav-log-disable"}
            />
          </Link>
        </div>

        <div className="navbar-content align-nav">
          {NavItemData.map((item) => (
            <NavItem item={item} showToast={showToast} key={item.id}/>
          ))}
          {getComponent()}
        </div>

        {/* Hamburger_menu */}
        <div className="mob-menu" onClick={toggleNav}>
          {!MobileNav ? (
            <img
              className="Hamburger_menu"
              src="assets/icons/menu.svg"
              alt=""
            />
          ) : (
            <Close sx={{ color: "#E94560", fontSize: 40 }} />
          )}
        </div>
      </div>

      {/* mobile-view */}
      {MobileNav ? (
        <div className="navbar-mobile">
          <div className="navbar-content-mobile align-nav">
            {NavItemData.map((item) => (
              <NavItemMob item={item} toggleNav={toggleNav} showToast={showToast} key={item.id} />
            ))}

            {getComponent()}

            {xtoken ? (
              <>
                <div className="logout-line"></div>
                <div
                  className={
                    location.pathname === "/logout"
                      ? "nav-item nav-item-active"
                      : "nav-item hover-underline-animation "
                  }
                  onClick={toggleNav}
                >
                  <Logout />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Navbar;
