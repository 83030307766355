import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_FAILURE, LOGOUT_SUCCESS } from "../actions/getLogin"

const initialState = {
    token:{},
    error:null,
}

const loginReducers =(state = initialState, action)=> {
    switch(action.type){
        case LOGIN_SUCCESS:
            return{
                ...state,
                token:action.payload,
                error:null,
            }

        case LOGIN_FAILURE:
            return{
                ...state,
                token:action.payload,
                error:true
            }
        
        case LOGOUT_SUCCESS:
            return{
                ...state,
                token:initialState,
                error:null
            }
        
        case  LOGOUT_FAILURE:
            return{
                ...state,
                token:initialState,
                error:true
            }
        
        default:
            return state
    }
}

export default loginReducers