import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const NavItemsData = (xtoken) =>{
  const NavItemData = [
    {
      id: 1,
      path: "/",
      navName: "Home",
      icon: "",
    },
    {
      id: 2,
      path: "/package",
      navName: "Explore",
      icon: "",
    },
    {
      id: 3,
      path:xtoken ?"/wishlist":"",
      navName: "Wishlist",
      icon: "",
    },
    {
      id: 4,
      path: "/blog-articles",
      navName: "Blogs",
      icon: "",
    },
    {
      id: 5,
      path: "/cart",
      navName: "Cart ",
      icon: <ShoppingCartIcon className="cart-icon" />,
    },
  ];

  return NavItemData;
}

