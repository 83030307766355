import React from 'react';
import "./BlogBtn.css"

const BlogBtn = ({btnName}) => {
  return (
    <button className='blog-btn'>
        {btnName}
    </button>
  )
}

export default BlogBtn;