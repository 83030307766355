export const TRENDING_PACKAGE_LOADING = '[package] trending package loading';
export const TRENDING_PACKAGE_SUCCESS = '[package] trending package success';
export const TRENDING_PACKAGE_FAILURE = '[package] trending package failure';

export const trendingPackageLoading = {
    type:TRENDING_PACKAGE_LOADING,
}

export const trendingPackageSuccess = (data)=>({
    type:TRENDING_PACKAGE_SUCCESS,
    payload:data
})

export const trendingPackageFailure = (error)=>({
    type:TRENDING_PACKAGE_FAILURE,
    payload:error
})