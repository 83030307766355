import React from 'react';
import './UnderlineHeading.css'

const UnderlineHeading = ({heading}) => {
    return (
        <div className="underline-heading">
           {heading}
            <span className='underline-heading-line'></span>
        </div>
    )
}

export default UnderlineHeading;