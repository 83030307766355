import React from "react";
import "./TrendingSecCard.css";
import Whislist from "../../../../../shared/components/whishlist/Whislist"
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import usePopup from "../../../../../shared/hooks/usePopup";

const TredingSecCard = (props) => {
  const {getPopUpData} = usePopup();
  
  const settingBackground = {
    background: `url(${props.imgURL})no-repeat`,
    backgroundPosition: "center",
    backgroundSize: "cover"
  }

  const tempNum = Math.floor(Math.random() * (20 + 1)) + 1;

  const formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <>
      <div className="card">
        <div className="trend-img" id='imgt' style={settingBackground} >
          <div className="trend-wish">
            <Whislist Sl ={props.sl}/>
          </div>
        </div>
        <div className="trend-shade1"></div>
        <div className="trend-shade2"></div>
        <div className="trend-details">
          <div className="trend-place-info" >
            <p id="tpn">{props.name.length > 14 ? `${props.name.slice(0, 14)}..` : props.name}</p>
            <div className="trend-place-address">
              <p style={{ fontWeight: "600px" }}>{props.locationName}, {props.stateName}</p>
            </div>
          </div>
          <div className="trend-rate" id="tr-rate" 
            onClick={() => getPopUpData(props.sl)}
          >
            <LocalFireDepartmentIcon id='trending-icon' />
            <p> <span id="trend-booked" style={{ marginRight: "2px" }}>{formatCash(props.bookedby + tempNum)}</span> Peoples Already Booked  </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TredingSecCard;
