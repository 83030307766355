import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { tpIdLoading } from '../application/actions/getTPID';

const usePackageDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const packageDetails = (tpid) => {
        dispatch(tpIdLoading(tpid))
        navigate("/pack-details")
    }
  return {packageDetails}
}

export default usePackageDetails;