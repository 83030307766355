import React, { useState } from "react";
import "./App.css";
import '../src/styles/Style.css'
import { Routes, Route, HashRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import HomePage from "./features/home-page/views/HomePage.jsx";
import SearchPage from "./features/search-page/views/SearchPage.jsx";
import CategoryPage from "./features/category-page/CategoryPage.jsx";
import BlogPage from "./features/blog-page/BlogPage.jsx";
import BlogDetails from "./features/blog-page/views/blog-details/BlogDetails.jsx";
import PackagePage from "./features/package-page/PackagePage.jsx";
import PackageDetails from './features/package-page/views/package-details/PackageDetails.jsx'
import MainNavbar from "./features/MainNavbar/MainNavbar.jsx";
import LoginPage from "./features/login/views/LoginPage.jsx";
import WhislistPage from "./features/whishlist-page/WhislistPage.jsx";
import SignupPage from "./features/signup/views/SignupPage.jsx";
import Toast from "./features/Toast/Toast.jsx";
import Profile from "./features/profile-page/Profile.jsx"
import NotFound from "./shared/components/NotFoundPage/NotFound.jsx";
import useProfileData from "./features/profile-page/hooks/useProfileData.jsx";
import PrivacyTerms from "./features/privacy-terms-page/PrivacyTerms.jsx";
import AboutPage from "./features/about-page/AboutPage.jsx";
import Cart from "./features/cart-page/Cart.jsx";
import TripPage from "./features/trip-planing-page/TripPage.js";
import { getToastData } from "./features/Toast/applications/selectors/getToastData.jsx";
import SiteMap from "./features/sitemap/SiteMap.jsx";

function App() {
  const toastlist = useSelector(getToastData);
  useProfileData();
  
  const [category, setCategory] = useState("");
  function handleCategory(cate){
    setCategory(cate);
  }

  return (
    <HashRouter>
      {
        toastlist.length > 0 ?
          <Toast /> : <></>
      }
      <Routes>
        <Route path="/" element={<MainNavbar />}>
          <Route
            index
            element={
              <HomePage
                category={category}
                handleCategory={handleCategory}
              />
            }
          />
          <Route
            path="search"
            element={<SearchPage />}
          />
          <Route path="profile" element={<Profile/>} />
          <Route path="trip-planning" element={<TripPage />} />
          <Route path="cart" element={<Cart />} />
          <Route path="blog-articles" element={<BlogPage />} />
          <Route path="wishlist" element={<WhislistPage />} />

          <Route path="package" element={<PackagePage />} />

          <Route path="blog-articles/:title?/:id" element={<BlogDetails/>} />
          <Route path="terms-and-condition" element={<PrivacyTerms page="0" />} />
          <Route path="privacy-policy" element={<PrivacyTerms page="1" />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="sitemap" element={<SiteMap handleCategory={handleCategory}/>} />
          <Route path="pack-details" element={<PackageDetails />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="signup" element={<SignupPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="categories/:categories" element={<CategoryPage category={category}/>}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
