export const ReviewData = [
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2015/01/08/18/30/entrepreneur-593371_960_720.jpg",
      "Feedback": "Amazing Experience. Such a great app. This app makes my trip feel wonderful and awesome.",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Satya Prakash"
    },
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2016/11/29/09/38/adult-1868750_960_720.jpg",
      "Feedback": "Really a great work, it will help us in making travelling plan. Very smooth user experience.",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Parth Bahadur"
    },
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2015/04/20/17/39/woman-731894_960_720.jpg",
      "Feedback": "“Awesome Services and fabulous management, Loved the Experience”",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Pranshu Kumar"
    },
  ];