import './ViewButton.css'

const ViewButton = (props) => {
    const {handleViewAll,view} = props;
    
  return (
    <div className="view-all">
      <hr />
      <div
        className="view-all-btn"
        onClick={handleViewAll}
      >
        {!view ? "View all" : "View Less"}
      </div>
      <hr />
    </div>
  );
};

export default ViewButton;
