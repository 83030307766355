import React, { useState } from 'react';
import './LoginSignUpInput.css'

const LoginSingupInput = (props) => {
    const { inputType, inputPlaceholder,handleKeyDown, inputIcon, lockOpenIcon, visibilityIcon, visibilityOffIcon,value,setInput,errors } = props;
    const [visibility, setVisibilty] = useState(inputType);
    const toggleVisibility = () => {
        const changeInputType = (visibility === "password") ? "text" : "password";
        setVisibilty(changeInputType)
    }

    return(
        <div className="input-field">
            <div className="input-field-icon">
                {
                    (inputType === "password" && visibility === "text") ?lockOpenIcon :inputIcon
                }
            </div>
            <input type={visibility} placeholder={inputPlaceholder} value={value}  
            onChange={(e)=> setInput(e.target.value)}
            // onChange={handleChange}
            className={errors?"error":""}
            onKeyDown={handleKeyDown}
            />
            {
                visibility === "password" ?
                    <div className="password-eye" onClick={toggleVisibility}>{visibilityOffIcon}</div> :
                    <div className="password-eye" onClick={toggleVisibility}>{visibilityIcon}</div>
            }
        </div>
    )
}

export default LoginSingupInput;