//reducers
import { PACKAGE_REVIEW_BY_ID_FAILURE, PACKAGE_REVIEW_BY_ID_SUCCESS } from "../actions/getPackageReviewById";

const initialState = {
    packageReviewById: null,
    error: null,
};

const PackageReviewByIdReducers = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_REVIEW_BY_ID_SUCCESS:
            return {
                ...state,
                packageReviewById: action.payload,
                error: null,
            };
        case PACKAGE_REVIEW_BY_ID_FAILURE:
            return {
                ...state,
                packageReviewById: null,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default PackageReviewByIdReducers;
