import { setLoading } from "../../../../shared/application/actions/ui";
import { TOAST_LOADED, toastFailure, toastSuccess } from "../actions/getToast";
import { TOAST_DELETING, toastDeletedFailure, toastDeletedSuccess } from "../actions/getToastDeleted";

const getToastFlow = () => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === TOAST_LOADED) {
        try {
            // dispatch(setLoading(true))
            dispatch(toastSuccess(action.payload));
        } catch (error) {
            dispatch(toastFailure(error));
            // dispatch(setLoading(false))
        }
    }

}

const deleteToastFlow = () => ({ dispatch }) => next => async (action) => {
    next(action);
    if (action.type === TOAST_DELETING) {
        try {
            dispatch(toastDeletedSuccess(action.payload));
            // dispatch(setLoading(false))
        } catch (error) {
            dispatch(toastDeletedFailure(error));
            // dispatch(setLoading(false))
        }
    }
}

const toastsFlow = [
    getToastFlow,
    deleteToastFlow,
]
export default toastsFlow