import { setLoading } from "../../../../shared/application/actions/ui";
import { LIKE_BLOG_LOADING, likeBlogFailure, likeBlogSuccess } from "../actions/getBlogLiked";
import { UNLIKE_BLOG_LOADING, unlikeBlogFailure, unlikeBlogSuccess } from "../actions/getBlogUnliked";

const likeBlogFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);
    if(action.type === LIKE_BLOG_LOADING){
        try {
            dispatch(setLoading(true));
            const response = await api.blogApi.likeBlog(action.payload.blogId);
            dispatch(likeBlogSuccess(response));
            dispatch(setLoading(false));
        } catch (err) {
            dispatch(likeBlogFailure( err));
            dispatch(setLoading(false));
        }
    }
};

const unlikeBlogFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);
    if(action.type === UNLIKE_BLOG_LOADING){
        try {
            dispatch(setLoading(true));
            const response = await api.blogApi.unlikeBlog(action.payload.blogId);
            dispatch(unlikeBlogSuccess( response));
            dispatch(setLoading(false));
        } catch (err) {
            dispatch(unlikeBlogFailure( err));
            dispatch(setLoading(false));
        }
    }
};

const blogsLikeUnlikeFlow = [
    likeBlogFlow,
    unlikeBlogFlow
]
export default blogsLikeUnlikeFlow; 