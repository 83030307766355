import {useState} from 'react'

const useRatingFilter = () => {
    /////////////////////////// Range Slider Values ////////////////////////////////////////////
    const [rating, setRating] = useState({ min: 0, max: 5 });
    const handleRatingClear = ()=>{
        setRating({ min: 0, max: 5 })
    }
    return {rating, setRating,handleRatingClear};
}

export default useRatingFilter;