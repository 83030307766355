import { useDispatch, useSelector } from 'react-redux';
import {
  addPackageUrl,
  addSinglePlaceUrl,
  removePackageUrl,
  removeSinglePlaceUrl,
} from '../../../assets/urls';
import useShowToast from '../../../shared/hooks/useShowToast';
import useRefreshToken from '../../../shared/hooks/useRefreshToken';
import {
  wishlistLoaded,
} from '../applications/actions/getWishlist';
import { getWishlistData, get_p_Wishlist, get_s_Wishlist } from '../applications/selectors/getWishlist';
import { useEffect, useState } from 'react';

const UseWishlist = (props) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const xtokens = JSON.parse(localStorage.getItem('xtoken'));
  const dispatch = useDispatch();

  const s_wishlist_data = useSelector(get_s_Wishlist);
  const p_wishlist_data = useSelector(get_p_Wishlist);
  const wishlistData = useSelector(getWishlistData);
  const { showToast } = useShowToast();

  useEffect(() => {
    if (xtokens && wishlistData) {
      if (props.Sl && s_wishlist_data.sWishlist.includes(props.Sl)) {
        setIsFavorite(true);
      } else if (props.TP_ID && p_wishlist_data.pWishlist.includes(props.TP_ID)) {
        setIsFavorite(true);
      }
    }
  }, [s_wishlist_data]);

  const { handleRefreshToken } = useRefreshToken();

  const updateToken = (response1) => {
    handleRefreshToken(response1, xtokens.refreshToken);
  };

  const handleWishlistAction = async (url, successMessage,add) => {
    const options = {
      method: add?'POST':'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: xtokens.accessToken,
      },
      body: props.Sl && props.Sl.slice(0, 2) !== 'TP' ? JSON.stringify({ sl: props.Sl }) : JSON.stringify({ tpid: props.TP_ID }),
    };

    try {
      const response = await fetch(url, options).then((res) => res);
      if (response.status === 201 || response.status === 200) {
        setIsFavorite(!isFavorite);
        dispatch(wishlistLoaded);
        showToast('success', successMessage);
      } else {
        updateToken(response);
      }
    } catch (err) {
      showToast('info', 'Something went wrong. Try again.');
    }
  };

  const getWishlist = async () => {
    if (!isFavorite) {
      const url = props.Sl && props.Sl.slice(0, 2) !== 'TP' ? addSinglePlaceUrl : addPackageUrl;
      handleWishlistAction(url, 'Place Added to Wishlist Successfully.',true);
    } else {
      const url = props.Sl && props.Sl.slice(0, 2) !== 'TP' ? removeSinglePlaceUrl : removePackageUrl;
      handleWishlistAction(url, 'Place Removed from Wishlist Successfully.',false);
    }
  };

  const handlingWishlist = () => {
    if (xtokens) {
      getWishlist();
    } else {
      showToast('info', 'Please Login to Your Account');
    }
  };

  return { handlingWishlist, IsFavorite:isFavorite};
};

export default UseWishlist;
