import "./Cart.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link } from "react-router-dom";

import CartCard from "./components/cart-card/CartCard";
import Copyright from "../../shared/components/copyright/Copyright";
import useCart from "./hooks/useCart";
import ErrorHandler from "../../shared/components/error/ErrorHandler";

const Cart = () => {
    const {
        cartError,
        selectedPlaces,
        tpid,
        recomendedCartError,
        recommendedCart,
        deleteCartData,
        updateCopySelectedPlaces,
        startDateHandler,
        startDate,
        endDateHandler,
        endDate,
        addSubtract,
        setPeople,
        adults,
        children,
        copySelectedPlaces
    } = useCart();


    if (!selectedPlaces && !recommendedCart) {
        return (
            <>
                <div className="Loading">
                    <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
                </div>
            </>
        );
    }

    if (cartError || recomendedCartError) {
        return <ErrorHandler />
    }

    return (
        <div className="cart-wrapper">
            {(selectedPlaces.length != 0 && recommendedCart.length != 0 && !cartError) ? (
                <div className="cart-body">
                    <div className="cart-cards">
                        {/* My Plan */}
                        <div className="cart-myPlan">
                            <h3 className="cart-title">My Plan</h3>
                            <div className="cart-slider">
                                {recommendedCart.map((item, i) =>
                                    selectedPlaces.includes(item["sl"]) ? (
                                        <CartCard
                                            key={i}
                                            added={false}
                                            data={item}
                                            tpid={tpid}
                                            updateCopySelectedPlaces={updateCopySelectedPlaces}
                                            deleteCartData={deleteCartData}
                                            selectedPlaces={selectedPlaces}
                                        />
                                    ) : null
                                )}
                            </div>
                        </div>
                        {/* Recommended Places */}
                            <div className="cart-recommended">
                                <h3 className="cart-title">Recommended Places</h3>
                                <div className="cart-slider">
                                    {recommendedCart.map((item, i) =>
                                        !selectedPlaces.includes(item["sl"]) ? (
                                            <CartCard
                                                key={i}
                                                added={true}
                                                data={item}
                                                tpid={tpid}
                                                updateCopySelectedPlaces={updateCopySelectedPlaces}
                                                deleteCartData={deleteCartData}
                                                selectedPlaces={selectedPlaces}
                                            />
                                        ) : null
                                    )}
                                </div>
                            </div>
                    </div>
                    <div className="cart-sidebar">
                        {/* Cart Details */}
                        <div className="cart-details">
                            <div className="cart-Key">Start Date</div>
                            <div className="cart-Date">
                                <input
                                    type="date"
                                    id="cart-Start-Date"
                                    value={startDate}
                                    onChange={startDateHandler}
                                />
                                <div className="cart-date-icon">
                                    <CalendarTodayIcon sx={{ color: "#FBCC7A" }} />
                                </div>
                            </div>
                            <div className="cart-Key">End Date</div>
                            <div className="cart-Date">
                                <input
                                    type="date"
                                    id="cart-end-Date"
                                    value={endDate}
                                    onChange={endDateHandler}
                                />
                                <div className="cart-date-icon">
                                    <CalendarTodayIcon sx={{ color: "#FBCC7A" }} />
                                </div>
                            </div>
                            {/* ... Other details ... */}
                            <div className="cart-Key">Adults</div>
                            <div className="cart-peoples">
                                <div
                                    className="cart-minus"
                                    onClick={() => addSubtract("adults", "sub")}
                                >
                                    <RemoveIcon sx={{ color: "#C4C4C4" }} />
                                </div>
                                <p className="cart-NOP">
                                    {setPeople(adults)}
                                </p>
                                <div
                                    className="cart-add"
                                    onClick={() => addSubtract("adults", "add")}
                                >
                                    <AddIcon sx={{ color: "#FBCC7A" }} />
                                </div>
                            </div>
                            <div className="cart-Key">Childrens</div>
                            <div className="cart-peoples">
                                <div
                                    className="cart-minus"
                                    onClick={() => addSubtract("children", "sub")}
                                >
                                    <RemoveIcon sx={{ color: "#C4C4C4" }} />
                                </div>
                                <p className="cart-NOP">
                                    {setPeople(children)}
                                </p>
                                <div
                                    className="cart-add"
                                    onClick={() => addSubtract("children", "add")}
                                >
                                    <AddIcon sx={{ color: "#FBCC7A" }} />
                                </div>
                            </div>
                            <div className="cart-quotes">
                                “Travel makes one modest. You see what a tiny place you occupy
                                in the world.”
                            </div>
                            <div className="cart-writer">~Gustave Flaubert</div>
                        </div>

                        {/* Plan Trip Link */}
                        <Link
                            to="/trip-planning"
                            state={{
                                data: {
                                    tpid,
                                    sdate: startDate,
                                    edate: endDate,
                                    places: copySelectedPlaces,
                                    adults,
                                    childrens: children,
                                },
                            }}
                            className="cart-plan-link"
                        >
                            <div className="cart-planButton">
                                Plan Trip
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="cart-body cart-center-flex">
                    <img src="./assets/images/error.png" className="cart-empty-img" alt="" />
                    <p className="cart-emty-msg">Ooops...Your Cart Seems Empty..!</p>
                    <div className="cart-empty-text">Let's Find Your Dream Destination !</div>
                    <Link to={{ pathname: "/search" }} className="cart-empty-button">
                        Find Now
                    </Link>
                </div>
            )}
            <Copyright />
        </div>
    );
};

export default Cart;
