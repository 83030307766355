import {SINGLE_WISHLIST_ITEMS_FAILURE, SINGLE_WISHLIST_ITEMS_SUCCESS} from "../actions/getSingleWishlistItems"

const initialState ={
    singleWishlistItems:[],
    error:null
}

const singleWishlistReducer =(state=initialState, action)=>{
    switch(action.type){
        case SINGLE_WISHLIST_ITEMS_SUCCESS:
            return{
                ...state,
                singleWishlistItems:action.payload,
                error:null,
            }

        case SINGLE_WISHLIST_ITEMS_FAILURE:
            return{
                ...state,
                singleWishlistItems:action.payload,
                error:true
            }
        
        default:
            return state
    }
}

export default singleWishlistReducer;