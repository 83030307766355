import { connect } from "react-redux";
import "./CartCard.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useIconHandler from "../../hooks/useIconHandler";
import { trimData } from "../../utils/trimData";

const CartCard = (props) => {
    const {added,iconHandler } = useIconHandler(props);

    const removeStyle = {
        background: "rgba(242, 78, 28, 0.36)",
        border: "2px solid #F24E1E",
        borderRadius: "50%",
    };

    const addStyle = {
        background: "rgba(15, 109, 0, 0.49)",
        border: "2px solid #24FF00",
        borderRadius: "50%",
    };

    return (
        <>
            <div
                className={added ? "cart-card" : "cart-card cart-mob-border"}
                style={{
                    background: `url(${props.data["image1"]}) center / cover no-repeat`,
                }}
            >
                <div className="cateCard-Lg">
                    <p className="cartCard-title">
                        {trimData(props.data["place"], 16)}
                    </p>
                    <p className="cartCard-location">
                        {trimData(
                            props.data["locality"] + ", " + props.data["state"],
                            24
                        )}
                    </p>
                </div>
                <div
                    className="cateCard-icon"
                    onClick={() => iconHandler(props.data["sl"])}
                    style={added ? addStyle : removeStyle}
                >
                    {added ? (
                        <AddIcon sx={{ color: "#24FF00" }} />
                    ) : (
                        <RemoveIcon sx={{ color: "#F24E1E" }} />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return { cart: state.cartData.cartData };
};

export default connect(mapStateToProps)(CartCard);
