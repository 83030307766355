import React, { useState } from "react";
import "./WhislistCard.css";
import { Rating } from "@mui/material";
import Whislist from "../../../../shared/components/whishlist/Whislist";
import usePopup from "../../../../shared/hooks/usePopup";
import usePackageDetails from "../../../../shared/hooks/usePackageDetails";
import { PlanTrip } from "../../../../shared/utils/planTrip";
import { ScreenWidthUpdater } from "../../../../shared/utils/screenWidthUpdater";

const WhislistCard = (props) => {
  const { getPopUpData } = usePopup();
  const {packageDetails} = usePackageDetails();
  const {planTrip,singlePlacePlanTrip} = PlanTrip(props.Sl ? props.Sl : props.TP_ID);
  const [notifyMessage, setNotifyMessage] = useState("A");

  const {screenWidth} = ScreenWidthUpdater();

  function setName(Place) {
    if (Place.length <= 15) {
      return Place;
    } else {
      if(screenWidth<1024){
        return Place.slice(0, 12) + "...";
      }
      return Place.slice(0, 14) + "...";
    }
  }

  return (
    <div className="whislist-card">
      <div className="wl-icon">
        <Whislist Sl={props.Sl} TP_ID={props.TP_ID} />
      </div>
      <div
        className="whislist-card-top"
        onClick={() => {
          props.Sl
            ? getPopUpData(props.Sl)
            : packageDetails(props.TP_ID)
        }}
        style={{
          background: `url(${props.img}) no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="wl-pl-details">
          <p id="name">{setName(props.place)}</p>
          <p id="address">{setName(props.locality + " " + props.state)}</p>
        </div>
      </div>
      <div className="whislist-card-bottom">
        <Rating
          name="size-large"
          value={props.items.rating}
          size="small"
          id="rate"
          readOnly
        />
        <div className="add-plan" onClick={props.Sl ? () => singlePlacePlanTrip(props.items) : planTrip }>
          {notifyMessage === "A" ? (
            "Add To Plan"
          ) : (
            <>
              <div
                className="Loading"
                style={{ width: "120px", height: "18px" }}
              >
                <img
                  src="assets/images/Icon_Animation.gif"
                  alt="logo"
                  style={{ width: "40px", height: "40px" }}
                ></img>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhislistCard;
