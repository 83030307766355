import './SinglePlace.css'
import usePopup from '../../../shared/hooks/usePopup';
import SinglePlaceDetails from './single-place-details/SinglePlaceDetails';

const SinglePlace = () => {
    const { popUpHandler } = usePopup();
    return (
        <>
            <div
                onClick={() => popUpHandler()}
                className="search-overlay"
            ></div>
            <div className="search-popup-content">
                <SinglePlaceDetails />
            </div>
        </>
    )
}

export default SinglePlace;