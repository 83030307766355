import React from 'react'
import './TopPlaceCard.css'
import RedBtnSolid from '../../../../shared/components/redbtn-solid/RedBtnSolid';
import RedBtnWithBorder from '../../../../shared/components/red-btn-with-border/RedBtnWithBorder';
import Whislist from '../../../../shared/components/whishlist/Whislist';
import RatingStar from '../../../../shared/components/rating-star/RatingStar';
import usePackageDetails from '../../../../shared/hooks/usePackageDetails';

const TopPlaceCard = (props) => {
    let { tpId, sl, image, name, rating, totalPlaces, address, locality, state,placeData } = props;
    
    function setName(Place, value){
        if (Place.length <= value){
            return Place;
        }else{
            return Place.slice(0, value - 1) + "...";
        }
    }

    const {packageDetails} = usePackageDetails();

    return (
        <div className="top-place-card" >
            <img src={image} className='tpc-img' alt="" />
            <Whislist
                Sl={sl}
                TP_ID={tpId}
            />
            <div className="tpc-details">
                <div className="tpc-top">
                    <div className="tpc-title"> {setName(name, 14)} </div>
                    <RatingStar rating={rating} />
                </div>
                <div className="tpc-address align-item">
                    <img src="assets/icons/location.svg" alt="" />
                    {
                        (locality && state) ?
                            <span>{setName(locality, 12)}, {setName(state, 12)}</span>
                            :
                            <span>{setName(address, 20)}</span>
                    }
                </div>
                {
                    !sl ?
                        <div className="tpc-locations align-item">
                            <img src="assets/icons/locations.svg" alt="" />
                            <span>{totalPlaces} Places to Visit</span>
                        </div>
                        :
                        <></>
                }
                <div className="tpc-btns">
                    <RedBtnSolid
                        Sl={sl}
                        TP_ID={tpId}
                        btnName={"Add To Plan"}
                        placeData = {placeData}
                    />
                    <RedBtnWithBorder
                        btnName={"More Info"}
                        tpId={tpId}
                        sl={sl}
                        PackageDetails={packageDetails}
                    />
                </div>
            </div>
        </div>
    )
}

export default TopPlaceCard;