import "./BlogPage.css";
import { useFetchData } from "./hooks/useFetchData";
import BlogBanner from "./views/blog-banner/BlogBanner";
import useBlogData from "./hooks/useBlogData";
import useBlogSearch from "./hooks/useBlogSearch";
import ErrorHandler from "../../shared/components/error/ErrorHandler";
import Footer from "../../shared/components/footer/Footer";
import Loading from "../../shared/components/loading/Loading";
import { lazy, Suspense } from "react";
const BlogSearched = lazy(() => import("./views/blog-searched/BlogSearched"));
const LatestBlog = lazy(() => import("./views/latest-blog/LatestBlog"));
const BlogTrendingPost = lazy(() =>
  import("./views/blog-Trending-Post/BlogTrendingPost")
);
const MoreBlogs = lazy(() => import("./views/more-blogs/MoreBlogs"));

const BlogPage = () => {
  useFetchData();
  const { AllBlogs, blogDataError } = useBlogData();

  const {
    blogSearchError , 
    searchkey,
    searchIndicator,
    searchData,
    blogSearchHandler,
    handleBlogSearch,
  } = useBlogSearch();

  let isPreviousQuery = localStorage.getItem("blogQuery")===searchkey;
  if (blogDataError||blogSearchError) {
    return <ErrorHandler />;
  }
  if (AllBlogs.length === 0) {
    return (
      <div className="Loading">
        {/* <img src="assets/images/Icon_Animation.gif" alt="logo"></img> */}
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        <div className="Blog_Body_Wrapper">
          {/* Blog-banner */}
          <BlogBanner
            searchkey={searchkey}
            blogSearchHandler={blogSearchHandler}
            handleBlogSearch={handleBlogSearch}
          />

          {/* searched card appearance code */}
          {(searchkey.length > 0) &&(isPreviousQuery)&& (searchData && searchData.status) ? (
            searchIndicator ? (
              <div className="Loading">
                <Loading />
              </div>
            ) : searchData && searchData.data.noOfResult > 0 ? (
              <Suspense fallback={<Loading />}>
                <BlogSearched
                  searchData={searchData && searchData.data}
                  searchkey={searchkey}
                />
              </Suspense>
            ) : (
              <div className="no-result-fount">Oops, No Result Found....</div>
            )
          ) : (
            // blog Cards
            <Suspense fallback={<Loading />}>
              <LatestBlog AllBlogData={AllBlogs} />

              <BlogTrendingPost AllBlogData={AllBlogs} />

              <MoreBlogs AllBlogData={AllBlogs} />
            </Suspense>
          )}
        </div>
        <Footer />
      </>
    );
  }
};

export default BlogPage;
