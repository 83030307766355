import React from "react";
import "./PackageCard.css";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import Whislist from "../../../../shared/components/whishlist/Whislist";

const packageCard = (props) => {
  const BackgroundImage = {
    background: `url(${props.data.coverPicture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  return (
    <div className="package-card-wrapper">
      <div className="package-card">
        <div
          className="package-card-image"
          style={BackgroundImage}
          onClick={() => props.PackageDetails(props.data["tpId"])}
        >
          <div
            className="pcard-rating"
            style={{
              backgroundColor: "#ffffff36",
              color: "#fbcc7a",
              opacity: "1",
            }}
          >
            <StarBorderPurple500Icon
              style={{ fontSize: "19px", marginRight: "4px" }}
            />{" "}
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {props.data.rating}
            </span>
          </div>
          <div className="package-detail">
            <div className="package-name">{props.data.name}</div>
            <div className="package-wishlist">
              <Whislist
                package={"true"}
                TP_ID={props.data.tpId}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default packageCard;
