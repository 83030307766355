import './Status.css'
import ProgressBar from '../../components/progress-bar/ProgressBar';
import { BadgeData } from '../../assets/BadgeData';

const Status = (props) => {
    const frequency_stroke_color = "#55CDEC";
    const frequency_tail_color = "#b7e0e8";
    const strike_rate_stroke_color = "#EF5DA8";
    const strike_rate_tail_color = "#f9d2e7";
    const frequency_data = props.profileData.frequency[0];
    const strike_rate_data = props.profileData.strikeRate[0];
    
    return (
        <div className="StatusPage">
            <div className="Status-top">
                <div className="status-heading">
                    <p>My Stats</p>
                </div>
                <div className="Status-card">
                    <div className="frequency">
                        <ProgressBar
                            stroke_color={frequency_stroke_color}
                            tail_color={frequency_tail_color}
                            percentage={frequency_data}
                            name="Frequency"
                        />
                    </div>
                    <div className="strike-rate">
                        <ProgressBar
                            stroke_color={strike_rate_stroke_color}
                            tail_color={strike_rate_tail_color}
                            percentage={strike_rate_data}
                            name="Strike Rate"
                        />
                    </div>
                </div>
            </div>
            <div className="Status-bottom">
                <div className="status-heading">
                    <p>My Badges</p>
                    <p id='Status-view-all'>View All</p>
                </div>
                <div className="Status-Badges">
                    {BadgeData.map((item) => (
                        <div className="Status-Badge" key={item.img}>
                            <img src={item.Image} alt="" />
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
export default Status;
