import { updateProfileDataUrl } from "../../../assets/urls"

const useUpdateProfileApi = (isValid, PDetails,profileData,setUpdate,setUpdatedetail) => {
    
    const UpdateData = async()=>{
        setUpdatedetail(true)
        const xtokens = JSON.parse(localStorage.getItem('xtoken'))
        const url = updateProfileDataUrl;
        const options = {
            method:"PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": xtokens.accessToken
            },
            body: JSON.stringify({
                'firstName':PDetails.FirstName,
                'lastName': PDetails.LastName,
                'email':PDetails.Email,
                'phone': PDetails.Phone,
                'dateOfBirth':PDetails.dob,
                'gender':profileData.gender
          }),
        }
        try{
            const res = await fetch(url,options)
            if(res.ok){
                isValid()
                setUpdatedetail(false)
                setTimeout(() => {
                    setUpdate(false)
                  }, 1000)
            }else{
                throw new Error(res.message)
            }
        }catch(e){
            console.log(e);
        }
    }
  return {UpdateData}
}

export default useUpdateProfileApi