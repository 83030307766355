import React from 'react';
import './DownloadBtn.css'

const DownloadBtn = ({ imgUrl, type,btnName1, btnName2 }) => {
    return (
        <div className="download-app-btn" >
            {
                (type === 'play-store') ?
                    <>
                        <img src={imgUrl} alt="" className='red-btn-icon' />
                    </> :
                    <>
                        <img src={imgUrl} alt="" className='red-btn-icon' />
                    </>
            }
            {/* <ArrowCircleRightOutlinedIcon className='red-btn-icon' /> */}
            <p id="download-app-txt"> <span>{btnName1}</span>{btnName2}</p>
        </div>
    )
}

export default DownloadBtn;