import React from 'react';
import './WeekRow.css'
import TodayIcon from '@mui/icons-material/Today';
import PlaceTiming from '../place-timing/PlaceTiming';
import { ScreenWidthUpdater } from '../../../../shared/utils/screenWidthUpdater';

const WeekRow = ({ type, holiday ,openingTime ,closingTime}) => {
    const weekDays = [
        {
            "idx": 1,
            "day": "M"
        },
        {
            "idx": 2,
            "day": "T"
        },
        {
            "idx": 3,
            "day": "W"
        },
        {
            "idx": 4,
            "day": "T"
        },
        {
            "idx": 5,
            "day": "F"
        },
        {
            "idx": 6,
            "day": "S"
        },
        {
            "idx": 0,
            "day": "S"
        }
    ]

    // const checkHoliday = 

    const { screenWidth } = ScreenWidthUpdater();
    return (
        <div className="week-row">
            {
                (screenWidth <= 600) ?
                    <>
                        <div className="calendar-with-time">
                            <div className="calendar">
                                <TodayIcon className="wr-calendar" />
                                <div className="week-days">
                                    {
                                        weekDays.map((data) => (
                                            <div
                                                className={holiday.length === 0 ? "weekday" : holiday.includes(data.idx) ? "weekday inactive-weekDay " : "weekday"}
                                                key={data.idx}>{data.day}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <PlaceTiming type={type} openingTime={openingTime} closingTime={closingTime} />
                        </div>
                    </>

                    :
                    <>
                        <TodayIcon className="wr-calendar" />
                        <div className="week-days">
                            {
                                weekDays.map((data) => (
                                    <div
                                        className={holiday.length === 0 ? "weekday" : holiday.includes(data.idx) ? "weekday inactive-weekDay " : "weekday"}
                                        key={data.idx}>{data.day}</div>
                                ))
                            }
                        </div>
                    </>
            }

        </div>
    )
}

export default WeekRow;