import getHomePageData from './get-homepage-data/getHomePageData'
import getSubscription from './getSubscription/getSubscription'
import logInApi from '../../../features/login/services/logInApi'
import refresTokenApi from './auth'
import signUpApi from '../../../features/signup/services/signUpApi'
import validateDataApi from '../../../features/signup/services/validateDataApi'
import wishlistApi from '../../../features/wishlist/services/wishlistApi'
import cartApi from '../../../features/cart-page/services/cartApi'
import searchwithFilterApi from '../../../features/search-page/services/searchwithFilterApi'
import blogApi from '../../../features/blog-page/services/blogApi'
import singlePlaceApi from '../../../features/single-place-page/services/singlePlaceApi'
import packageApis from  "../../../features/package-page/services/packageApis"
import singleWishlistItemsApi from "../../../features/whishlist-page/services/singleWishlistApi"
import packageWishlistItemsApi from "../../../features/whishlist-page/services/packageWishlistApi"

export default {
    getHomePageData,
    getSubscription,
    logInApi,
    validateDataApi,
    signUpApi,
    refresTokenApi,
    wishlistApi,
    cartApi,
    searchwithFilterApi,
    blogApi,
    singlePlaceApi,
    packageApis,
    singleWishlistItemsApi,
    packageWishlistItemsApi
}