import React from 'react';
import './DownloadApp.css'
import DownloadBtn from '../../../../shared/components/download-btn/DownloadBtn';
import UnderlineHeading from '../../../../shared/components/underline-heading/UnderlineHeading';
import { getAppUrl } from '../../../../assets/urls';

const DownloadApp = () => {
    return(
        <div className="download-app-wrapper">
            <div className="download-app">
               <UnderlineHeading heading={"Download Travlpoint Mobile App"}/>
                <div className="download-app-body">
                    <div className="download-app-left">
                        <div className="description-heading">Get more on your device</div>
                        <div className="d-app-description">"Get our user-friendly app now for seamless travel planning. Access exclusive deals, real-time updates, and personalised recommendations, ensuring an unforgettable journey. <br />
                            Simplify booking and enjoy a hassle-free travel experience, all in the palm of your hand."</div>
                        <div className="download-btns">
                           <a href={getAppUrl}>  <DownloadBtn btnName1={"Google "} btnName2={"Play"} imgUrl={"assets/icons/play-store.svg"} btnType={"play-store"} /></a>
                            <DownloadBtn btnName1={"App "} btnName2={"Store"} imgUrl={"assets/icons/app-store.svg"} btnType={"play-store"} />
                        </div>
                    </div>
                    <div className="download-app-right">
                        <div className="d-images">
                            <img src="assets/images/Schedule 1.svg" alt="" className="download-app-img2" />
                            <img src="assets/images/Home 1.svg" alt="" className="download-app-img1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadApp;