import { setLoading } from "../../../../shared/application/actions/ui";
import { TRENDING_PACKAGE_LOADING, trendingPackageFailure, trendingPackageSuccess } from "../actions/getTrendingPackage";

const trendingPackageFlow = ({api}) => ({dispatch}) =>next=>async(action)=>{
    next(action);

    if(action.type === TRENDING_PACKAGE_LOADING){
        try {
            dispatch(setLoading(true));
            const response = await api.packageApis.trendingPackage();
            // console.log("middle",response);
            dispatch(trendingPackageSuccess(response));
            dispatch(setLoading(false));
        } catch(error){
            dispatch(trendingPackageFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const trendPackageFlow  = [
    trendingPackageFlow
]

export default  trendPackageFlow ;
