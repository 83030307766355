import { SEARCH_BLOGS_FAILURE, SEARCH_BLOGS_SUCCESS } from "../actions/getSearchBlogs";

const initialState = {
    searchedBlogs:[],
    error:null
}

const SearchBlogReducers = (state=initialState, action) =>{
    switch(action.type){
        case SEARCH_BLOGS_SUCCESS:
            return {
                ...state,
                searchedBlogs:action.payload,
                error:null,
            }

        case SEARCH_BLOGS_FAILURE:
            return {
                ...state,
                searchedBlogs:action.payload,
                error:true,
            }
        
        default:
            return state;
    }
}

export default SearchBlogReducers;