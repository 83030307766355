import { useState } from "react";

export const WishlistContainerHandler = (WishlistItem) => {
    const [view, setView] = useState(false);
    const [slice, setSlice] = useState(8);
  
    const handleViewAll = () => {
      setView(!view);
      !view ? setSlice(WishlistItem&&WishlistItem.length) : setSlice(8);
    };
  
    return { handleViewAll, slice, view };
  };