import { SUBSCRIPTION_LOADED, getSubscriptionFailure, getSubscriptionSuccess } from "../actions/getSubscription";
import { setLoading } from "../actions/ui";


const subscriptionFlow =({api})=>({dispatch})=>next =>async(action)=>{
    next(action);

    if(action.type === SUBSCRIPTION_LOADED){
        try{
            dispatch(setLoading(true));
            let response;
            if(action.payload.type === "News"){
                response = await api.getSubscription.getSubscription(action.payload.email);
            }else if(action.payload.type === "Blog"){
                response = await api.getSubscription.getBlogSubscription(action.payload.email);
            }
            dispatch(getSubscriptionSuccess(response))
            dispatch(setLoading(false));
        }catch(error){
            dispatch(getSubscriptionFailure(error))
        }
    }
}

const subsFlow = [
    subscriptionFlow
]

export default subsFlow ;