import { HOME_DATA, homeDataFailure, homeDataSuccess } from "../actions/getHomePageData";
import { setLoading } from "../actions/ui";

const homeDataFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === HOME_DATA) {
        try {
            dispatch(setLoading(true));
            const response = await api.getHomePageData.getAllData(action.payload);
            dispatch(homeDataSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(homeDataFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const homeDFlow = [
    homeDataFlow
]
export default homeDFlow;