import { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { planNewTripUrl } from '../../../assets/urls';

const useCreateTrip = () => {
    const location = useLocation();
    const [tripPopUp, settripPopUp] = useState(false);
    const [tripDetail, setTripDetail] = useState([]);

    const tripdata = location.state.data;
    const xtokens = JSON.parse(localStorage.getItem("xtoken"));
    const createTrip = async (Data) => {
        if (xtokens){
          settripPopUp(!tripPopUp);
          // const options = {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: xtokens.accessToken,
          //   },
          //   body: JSON.stringify({
          //     tpid: tripdata.tpid,
          //     Sdate: tripdata.sdate,
          //     Edate: tripdata.edate,
          //     Places: JSON.stringify(tripdata.places),
          //     TripName: tripDetail[0].Name,
          //     Schedule: JSON.stringify(Data),
          //     Status: 1,
          //     Success: 1,
          //     Adults: tripdata.adults,
          //     Childrens: tripdata.childrens,
          //     CoverPicture: tripDetail[0].CoverPicture,
          //   }),
          // };

          // try {
          //   const res = await fetch(planNewTripUrl, options)
          //   const data = await res.json();

          //   if(res.ok){
          //     settripPopUp(!tripPopUp);
          //   }else{
          //     throw new Error(data);
          //   }
          // } catch (e) {
          //   console.log(e);
          // }
        }else{
          settripPopUp(!tripPopUp);
        }
      };
    
  return {createTrip,setTripDetail,tripPopUp, settripPopUp,xtokens}
}

export default useCreateTrip