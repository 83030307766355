import { fetchBlogByIdUrl, getBlogsUrl, likeBlogUrl, searchBlogUrl, unlikeBlogUrl } from "../../../assets/urls"

 const blogDataApi = {
    getBlogData: async () =>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }

        const response = await fetch(getBlogsUrl,options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data)
        }
    },

    getSearchedBlogs: async ({searchKey,pageNo}) =>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }
        
        const response = await fetch(searchBlogUrl(searchKey,pageNo),options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data)
        }
    },

    getBlogDetails: async (blogId) => {
        const token = localStorage.getItem('xtoken')?JSON.parse(localStorage.getItem('xtoken')).accessToken:"";

        let options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            // body: JSON.stringify({ blogId })
        };
    
        const response = await fetch(fetchBlogByIdUrl(blogId), options);
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error(data);
        }
    },

    likeBlog: async (blogId) => {
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem('xtoken')?JSON.parse(localStorage.getItem('xtoken')).accessToken:""
            },
            body: JSON.stringify({
                "blogId":blogId,
          }),
        };

        const response = await fetch(likeBlogUrl, options);
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error(data);
        }
    },

    unlikeBlog: async (blogId) => {
        let options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": JSON.parse(localStorage.getItem('xtoken')).accessToken
            },
            body: JSON.stringify({
                "blogId":blogId,
          }),
        };

        const response = await fetch(unlikeBlogUrl, options);
        const data = await response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error(data);
        }
    },
}

export default blogDataApi;