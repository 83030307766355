import { useNavigate } from "react-router-dom";
import useShowToast from "../../../shared/hooks/useShowToast";
import { getLoading } from "../../../shared/application/selectors/ui";
import { get_s_Wishlist } from "../../wishlist/applications/selectors/getWishlist";
import { getPackageWishlistItems, getSingleWishlistItems } from "../applications/selectors/getWishlists";
import { singlePlaceDetails} from "../../single-place-page/applications/selectors/getSinglePlaceData";
import { useDispatch, useSelector } from "react-redux";
import { singleWishlistItemsLoaded } from "../applications/actions/getSingleWishlistItems";
import { packageWishlistItemsLoaded } from "../applications/actions/getPackageWishlistItems";
import { useEffect } from "react";

export const WishlistPageHandler = () => {
    const { showToast } = useShowToast();
    const navigate = useNavigate();
    const loading = useSelector(getLoading);
  
    const s_wishlist_data = useSelector(get_s_Wishlist);
  
    let sWishlistItem = useSelector(getSingleWishlistItems);
    let pWishlistItem = useSelector(getPackageWishlistItems);
  
    const placedata = useSelector(singlePlaceDetails);
  
    const xtokens = JSON.parse(localStorage.getItem("xtoken"));
    const dispatch = useDispatch();
  
    useEffect(() => {
      if (xtokens) {
        dispatch(singleWishlistItemsLoaded);
        dispatch(packageWishlistItemsLoaded);
      } else {
        if (!xtokens) {
          navigate("/");
          showToast("info", "Please Login");
        }
      }
    }, [s_wishlist_data]);
  
    return { sWishlistItem, pWishlistItem, placedata, loading };
  };
  