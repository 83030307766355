export const RECOMENDED_CART_DATA_LOADED = '[recomended cart] recomended cart data loaded';
export const RECOMENDED_CART_DATA_SUCCESS = '[recomended cart] recomended cart data success';
export const RECOMENDED_CART_DATA_FAILURE = '[recomended cart] recomended cart data failure';

export const recomendedCartDataLoaded = (tpid)=>({
    type:RECOMENDED_CART_DATA_LOADED,
    payload:tpid,
})

export const recomendedCartDataSuccess = (data) =>({
    type:RECOMENDED_CART_DATA_SUCCESS,
    payload:data,
})

export const recomendedCartDataFailure = (error) =>({
    type:RECOMENDED_CART_DATA_FAILURE,
    payload:error,
})