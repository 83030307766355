import { setLoading } from '../../../../shared/application/actions/ui';
import { LOGIN_LOADED, LOGOUT_LOADED, loginFailure, loginSuccess, logoutFailure, logoutSuccess } from '../actions/getLogin';

const getLoginFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === LOGIN_LOADED) {
        const { username, password, OS,address } = action.payload;
        try {
            dispatch(setLoading(true));
            const response = await api.logInApi.logIn(username, password, OS,address);
            dispatch(loginSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(loginFailure(error))
            dispatch(setLoading(false));
        }
    }

    if (action.type === LOGOUT_LOADED) {
        try {
            dispatch(setLoading(true));
            dispatch(logoutSuccess);
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(logoutFailure(error))
            dispatch(setLoading(false));
        }
    }

}

const loginFlow =  [
    getLoginFlow,
    // logoutFlow,
]
export default loginFlow