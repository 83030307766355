import { BLOG_DETAILS_FAILURE, BLOG_DETAILS_SUCCESS } from "../actions/getBlogDetail";

const initialState = {
    blogDetails: null,
    error: null
};

const BlogDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case BLOG_DETAILS_SUCCESS:
            return {
                ...state,
                blogDetails: action.payload,
                error: null,
            };

        case BLOG_DETAILS_FAILURE:
            return {
                ...state,
                blogDetails: null,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default BlogDetailsReducer;
