export const supportData = [
    {
        title:"Support Centre",
        path:""
    },
    {
        title:"Feedback",
        path:""
    },
    {
        title:"Contact Us",
        path:""
    },
    {
        title:"Terms & Conditions",
        path:"/terms-and-condition"
    },
    {
        title:"Privacy Policy",
        path:"/privacy-policy"
    },
    
]

export  const quickLinkData = [
    {
        title:"News Letters",
        path:""
    },
    {
        title:"Sitemap",
        path:"/sitemap"
    },
    {
        title:"Partners",
        path:""
    },
    
]

export const travlPointData = [
    {
        title: "About Us",
        path:"/about"
    },
    {
        title:"Blog Articles",
        path:"/blog-articles"
    },
    {
        title:"Feedback",
        path:""
    },
    {
        title:"Download App",
        path:""
    },
    
]