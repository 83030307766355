import { REFRESH_TOKEN_LOADED, refreshTokenFailure, refreshTokenSuccess } from "../actions/getRefreshToken";
import { setLoading } from "../actions/ui";

const refreshTokenFlow = ({api})=>({dispatch}) =>next => async(action)=>{
    next(action);
    if(action.type === REFRESH_TOKEN_LOADED ){
        try{
            dispatch(setLoading(true));
            const response = await api.refresTokenApi.refreshToken(action.payload);
            dispatch(refreshTokenSuccess(response));
            dispatch(setLoading(false));
        }catch(error){
            dispatch(refreshTokenFailure(error))
            console.log(error)
        }
    }
}

const rTokenFlow = [
    refreshTokenFlow
]
export default rTokenFlow;