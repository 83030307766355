import './CategoryBtn.css'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const CategoryBtn = ({ btnName, handleCategories, catType }) => {
  const handleCategory = () => {
    handleCategories(btnName)
  }

  return (
    <button
      className={catType ? "category-type-btn cat-not-active" : "category-type-btn"}
      onClick={handleCategory}>
      <span>
        {
          catType ?
            <RemoveCircleOutlineIcon className={catType ? 'cat-icon-active' : 'cat-icon-inactive'} />
            : <ControlPointIcon className={catType ? 'cat-icon-active' : 'cat-icon-inactive'} />
        }
      </span>
      <p id='cat-title'>{btnName}</p>
    </button>
  )
}

export default CategoryBtn;