import { cartUrl } from "../../../assets/urls";
import useShowToast from "../../../shared/hooks/useShowToast";

const useDetailPlanTrip = () => {
    let {showToast} = useShowToast();

    const notify = (text) => {
        showToast('added', text);
    };

    const planTrip = async (data) => {
        if (localStorage.getItem("xtoken")) {
            let url = cartUrl;
            const options = {
                method: `PUT`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": JSON.parse(localStorage.getItem('xtoken')).accessToken
                },
                body: JSON.stringify({
                    tpid: data["tpId"],
                    sl: data["sl"],
                }),
            };
            // let response = await fetch(url, options).then((res) => res.json());
            try{
                let response = await fetch(url, options);
                let res  = await response.json();
                if(response.ok||response.status==406){
                    notify(res.data?res.data.message:res.message);
                }else{
                    throw new Error("Error found")
                }
                
            }catch(err){
                notify("Something went wrong!");
            }

        } else {
            if (localStorage.getItem("TPID")) {
                if (localStorage.getItem("TPID") !== data["tpId"]) {
                    notify("Another plan exist!!");
                } else {
                    let tempArr = JSON.parse(localStorage.getItem("Selected-Places"));
                    if (tempArr.indexOf(data["sl"]) === -1) {
                        tempArr.push(data["sl"]);
                        localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
                        notify("Place Added in Your Plan");
                    } else {
                        notify("Place already exist in Your Plan");
                    }
                }
            } else {
                localStorage.setItem("TPID", data["tpId"]);
                let tempArr = [];
                tempArr.push(data["sl"]);
                localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
                notify("Trip Created");
            }
        }
    }

    return { planTrip }
}

export default useDetailPlanTrip