import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import "./logout.css";
import { useDispatch } from "react-redux";
import { logoutLoaded } from "../../../features/login/application/actions/getLogin";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    // (window.innerWidth<600)?  props.toggleNav() :navigate('/');
    // if(window.innerWidth<600){
    //     props.toggleNav()
    // }
    navigate("/");
    dispatch(logoutLoaded);
    localStorage.removeItem("xtoken");
  };

  return (
    <>
      <div className="logout-btn" onClick={handleLogout}>
        <p style={{ marginRight: "8px" }}>Logout</p>
        <ExitToAppIcon />
      </div>
    </>
  );
};

export default Logout;
