import { setLoading } from "../../../../shared/application/actions/ui";
import { FILTER_DATA_LOADED, filterDataFailure, filterDataSuccess } from "../actions/getSearchFilterData";

const getSearchFilterDataFlow = ({api})=>({dispatch})=>next =>async(action)=>{
    next(action);

    if(action.type === FILTER_DATA_LOADED){
        // const {query,pageNo,state,category,entryType,rating,searchType} = action.payload;
        // console.log("SearchMiddleWare",action.payload )
        try{
            dispatch(setLoading(true));
            const response = await api.searchwithFilterApi.getFilterData(action.payload);
            //console.log("cartMiddle",response)
            dispatch(filterDataSuccess(response))
            dispatch(setLoading(false));
        }
        catch(error){
          dispatch(filterDataFailure(error));
          dispatch(setLoading(false));
      }
    }
}

const searchFilterFlow = [
    getSearchFilterDataFlow
]

export default searchFilterFlow 