import React from 'react'
import './MemberDetails.css'

const MemberDetails = () => {
    return (
        <div className="Memberdetails-wrapper" id='vision'>
            <div className="Memberdetails">
                <div className="Mdetails-left">
                    <div className="Mdetails-card">
                        <div className="Mdetails-Sno" style={{ backgroundColor: "#fbcc7a" }}>01</div>
                        <div className="Mdetails">
                            <div className="Mdetails-title">Customer satisfaction</div>
                            <div className="Mdetilas-description" >
                                <p style={{ lineHeight: "20px" }}>We deliver outstanding travel and event experiences by creating inspiring tours, meetings, unique promotional tours, immersive experiences and event solutions that support customer objectives, building Travlpoint as the brand of choice for our partners and market leaders.</p>
                            </div>
                        </div>
                    </div>
                    <div className="Mdetails-card">
                        <div className="Mdetails-Sno" style={{ backgroundColor: "#55CDEC" }}>02</div>
                        <div className="Mdetails">
                            <div className="Mdetails-title">Quality experience and support</div>
                            <div className="Mdetilas-description" >
                                <p style={{ lineHeight: "20px" }}>We ensure sustainable development of tourism and make India a better country for people to live in and a better country for people to visit.</p>
                            </div>
                        </div>
                    </div>
                    <div className="Mdetails-card">
                        <div className="Mdetails-Sno" style={{ backgroundColor: "#66D685" }}>03</div>
                        <div className="Mdetails">
                            <div className="Mdetails-title">Put on your exception</div>
                            <div className="Mdetilas-description" >
                                <p style={{ lineHeight: "20px" }}>We handle almost every type of travel needs, from simple to complex, and create a unique experience that our customers can trust.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Mdetails-right">
                    <div className="AppDetails-page">
                        <div className="Adetails-title">Join us</div>
                        <div className="Adetails-heading">Download our AI powered app and be a part of revolution</div>
                        <div className="Adetails-image"> <img src="assets/images/download.jpg" alt="" /></div>
                        <a href='https://play.google.com/store/apps/details?id=com.app.travlpoint' target={"_blank"} rel="noreferrer" className="Adetails-btn">Download</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberDetails