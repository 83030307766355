import "./Banner.css";
import {useRef } from "react";
import BannerSlider from "./BannerSlider";
import Slider from "react-slick";
import SearchBar from "../../../../shared/components/search-bar/SearchBar";
import { getLoading } from "../../../../shared/application/selectors/ui";
import { getNewsData } from "../../../../shared/application/selectors/homeData";
import { useSelector } from "react-redux";
import useMemoizedState from "../../hooks/useMemoizedState";

const PreviousNextMethods = () => {
  const sliderRef = useRef(null);

  const loading = useSelector(getLoading);
  const newsData = useSelector(getNewsData);
  const {data} = useMemoizedState(newsData);
  
  const next = () => {
    sliderRef.current.slickNext();
  }

  const previous = () => {
    sliderRef.current.slickPrev();
  }

  const settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  if(loading || !data){
    return(
      <div className="Loading">
          <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
        </div>
    )
  }
  return (
    <div className="banner-wrapper">

      <Slider ref={sliderRef} {...settings}>
        {data.map((element, i) => (

          <BannerSlider
            key={i}
            id={element.id}
            BannerType={element.bannerType}
            img={element.image}
            title={element.title}
            head_1={element.head1}
            head_2={element.head2}
            sub_head={element.subHead}
            next={next}
            previous={previous}
          />

        ))}
      </Slider>
      <SearchBar/>
    </div>
  );
}

export default PreviousNextMethods;