import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import SchedulePlaceCard from "./SchedulePlace_card";
import "../TripPage.css";

const ScheduleCard = (props) => {
  const schedule = props.data.slice(1, props.data.length);
  const [expanded, setExpanded] = useState(props.expanded);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  let ExpandedCard = 0;
  if (window.outerWidth > 600) {
    ExpandedCard = { height: `${schedule.length * 190 + 70}px` };
  } else {
    ExpandedCard = { height: `${schedule.length * 140}px` };
  }

  const places = schedule.map((place, i) => (
    <SchedulePlaceCard data={place} key={i} />
  ));

  return (
    <>
      <div className={"Schedule-Day"} style={expanded ? ExpandedCard : null}>
        <div className="Schedule-Day-Heading">
          <div className="Schedule-Day-Date">
            <h3>{props.data[0].Day}</h3>
            <h3>{props.data[0].Date}</h3>
          </div>
          <div
            className={
              "Schedule-Expand-btn " + (expanded ? "Expand-btn-expanded" : "")
            }
            onClick={handleExpanded}
          >
            {expanded ? (
              <RemoveIcon sx={{ color: "#C4C4C4", fontSize: 40 }} />
            ) : (
              <AddIcon sx={{ color: "#FBCC7A", fontSize: 40 }} />
            )}
          </div>
        </div>
        <div className="Schedule-Day-Body">
          {/* <SchedulePlaceCard data={props.data[1]} />
          <SchedulePlaceCard data={props.data[2]} /> */}
          {places}
        </div>
      </div>
    </>
  );
};

export default ScheduleCard;
