import { CART_DATA_FAILURE, CART_DATA_SUCCESS } from "../actions/getCartData";

const initialState = {
    cartData:{},
    error:null
}

const cartReducers = (state=initialState, action)=>{
    switch(action.type){
        case CART_DATA_SUCCESS:
            return{
                ...state,
                cartData:action.payload,
                error:null,
            }

        case CART_DATA_FAILURE:
            return{
                ...state,
                cartData:action.payload,
                error:true,
            }
        default:
            return initialState;
    }
}

export default cartReducers;