import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchBlogsLoading } from "../applications/actions/getSearchBlogs";
import { getSearchBlogsError, getSearchedBlogs } from "../applications/selectors/getSearchedBlogs";

const useBlogSearch = () => {
    const [searchkey, setSearchkey] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [searchIndicator, setSearchIndicator] = useState(false);

    const dispatch = useDispatch();
    const getSearchData = useSelector(getSearchedBlogs);
    const blogSearchError = useSelector(getSearchBlogsError);
    
    const blogSearchHandler = (e) => {
        setSearchkey(e.target.value);
        setSearchIndicator(false);
    };
    
    const handleBlogSearch = async () => {
        if(searchkey !== ""){
            dispatch(searchBlogsLoading(searchkey,1))
            localStorage.setItem("blogQuery",searchkey);
            setSearchIndicator(true)
        }
    };

    useEffect(() => {
        setSearchData(getSearchData);
        setSearchIndicator(getSearchData.length>0&&getSearchData.data.noOfResult> 0 ? true : false)
    
    }, [getSearchData])
    
        
  return {blogSearchError,searchkey,searchIndicator, searchData, blogSearchHandler,handleBlogSearch};
}

export default useBlogSearch;