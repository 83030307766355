import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const socials = [
    /*
    {
      outlet: "Instagram",
      href: (url, title) => `https://www.instagram.com/`, // Typically, Instagram does not support direct link sharing via URL
      background: "#E4405F",
      color: "white",
      label: "Share on Instagram",
      icon: <InstagramIcon />,
    },
    */
    {
      outlet: "Facebook",
      href: (url, title) => `https://www.facebook.com/sharer.php?u=${url}`,
      background: "#3b5898",
      color: "white",
      label: "Share on Facebook",
      icon: <FacebookIcon />,
    },
    {
      outlet: "Twitter",
      href: (url, title) =>
        `https://x.com/intent/tweet?url=${url}&text=${title}`,
      background: "#00aced",
      color: "white",
      label: "Share on Twitter",
      icon: <TwitterIcon />,
    },
    {
      outlet: "LinkedIn",
      href: (url, title) =>
        `https://www.linkedin.com/shareArticle?url=${url}&title=${title}`,
      background: "#0a66c2",
      color: "white",
      label: "Share on LinkedIn",
      icon: <LinkedInIcon />,
    },
    {
      outlet: "WhatsApp",
      href: (url, title) => `https://api.whatsapp.com/send?text=${title} ${url}`,
      background: "#25D366",
      color: "white",
      label: "Share on WhatsApp",
      icon: <WhatsAppIcon />,
    },
    {
      outlet: "Email",
      href: (url, title) =>
        `mailto:?subject=${title}&body=${url} Check out this new article!`,
      background: "#dd4b39",
      color: "white",
      label: "Share via Email",
      icon: <MailOutlineIcon />,
    },
    {
      outlet: "SMS",
      href: (url, title) => `sms:?body=${url}`,
      background: "#7bcb20",
      color: "white",
      label: "Share via SMS",
      icon: <SmsIcon />,
    },
  ];