import { PAGE_LOADED } from "../actions/ui";
import { homeData } from "../actions/getHomePageData";

const pageLoadedFlow = ({ log }) => ({ dispatch }) => next => action => {
    next(action);

    if (action.type === PAGE_LOADED) {
        log('page loaded');
        dispatch(homeData(action.payload));
    }
}

const uiFlow = [
    pageLoadedFlow
]
export default uiFlow;