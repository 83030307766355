import { WISHLIST_FAILURE, WISHLIST_SUCCESS } from "../actions/getWishlist"

const initialState ={
    wishlist:{},
    error:null
}

const wishlistReducer =(state=initialState, action)=>{
    switch(action.type){
        case WISHLIST_SUCCESS:
            return{
                ...state,
                wishlist:action.payload,
                error:null,
            }

        case WISHLIST_FAILURE:
            return{
                ...state,
                wishlist:action.payload,
                error:true
            }
        
        default:
            return state
    }
}

export default wishlistReducer