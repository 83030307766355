import { cartUrl, newPackageUrl, packageByIdUrl, placesToVisitByIdUrl, trendingPackageUrl } from "../../../assets/urls";

export const TrendingData = async()=>{
    const url = trendingPackageUrl

    try{
        const res = await fetch(url)
        .then((res)=> res.json())
        .then((data) => {
            return data;
        })
        return res;
    }catch(err){
        return err;
    }
};

export const NewPlacesData = async() =>{
    const url = newPackageUrl;
    try{
        const res = await fetch(url).then(res => res.json()).then(data =>{return data})
        return res;

    }catch(err){
        console.log(err)
    }

}

export function getPackageByID(tpid) {
    return new Promise((resolve, reject) => {
        try {
            fetch(packageByIdUrl(tpid)).then(res => { 
                return res.json() 
            }).then(package_data => {
                resolve({ error: null, package_data: package_data })
            })
        } catch (err) {
            resolve({ error: err.message, package_data: null })
        }
    })
}

// export function getPackageReviewByID(tpid) {
//     return new Promise((resolve, reject) => {
//         try {
//             fetch(packageReviewByIdUrl(tpid)).then(res => { return res.json() }).then(review_data => {
//                 resolve({ error: null, review_data: review_data })
//             })
//         } catch (err) {
//             resolve({ error: err.message, review_data: null })
//         }
//     })
// }

export function getPlacestoVisitReviewByTPID(tpid) {
    return new Promise((resolve, reject) => {
        try {
            fetch(placesToVisitByIdUrl(tpid)).then(res => { return res.json() }).then(placestovisit_data => {
                resolve({ error: null, placestovisit_data: placestovisit_data })
            })
        } catch (err) {
            resolve({ error: err.message, placestovisit_data: null })
        }
    })
}

export function addToCartForPlaces(tpid, token, alOfAllPlaces){
    return new Promise((resolve, reject) => {
        try {
            if (token == null) {
                if (localStorage.getItem("TPID") === tpid) {
                    resolve({ error: null, message: "Your Trip is already Created" });
                }else if(localStorage.getItem("TPID")){
                    resolve({ error: null, message: "Another plan exist!!" });
                }else{
                    localStorage.setItem("TPID", tpid);
                    localStorage.setItem("Selected-Places", JSON.stringify(alOfAllPlaces));
                    resolve({ error: null, message: "Place Added in Your Plan" });
                }
            } else {
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                    },
                    body: JSON.stringify({
                        'tpid': tpid,
                    })
                }
                fetch(cartUrl, options).then(res => { return res.json() }).then(response => {
                    resolve({ error: null, message: response.data?response.data.message:response.message  })
                })
            }
        } catch (err) {
            resolve({ error: err.message, message: null })
        }
    })
}