//base Url
const baseUrlProduction = "https://api.travlpoint.com/app";
const baseUrlProductionv1 = "https://api.travlpoint.com/app/v1";
const baseUrlProductionv2 = "https://api.travlpoint.com/app/v2";

// Get HomePage Data Url
export const getHomePageDataUrl =  baseUrlProductionv1 + "/home";

// app Download Url
export const getAppUrl = "https://play.google.com/store/apps/details?id=com.app.travlpoint";

// news-letter subscription
export const  getSubscriptionUrl = baseUrlProductionv1 + "/subscribtion/News"

// Blog-urls
export const getBlogsUrl = baseUrlProductionv2 + "/fetch_blogs";
export const fetchBlogByIdUrl = (id) => baseUrlProductionv2 + "/blog_details/" + id;
export const searchBlogUrl =(searchKey,pageNo) => baseUrlProductionv2 + `/blog_search?q=${searchKey}&page_no=${pageNo}`;
export const getBlogSubscriptionUrl = baseUrlProductionv1 + "/subscribtion/Blog";
export const likeBlogUrl = baseUrlProductionv2 +"/like_a_blog";
export const unlikeBlogUrl = baseUrlProductionv2 +"/like_a_blog";

// Cart 
export const cartUrl = baseUrlProductionv1 + "/add_to_cart";
export const recomendedCartUrl = (id) => baseUrlProductionv1 +"/all_destination/"+id;
export const removeCartUrl = baseUrlProductionv1 + "/reomve_from_cart";

// TripPlan Page // TO DO
export const planTripUrl = (tpid,copySelectedPlaces,startDate,endDate) => `https://api.travlpoint.com/trip/plan_trip/${tpid}/${JSON.stringify(copySelectedPlaces)}/${startDate}/${endDate}`
export const getTripDataUrl = baseUrlProduction + "/get_trip_data";
export const planNewTripUrl = baseUrlProduction + "/plan_new_trip";


//Auth
export const loginUrl = baseUrlProductionv1 + "/login";
export const validateSignupDataUrl = (email,mobileNumber,referralCode) =>`${baseUrlProductionv1}/validate_data?email=${email}&phone=${mobileNumber}&referal_code=${referralCode}`;
export const signUpUrl = baseUrlProductionv1 + "/user";
export const refreshTokenUrl = baseUrlProductionv1 + "/refresh-token";

// Wishlist
export const  wishlistUrl = baseUrlProductionv1 +"/wishlist_items";
export const addSinglePlaceUrl = baseUrlProductionv1 + "/Single_wishlist";
export const addPackageUrl = baseUrlProductionv1 + "/Package_wishlist";
export const removeSinglePlaceUrl = baseUrlProductionv1 + "/Single_wishlist";
export const removePackageUrl = baseUrlProductionv1 + "/Package_wishlist";

// Wishlist Page
export const singleWishlistItemsUrl = baseUrlProductionv1 + "/Single_wishlist";
export const packageWishlistItemsUrl = baseUrlProductionv1 + "/Package_wishlist";

//Search Page Api
export const  searchWithFilterUrl = baseUrlProductionv1 + "/search?";

//Single Place Api
export const singlePlaceDetailsUrl = (Sl) => baseUrlProductionv1 + "/destination_details/" + Sl;

//Profile Page Api
export const profilDataUrl = baseUrlProductionv1+"/user";
export const updateProfileDataUrl = baseUrlProductionv1 +"/user";
export const resetPasswordUrl = baseUrlProductionv1 + "/change_password"

//package
export const trendingPackageUrl = baseUrlProductionv1 + "/trending_package";
export const newPackageUrl = baseUrlProductionv1 + "/new_package";
export const packageByIdUrl = (tpid) => baseUrlProductionv1 + "/Place_Details/" + tpid;
export const placesToVisitByIdUrl = (tpid) => baseUrlProductionv1 + "/all_destination/" + tpid;

// Category page
export const fetchByCategoryUrl = (category) => baseUrlProductionv1 + "/fetch_by_category/" +category;