import React from 'react';
import './RedBtnWithBorder.css'
import usePopup from '../../hooks/usePopup';

const RedBtnWithBorder = ({ btnName, sl, tpId, PackageDetails}) => {
  const { getPopUpData } = usePopup();
  const handlePackageDetail = () => {
    if (!sl && tpId) {
      PackageDetails(tpId)
    }
    if(sl){
      getPopUpData(sl)
    }
  }
  return (
    <button className="redbtn-with-border" onClick={handlePackageDetail} >{btnName}</button>
  )
}

export default RedBtnWithBorder;