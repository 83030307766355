import React from "react";
import "./AboutBanner.css";
import { Link } from "react-scroll";

function AboutBanner() {
  return (
    <div className="AboutBanner">
      <div className="AboutBannerWrapper">
        <div className="AboutBannerBGCircle-1"></div>
        <div className="AboutBannerBGCircle-2"></div>
        <div className="AboutBannerBGCircle-3"></div>
        <div className="AboutBannerLeft">
          <p className="AboutBannerHeading">
            Make smarter decisions for your better family life
          </p>
          <p className="AboutBannerDesc">
            Travelpoint Ventures is here to change the way tourism is created in
            India
          </p>
          <div className="AboutReviewLinks">
            {/* <Link className="AboutReviewLink" to="aboutUs" smooth={true} duration={1000}>About Us</Link> */}
            <Link
              className="AboutReviewLink"
              to="meetWithUs"
              smooth={true}
              duration={1000}
            >
              Our Team
            </Link>
            {/* <Link className="AboutReviewLink" to="vision" smooth={true} duration={1000}>Our Vision</Link>
                        <Link className="AboutReviewLink" to="review" smooth={true} duration={1000}>Reviews</Link> */}
          </div>
        </div>
        <div className="AboutBannerRight">
          <div className="AboutBannerIMG AboutBannerIMG-1"></div>
          <div className="AboutBannerIMG AboutBannerIMG-2"></div>
          <div className="AboutBannerIMG AboutBannerIMG-3"></div>
          <div className="AboutBannerIMG AboutBannerIMG-4"></div>
          <div className="AboutBannerIMG AboutBannerIMG-5"></div>
          <div className="AboutBannerIMG AboutBannerIMG-6"></div>
          <div className="AboutBannerIMG AboutBannerIMG-7"></div>
          <div className="AboutBannerIMG AboutBannerIMG-8"></div>
          <div className="AboutBannerIMG AboutBannerIMG-9"></div>
        </div>
      </div>
    </div>
  );
}

export default AboutBanner;
