import React from "react";
import "./NotFound.css";
import Copyright from "../copyright/Copyright";

const NotFound = () => {
  window.scrollTo(100, 100);

  return (
    <>
      <div className="NotFound-wrapper">
        <div
          className="NotFound"
          style={{
            background: `url(${"assets/images/NotFound.png"})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="NF-circles"></div>
          <div className="Not-found-messages">
            <div className="message1">
              <p id="m1">404</p>
              <p id="m2">0oops ... !! You Seem Lost.</p>
            </div>
            <div className="message2">
              <p>
                The Page you’re looking does not exists or has moved somewhere{" "}
                <br /> else, please check the url.
              </p>
            </div>
            <div className="notfound-c1"></div>
            <div className="notfound-c2"></div>
            <div className="notfound-c3"></div>
          </div>
          <div className="Not_found_copyright">
            <Copyright />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
