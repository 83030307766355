import { cartUrl } from '../../assets/urls';
import { PackageViewModel } from '../../features/package-page/applications/PackageViewModel';
import useShowToast from '../hooks/useShowToast';

export const PlanTrip = (tpid)=>{
    const {showToast} = useShowToast();
    const {GetAllPlacestoVisitReviewByTPID, CartPageForTPID } = PackageViewModel();
    
    const notify = (text) => {
        showToast("info",text)
     };
    
    const planTrip = () => {
        GetAllPlacestoVisitReviewByTPID(tpid).then(placestovisit => {
            // setPlacestovisitData(placestovisit["placestovisit_data"])
            let sl = [];
            for (let i = 0; i < placestovisit["placestovisit_data"].length; i++) {
                sl.push(placestovisit["placestovisit_data"][i]["Sl"])
                if (i === placestovisit["placestovisit_data"].length - 1) {
                    if (localStorage.getItem('xtoken') && JSON.parse(localStorage.getItem('xtoken')).accessToken) {
                        CartPageForTPID(tpid, JSON.parse(localStorage.getItem('xtoken')).accessToken, sl).then(response => {
                            notify(response.message)
                        });
                    } else {
                        CartPageForTPID(tpid, null, sl).then(response => {
                            notify(response.message)
                        });
                    }
                }
            }

        })
    }
    
    // planTrip function active from solidRedbtn 
    const singlePlacePlanTrip = async (data) => {
        if (localStorage.getItem("xtoken")) {
            let url = cartUrl;
            const options = {
                method: `PUT`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": JSON.parse(localStorage.getItem('xtoken')).accessToken
                },
                body: JSON.stringify({
                    tpid: data["tpId"] || data["TP_ID"],
                    sl: data["sl"] || data["Sl"],
                }),
            };
            let response = await fetch(url, options).then((res) => res.json());
            notify(response.data?response.data.message:response.message);
        } else {
            if (localStorage.getItem("TPID")) {
                if (localStorage.getItem("TPID") !== (data["TP_ID"] || data["tpId"])) {
                    notify("Another plan exist!!");
                } else {
                    let tempArr = JSON.parse(localStorage.getItem("Selected-Places"));
                    if (tempArr.indexOf(data["Sl"] || data["sl"]) === -1) {
                        tempArr.push(data["Sl"] || data["sl"]);
                        localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
                        notify("Place Added in Your Plan");
                    } else {
                        notify("Place already exist in Your Plan");
                    }
                }
            } else {
                localStorage.setItem("TPID", ( data["TP_ID"] || data["tpId"]));
                let tempArr = [];
                tempArr.push(data["Sl"] || data["sl"]);
                localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
                notify("Trip Created");
            }
        }
    }


    return {planTrip,singlePlacePlanTrip}
}
