import {
  PACKAGE_WISHLIST_ITEMS_LOADED,
  packageWishlistItemsFailure,
  packageWishlistItemsSuccess,
} from "../actions/getPackageWishlistItems";
import {setLoading} from '../../../../shared/application/actions/ui'

const getPackageWishlistItemsFlow =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === PACKAGE_WISHLIST_ITEMS_LOADED) {
      try {
        dispatch(setLoading(true))
        const response =
          await api.packageWishlistItemsApi.packageWishlistItems();
        dispatch(packageWishlistItemsSuccess(response));
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(packageWishlistItemsFailure(error));
        dispatch(setLoading(false))
      }
    }
  };

const packageWishlistItemsFlow = [getPackageWishlistItemsFlow];

export default packageWishlistItemsFlow;
