import { signUpUrl } from "../../../assets/urls";

export default {
    signUp:async (register,dob,gender,OS,signUpAddress)=>{
        const userData = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                      "firstName":register.firstName,
                      "lastName": register.lastName,
                      "email":register.email,
                      "phone": register.mobileNumber,
                      "dateOfBirth":dob,
                      "referralcode": register.referralCode,
                      "gender": gender,
                      "password":register.password,
                      "device":"web_app",
                      "deviceID":"web_app",
                      "androidVersion":OS,
                      "brand":"web_app",
                      "appVersion":"1.2.1",
                      "address":signUpAddress
                }),
        };

        const response  = await fetch(signUpUrl,userData);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data);
        }
        
    }
}