export const CategoryTitle = [
    {
        title:"Historical",
        imgUrl:"https://ihplb.b-cdn.net/wp-content/uploads/2021/01/HumayunsTomb.jpg",
        path: "/categories/historical",
        type:"Historical",
    },
    {
        title:"Hill Station",
        imgUrl:"https://www.tourmyindia.com/blog//wp-content/uploads/2022/08/Best-Hill-Stations-to-Explore-in-India.jpg",
        path:"/categories/hill station",
        type:"Hill Station"
    },
    {
        title:"Devotional",
        imgUrl:"https://www.tourmyindia.com/blog//wp-content/uploads/2013/09/Konark-Sun-Temple.jpg",
        path: "/categories/holy",
        type:"Holy"

    },
    {
        title:"Bachelors",
        imgUrl:"https://imgcld.yatra.com/ytimages/image/upload/t_yt_blog_w_800_c_fill_g_auto_q_auto:good_f_jpg/v1517481510/Goa_Beach_Bar_1517481528.jpg",
        path: "/categories/bachelors",
        type:"Bachelors",
    }
]
