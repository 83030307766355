import { BLOG_DATA_LOADED, blogDataFailure, blogDataSuccess } from "../actions/getBlogsData";
import {setLoading} from '../../../../shared/application/actions/ui'
import { SEARCH_BLOGS_LOADING, searchBlogsFailure, searchBlogsSuccess } from "../actions/getSearchBlogs";
import { BLOG_DETAILS_LOADING, blogDetailsFailure, blogDetailsSuccess } from "../actions/getBlogDetail";

const getBlogDataFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === BLOG_DATA_LOADED) {
        try {
            dispatch(setLoading(true));
            const response = await api.blogApi.getBlogData(action.payload);
            dispatch(blogDataSuccess(response));
            dispatch(setLoading(false));
        } catch(error){
            dispatch(blogDataFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const searchBlogsFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);
    if(action.type === SEARCH_BLOGS_LOADING){
        try{
            dispatch(setLoading(true));
            const response = await api.blogApi.getSearchedBlogs(action.payload);
            dispatch(searchBlogsSuccess(response));
            dispatch(setLoading(false));
        }catch(err){
            dispatch(searchBlogsFailure(err))
            dispatch(setLoading(false));
        }
    }
}

const getBlogDetailsFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === BLOG_DETAILS_LOADING) {
        try {
            dispatch(setLoading(true));
            const response = await api.blogApi.getBlogDetails(action.payload.blogId);
            dispatch(blogDetailsSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(blogDetailsFailure(error));
            dispatch(setLoading(false));
        }
    }
}


const blogsDataFlow = [
    getBlogDataFlow,
    getBlogDetailsFlow,
    searchBlogsFlow
]
export default blogsDataFlow; 