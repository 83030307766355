//middleware
import { setLoading } from "../../../../shared/application/actions/ui";
import { PLACES_TO_VISIT_BY_ID_LOADING, placesToVisitByIdFailure, placesToVisitByIdSuccess } from "../actions/getPlacesToVisitById";

const placesToVisitByIdFlow = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    next(action);

    if (action.type === PLACES_TO_VISIT_BY_ID_LOADING) {
        try {
            dispatch(setLoading(true));
            const response = await api.packageApis.placesToVisitById(action.payload);
            dispatch(placesToVisitByIdSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(placesToVisitByIdFailure(error));
            dispatch(setLoading(false));
        }
    }
};

const placeToVisitFlow = [placesToVisitByIdFlow];
export default placeToVisitFlow;
