import "./Loading.css";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading-bg" >
        <CircularProgress className="spinner" variant="indeterminate"  />
      </div>
    </div>
  );
};

export default Loading;
