import React from 'react';
import './SinglePlaceDetails.css'
import CancelIcon from '@mui/icons-material/Cancel';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useSelector } from 'react-redux';
import { singlePlaceDetails } from '../../applications/selectors/getSinglePlaceData';
import usePopup from '../../../../shared/hooks/usePopup';
import { Holidays } from "../../assets/Holidays";
import { convert12Hours } from "../../../../shared/utils/convert12Hours"
import useDetailPlanTrip from '../../hooks/useDetailPlanTrip';

function SinglePlaceDetails() {
    const { planTrip } = useDetailPlanTrip();
    const { popUpHandler } = usePopup();

    let data = useSelector(singlePlaceDetails)
    let placedata = data.data

    function setTime(time) {
        let cTime = time;
        if (time.length === 7) {
            cTime = "0" + time;
        }
        return convert12Hours(cTime);
    }

    const profilepicStyle = {
        background: `url(${placedata.imageList[0]}) center / cover no-repeat`,
    };
    const coverpicStyle = {
        background: placedata&&placedata.imageList.length > 1 ? `url(${placedata.imageList[1]}) center / cover no-repeat` : `url(${placedata.imageList[0]}) center / cover no-repeat`,
    };

    const colour = ["#DAFFBC", "#E3C2FE", "#BCFFF7", "#F65555"];
    ///////////////
    return (
        <div className="details-wrapper">
            <div className="details-header">
                <div className="details-coverpic" style={coverpicStyle}>
                    <div className="details-coverpic-overlay"></div>
                </div>
                <div className="details-profilepic" style={profilepicStyle}></div>
                <div onClick={popUpHandler} className="details-icon-background background-absolute"><CancelIcon sx={{ color: "#1c1c1c", fontSize: "26px" }} /></div>
            </div>
            <div className="details-scroll">
                <div className="details-name-2Button">
                    <h1 className="details-name">{placedata["place"]}</h1>
                    <div className="details-icons">
                        <div className="details-icon-background "><FavoriteIcon sx={{ color: "#F24E1E", fontSize: "26px" }} /></div>
                        <div className="details-icon-background"><ScreenShareIcon sx={{ color: "#4ECB71", fontSize: "26px" }} /></div>
                    </div>
                </div>
                <div className="details-address-rating">
                    <h3 className="details-address">{placedata["locality"] + ', ' + placedata["state"]}</h3>
                    <div className="review">
                        <div className="details-icon"></div>
                        <div className="details-review"><StarIcon sx={{ color: "#979292", fontSize: "25px", marginRight: "5px" }} />{` ${placedata["rating"]} (${placedata["review"]["ratingCount"]} reviews)`}</div>
                    </div>
                </div>
                <div className="details-description">
                    <h2 className="deatils-heading">Brief Description</h2>
                    <p className="details-desc">{placedata["description"]}</p>
                </div>
                <div className="details-timming-holiday">
                    <div className="details-timming">
                        <h2 className="deatils-heading">Timing</h2>
                        <div className="details-flex">
                            <div className="details-time-card details-open">
                                Open:
                                <div className="details-time-open">
                                    {setTime(placedata["openingTime"])}
                                </div>
                                {/* props.setTime(props.plac) */}
                            </div>
                            <div className="details-time-card details-close">
                                Close:
                                <div className="details-time-close">
                                    {setTime(placedata["closingTime"])}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-holiday">
                        <h2 className="deatils-heading">Holidays</h2>
                        <div className="details-flex" >
                            {
                                placedata["holiday"].length === 0 ?
                                    <>
                                        <div className="details-categories-border" style={{ border: "2px solid #DAFFBC", width: "fit-content" }}>
                                            <div className="details-categories-card" style={{ backgroundColor: "#DAFFBC" }}>All Days Open</div>
                                        </div>
                                    </> :
                                    <>{
                                        placedata["holiday"].map((item) => {
                                            return (
                                                <div className="details-holiday-card" key={item}>
                                                    {Holidays[item]}</div>
                                            )
                                        })
                                    }
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className="details-categories">
                    <h2 className="deatils-heading">Category</h2>
                    <div className="details-flex">
                        {placedata["categories"].map((cate, i) => {
                            return (
                                <div className="details-categories-border" key={i} style={{ border: `2px solid ${colour[i % 4]}` }}>
                                    <div className="details-categories-card" style={{ backgroundColor: `${colour[i % 4]}` }}>{cate}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="details-gallery">
                    <h2 className="deatils-heading">Gallery</h2>
                    <div className="gallery-section">
                        <div className="detail-not-function-icon">
                            <div className="detail-icon-circle">
                                <ArrowBackIcon />
                            </div>
                        </div>
                        {placedata["imageList"].map((img, i) => {
                            return (<div className="detail-gallery-img" key={i} style={{
                                backgroundImage: `url(${img})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: "center",
                                backgroundSize: "cover"
                            }}></div>);
                        })}
                    </div>
                </div>
                <div className="details-review-card">
                    <h2 className="deatils-heading">Reviews</h2>
                    <div className='details-display-center'>
                        {placedata["review"]["reviewDetails"].map((review, i) => (
                            <div className="Review-section" key={i}>
                                <div className="profile-img" style={{
                                    backgroundImage: `url(${review["Profile Picture"]}) #989898`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: "center",
                                    backgroundSize: "cover"
                                }}></div>
                                <div className="top_review_section">
                                    <div className="reviwer_info">
                                        <span>{`${review["First Name"]} ${review["Last Name"]}, Hazaribagh`}</span>
                                    </div>
                                    <div className="reviwer_rating">
                                        <span className="details-review rating-colour"><StarIcon sx={{ color: "#F65555", fontSize: "25px", marginRight: "5px" }} />{review["Rate"]}</span>
                                    </div>
                                </div>
                                <div className="bottom_review_section">
                                    <span>{review["Review"]}</span>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <div className="details-floating-action-btn" onClick={() => planTrip(placedata)}>Plan Trip</div>
            </div>
    );
}

export default SinglePlaceDetails;