import { singlePlaceDetailsUrl } from "../../../assets/urls"

export default{
    singlePlaceDetailData : async(Sl)=>{
        let options = {
            methods:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        }
        const response = await fetch(singlePlaceDetailsUrl(Sl),options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data);
        }
    },
    
}
