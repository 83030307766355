import { TPID_LOADING, tpIdFailure, tpIdSuccess } from "../actions/getTPID";

const TPIDFlow = ()=>({dispatch})=>next =>async(action)=>{
    next(action);
    if(action.type === TPID_LOADING){
        try{
            dispatch(tpIdSuccess(action.payload));
        }catch(err){
            dispatch(tpIdFailure(err))

        }
    }
}

const tpIdFlow =  [
    TPIDFlow
]
export default tpIdFlow