import { convert12Hours } from "../../../shared/utils/convert12Hours";
export const setTime = (Opening_time, Closing_time) => {
    let oTime = Opening_time;
    let cTime = Closing_time;
    if (Opening_time.length === 7) {
      oTime = "0" + Opening_time;
    }
    if (Closing_time.length === 7) {
      cTime = "0" + Closing_time;
    }
    return convert12Hours(oTime) + " - " + convert12Hours(cTime);
  };