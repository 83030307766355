import { setLoading } from '../../../../shared/application/actions/ui';
import { SIGNUP_LOADED, signupFailure, signupSuccess } from '../actions/getSignUp';

const getSignUpFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === SIGNUP_LOADED) {
        const { register,dob,gender, OS ,signUpAddress} = action.payload;
        try {
            dispatch(setLoading(true));
            const response = await api.signUpApi.signUp(register,dob,gender,OS,signUpAddress);
            dispatch(signupSuccess(response));
            dispatch(setLoading(false));

        }catch (error) {
            dispatch(signupFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const signUpFlow = [
    getSignUpFlow,
]

export default signUpFlow;