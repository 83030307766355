export const LOGIN_LOADED = '[login] login loaded';
export const LOGIN_SUCCESS = '[login] login success';
export const LOGIN_FAILURE = '[login] login failure';
//logout
export const LOGOUT_LOADED = '[logout] logout loaded';
export const LOGOUT_SUCCESS = '[logout] logout success';
export const LOGOUT_FAILURE = '[logout] logout failure';

export const loginLoaded = (username,password,OS,address) => ({    
    type:LOGIN_LOADED,
    payload: {username,password,OS,address},
})

export const loginSuccess = (data) =>({
    type:LOGIN_SUCCESS,
    payload:data
})

export const loginFailure = (error) =>({
    type:LOGIN_FAILURE,
    payload:error
})

// logout
export const logoutLoaded ={
    type:LOGOUT_LOADED
}

export const logoutSuccess ={
    type:LOGOUT_SUCCESS,
}

export const logoutFailure =(error)=>({
    type:LOGOUT_FAILURE,
    payload:error
})