import { useState } from 'react'
import { SignupValidationStep1 } from '../utils/SignupValidation';

const SignUpPageHook = () => {
    const [step, setStep] = useState(true);
    const [checkValidation , setCheckValidation] = useState(false)

    const [register,setRegister] =useState({
        firstName:"",
        lastName:"",
        email:"",
        mobileNumber:"",
        referralCode:"",
        password:"",
        confirmPassword:"",
        check: "",
    })

    const [signUpErrors, setSignUpErrors] = useState({
        firstName:"",
        lastName:"",
        email:"",
        mobileNumber:"",
        referralCode:"",
        password:"",
        confirmPassword:"",
        check: "",
      });

    const handleSubmit = async() => {
      setCheckValidation(true)
        const errors = await SignupValidationStep1(register);
        if(Object.keys(errors).length === 0){
            setStep(!step);
            setCheckValidation(false)
        }else{
            setSignUpErrors(errors)
            setCheckValidation(false)
        }
    };
    
  const handleInput = (e) => {
    let name = e.target.name;
    const value = e.target.value;
    setRegister({ ...register, [name]: value });
  };
  
  const handleStep=()=>{
    setStep(!step)
  }

  return{register,setRegister,handleSubmit,step,handleStep,handleInput,signUpErrors,checkValidation};
}

export default SignUpPageHook;