import { useDispatch, useSelector } from "react-redux";
import { likeBlogLoading } from "../applications/actions/getBlogLiked";
import { unlikeBlogLoading } from "../applications/actions/getBlogUnliked";
import { getBlogLikeUnlike } from "../applications/selectors/getBlogData";
import { useEffect, useState } from "react";
import useShowToast from "../../../shared/hooks/useShowToast";


export const useHandleLikeUnlike = (BlogData) => {
  const dispatch = useDispatch();
  const [loading ,setLoading] = useState(true)
  const getLikeUnlikeStatus = useSelector(getBlogLikeUnlike);
  const { showToast } = useShowToast();
  const xtoken = localStorage.getItem('xtoken');

  const handleLikeUnlike = () => {
    if(!xtoken){
      showToast("info", "Please log in to save this blog!");
      return;
    }
    setLoading(false);
    if (BlogData && BlogData.data && !BlogData.data.isLiked) {
      dispatch(likeBlogLoading(BlogData.data.blogId));
    } else {
      dispatch(unlikeBlogLoading(BlogData.data.blogId));
    }
  };

  useEffect(() => {
    if (getLikeUnlikeStatus && getLikeUnlikeStatus.blogId) {
      setTimeout(()=>{
        setLoading(true);
      },1000)
    }
  }, [getLikeUnlikeStatus]);
  
  return {handleLikeUnlike,loading};
};
