import './SearchFilter.css'
import SubHeading from '../../components/sub-heading/SubHeading';
import CheckBoxItem from '../../components/checkbox-item/CheckBoxItem';
import CategoryBtn from '../../components/category-btn/CategoryBtn';
import RangeSlider from '../../components/range-slider/RangeSlider';
import RedBtnSolid from '../../../../shared/components/redbtn-solid/RedBtnSolid';
import useCheckBoxFilter from '../../hooks/useCheckBoxFilter';
import useCategoryFilter from '../../hooks/useCategoryFilter';
import useRatingFilter from '../../hooks/useRatingFilter';
import usePagination from '../../hooks/usePagination';
import { useDispatch } from 'react-redux';
import { filterDataLoaded } from '../../application/actions/getSearchFilterData';
import { ScreenWidthUpdater } from '../../../../shared/utils/screenWidthUpdater';

const SearchFilter = ({handelMobFilterToggle}) => {
    const { handleCheckboxChange, entryTypes, searchTypes, handleEntryClear, handleSearchClear } = useCheckBoxFilter();
    const { selectedCategories, notSelectedCategories, addCategory, removeCategory, show, handleClick, handleCategoryClear } = useCategoryFilter();
    const { rating, setRating, handleRatingClear} = useRatingFilter();
    const {currentPage} = usePagination();

    const dispatch = useDispatch();
    const {screenWidth} = ScreenWidthUpdater();
    
    const handleApplyFilter = () => {
        // console.log("Filters", selectedCategories, rating.min, searchTypes, entryTypes, searchKey)
        const query = localStorage.getItem("SearchKey")
        const ratings = rating.min;
        
        // console.log("SearchInputData",query,currentPage,selectedCategories,entryTypes,ratings,searchTypes)
        dispatch(filterDataLoaded({ query,pageNo:currentPage,state:"",category:selectedCategories,entryType:entryTypes,rating:ratings,searchType:searchTypes}))

        if(screenWidth<=1024){
            handelMobFilterToggle()
        }
    }

    const handleClearFilters = () => {
        handleEntryClear()
        handleSearchClear()
        handleCategoryClear()
        handleRatingClear()
    }

    return (
        <>
            <div className="search-filter">
                <div className="search-filter-header">
                    <div className="sf-heading">FILTERS</div>
                    <div className="search-clear" onClick={handleClearFilters}>Clear Filters</div>
                </div>
                <div className="entry-type">
                    <SubHeading subHeading={"Entry Type"} handleFilter={handleEntryClear} />
                    {
                        ["All", "Free", "Paid"].map((type) => (
                            <CheckBoxItem
                                key={type}
                                value={type}
                                boxName={type}
                                handleCheckboxChange={handleCheckboxChange}
                                checkBoxTypes={entryTypes}
                                filterType={"entryTypes"}
                            />
                        ))
                    }
                </div>
                <div className="search-type">
                    <SubHeading subHeading={"Search Type"} handleFilter={handleSearchClear}/>
                    {
                        ["All", "Single Places", "Packages"].map((type) => (
                            <CheckBoxItem
                                key={type}
                                value={type}
                                boxName={type}
                                handleCheckboxChange={handleCheckboxChange}
                                checkBoxTypes={searchTypes}
                                filterType={"searchTypes"}
                            />
                        ))
                    }
                </div>
                <div className="categories-type">
                    <SubHeading subHeading={"Categories"} handleFilter={handleCategoryClear} />
                    <div className="selected-category">
                        {
                            selectedCategories
                                .map((category) => (
                                    <CategoryBtn btnName={category} key={category} handleCategories={removeCategory} catType={true} />
                                ))
                        }
                    </div>
                    <div className="not-selected">
                        {
                            notSelectedCategories
                                .slice(0, 7)
                                .map((category) => (
                                    <CategoryBtn btnName={category} key={category} handleCategories={addCategory} catType={false} />
                                ))
                        }
                        {
                            show ?
                                <>
                                    {
                                        notSelectedCategories
                                            .slice(7, 13)
                                            .map((category) => (
                                                <CategoryBtn btnName={category} key={category} handleCategories={addCategory} catType={false} />
                                            ))
                                    }
                                </> : <></>
                        }
                    </div>
                    <div className="cat-show-more" onClick={handleClick}>{show ? "show less" : "show more"}</div>
                </div>
                <div className="rating-type">
                    <SubHeading subHeading={"Ratings"} handleFilter={handleRatingClear}/>
                    <RangeSlider min={0.0} max={5.0} step={0.1} value={rating} onChange={setRating} />
                    <div className="rating-value">
                        <span className='rating-min'>{rating.min}</span> - <span className='rating-max'>{rating.max}</span>

                    </div>
                </div>
            </div>
            <div className="filter-apply-btn" onClick={handleApplyFilter}>
                <RedBtnSolid btnName={"Apply"} />
            </div>
        </>
    )
}

export default SearchFilter;