export const BadgeData = [
    {
        img: 1,
        Image: "https://travlpoint.com/Gallery/Badges/novice.png",
        name:"Novice"
    },
    {
        img: 2,
        Image: "https://travlpoint.com/Gallery/Badges/novice_nomad.png",
        name:"Novice Nomad"
    },
    {
        img: 3,
        Image: "https://travlpoint.com/Gallery/Badges/elite_nomad.png",
        name:"Elite Nomad"
    },
]