import { useEffect, useState } from "react";
import "./PackageDetails.css";
import IosShareIcon from "@mui/icons-material/IosShare";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import PlaceIcon from "@mui/icons-material/Place";
import { PackageViewModel } from "../../applications/PackageViewModel.jsx";
import Gallery from "../gallery/Gallery.jsx"
import PhotoPreview from "../photo-preview/PhotoPreview.jsx";
import ReviewCard from "../review-card/ReviewCard.jsx";
import { useSelector } from "react-redux";
import TrendingSecCard from "../../../home-page/views/trending-sec/trending-sec-card/TrendingSecCard.jsx";
import Whislist from "../../../../shared/components/whishlist/Whislist.jsx"
import Copyright from "../../../../shared/components/copyright/Copyright.jsx";
import { useLocalStorage } from "../../../search-page/hooks/useLocalStorage.jsx";
import { singlePlaceDetails} from "../../../single-place-page/applications/selectors/getSinglePlaceData.jsx";
import SinglePlace from "../../../single-place-page/views/SinglePlace.jsx";
import usePopup from "../../../../shared/hooks/usePopup.jsx";
import useShowToast from "../../../../shared/hooks/useShowToast.jsx";
import { getTpId } from "../../../../shared/application/selectors/TPID.jsx";

const PackageDetails = () => {
  const tpId = useSelector(getTpId)
  /////////////////////////////////////Placestovisit Card/////////////////////////////////////
  const placedata = useSelector(singlePlaceDetails);
  const { getPopUpData } = usePopup();
  const { showToast } = useShowToast();

  ///////////////////////////////////// Placestovisit Card ///////////////////////////////////

  const [tab, setTab] = useState("Overview");
  const [loading, setLoading] = useState(true);
  const [packageDetails, setPackageDetails] = useState({});
  const [placestovisitData, setPlacestovisitData] = useState([]);

  const {
    GetOnePackage,
    GetAllPlacestoVisitReviewByTPID,
    CartPageForTPID,
  } = PackageViewModel();

  const [preview, setPreview] = useState(false);

  const previewHandler = () => {
    setPreview(!preview);
  };

  const { handleAddObject } = useLocalStorage();

  useEffect(() => {
    GetOnePackage(tpId).then((pack) => {
      setPackageDetails(pack["package_data"]);
        GetAllPlacestoVisitReviewByTPID(tpId).then((placestovisit) => {
          setPlacestovisitData(placestovisit["placestovisit_data"]);
          setLoading(false);
        });
      handleAddObject(pack["package_data"], "package");
    });
  }, []);


  const notify = (text) => {
    showToast("info", text);
  };

  const planTrip = (tpid) => {
    let sl = [];
    for (let i = 0; i < placestovisitData.data.length; i++) {
      sl.push(placestovisitData.data[i]["sl"]);
      if (i === placestovisitData.data.length - 1) {
        if (
          localStorage.getItem("xtoken") &&
          JSON.parse(localStorage.getItem("xtoken")).accessToken
        ) {
          CartPageForTPID(
            tpid,
            JSON.parse(localStorage.getItem("xtoken")).accessToken,
            sl
          ).then((response) => {
            notify(response.message);
          });
        } else {
          CartPageForTPID(tpid, null, sl).then((response) => {
            notify(response.message);
          });
        }
      }
    }
  };

  if (!loading) {
    return (
      <>
        <div className="package_details_wrapper scorllWithoutBar">
          <section className="package_details">
            {preview ? (
              <PhotoPreview
                previewHandler={previewHandler}
                img={packageDetails.data["imageList"]}
              />
            ) : (
              <div></div>
            )}
            <h2>{packageDetails.data["details"]["name"]}</h2>
            <Gallery
              img={packageDetails.data["imageList"]}
              previewHandler={previewHandler}
            />
            <div className="package_info">
              <div className="package-info-card">
                <PlaceIcon style={{ color: "white", marginRight: "1vmin" }} />
                <p className="info-text">{`${placestovisitData.data[0]["locality"]}, ${placestovisitData.data[0]["state"]}`}</p> 
                {/* District required  placestovisitData.data[0]["District"]*/}
              </div>
              <div className="package-info-card">
                <TempleHinduIcon
                  style={{ color: "white", marginRight: "1vmin" }}
                />
                <p className="info-text">{`${packageDetails.data["details"]["totalPlaces"]} Places to visit`}</p>
              </div>
              <div className="package-info-card">
                <StarHalfIcon
                  style={{ color: "white", marginRight: "1vmin" }}
                />
                <p className="info-text">{`${packageDetails.data.review["ratingCount"]} (${packageDetails.data.review["reviewCount"]} Reviews)`}</p>
              </div>
              <div className="package-info-card">
                <IosShareIcon
                  style={{
                    color: "#FBCC7A",
                    cursor: "pointer",
                    marginRight: "1vmin",
                  }}
                />
                <Whislist
                  TP_ID={packageDetails.data["details"]["tpId"]}
                />
              </div>
            </div>
            <div className="package_desc">
              <div className="package_overview scorllWithoutBar">
                <div className="package_tabs">
                  <div
                    className={
                      tab === "Overview"
                        ? "package_tab active_tab"
                        : "package_tab"
                    }
                    onClick={() => setTab("Overview")}
                  >
                    Overview
                  </div>
                  <div
                    className={
                      tab === "Strategic"
                        ? "package_tab center_tab active_tab"
                        : "package_tab center_tab"
                    }
                    onClick={() => setTab("Strategic")}
                  >
                    Strategic Location
                  </div>
                  <div
                    className={
                      tab === "Culture"
                        ? "package_tab active_tab"
                        : "package_tab"
                    }
                    onClick={() => setTab("Culture")}
                  >
                    Culture
                  </div>
                </div>
                <div className="package_description">
                  {tab === "Overview"
                    ? packageDetails.data["details"]["overview"]
                    : tab === "Strategic"
                      ? packageDetails.data["details"]["stregeticLocation"]
                      : packageDetails.data["details"]["culture"]}
                </div>
              </div>
              <div className="package_rhs_desc">
                <button
                  className="pacakge_plan"
                  onClick={() => planTrip(packageDetails.data["details"]["tpId"])}
                >
                  Plan Trip
                </button>
                <div className="package_reviews scorllWithoutBar">
                  <p className="package_review_title">
                    What people say about this place ?
                  </p>
                  <ReviewCard array={packageDetails.data.review["reviewDetails"]} />
                </div>
              </div>
            </div>
            <div className="placestovisitList">
              <p className="package-Sub-Title">{`Places to Visit at ${placestovisitData.data[0]["locality"]}`}</p>
              <div className="trending_card">
                {placestovisitData.data.map((element) => {
                  return (
                    <div className="card-section" key={element.Sl}>
                      <TrendingSecCard
                        sl={element.sl}
                        imgURL={element.image1}
                        name={element.place}
                        bookedby={element["bookedBy"]}
                        stateName={element.state}
                        locationName={element.locality}
                        getPopUpData={getPopUpData}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {
              placedata&&placedata.status  && (
                <div className="search-popup">
                  <SinglePlace />
                </div>
              )
            }
          </section>
          <Copyright />
        </div>
      </>
    );
  } else {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    );
  }
};

export default PackageDetails;
