import React, { useEffect } from 'react';
import Banner from './banner/Banner';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomePage.css'
import TrendingSec from './trending-sec/TrendingSec';
import Destinations from './destinations/Destinations';
import Populars from './populars/Populars';
import DownloadApp from './download-app/DownloadApp';
import NewsLetter from './subscription/Subscription';
import Categories from './categories/Categories';
import AboutCompany from './about-company/AboutCompany';
import Footer from '../../../shared/components/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { pageLoaded } from '../../../shared/application/actions/ui';
import { useCallback } from 'react';
import { getHomeData, getHomeDataError } from '../../../shared/application/selectors/homeData';
import { useAddress } from '../../../shared/hooks/useAddress';
import {  singlePlaceDetails } from '../../single-place-page/applications/selectors/getSinglePlaceData';
import SinglePlace from '../../single-place-page/views/SinglePlace';
import ErrorHandler from '../../../shared/components/error/ErrorHandler';

function HomePage(props) {
  // new Code
  const dispatch = useDispatch();
  const homeData = useSelector(getHomeData);
  const placedata = useSelector(singlePlaceDetails);
  const error = useSelector(getHomeDataError)

  useAddress(); // do not delete

  let AddressState = localStorage.getItem("state");
  const dispatchPageLoaded = useCallback(() => {
    dispatch(pageLoaded(AddressState))
  }, [dispatch, AddressState]);

  useEffect(() =>{
    if (Object.keys(homeData).length === 0 && AddressState) {
      dispatchPageLoaded();
    }
  }, [dispatchPageLoaded,AddressState])

  if (placedata&&placedata.status){
    document.body.classList.add("scroll-lock");
  } else {
    document.body.classList.remove("scroll-lock");
  }

  if ((!placedata )||!(Object.keys(homeData).length>0)) {
    return (
      <>
        <div className="Loading">
          <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
        </div>
      </>
    );
  } else {
    return (
      <>
        {
          error ?
            <ErrorHandler />
            :
            <>
              <Banner />
              <Populars />
              <TrendingSec />
              <Destinations />

            </>
        }
        <DownloadApp />
        <NewsLetter />
        <Categories handleCategory={props.handleCategory} />
        <AboutCompany />
        <Footer />
        {
          placedata&&placedata.status  && (
            <div className="search-popup">
              <SinglePlace />
            </div>
          )
        }
      </>
    )
  }

}

export default HomePage;
