export const BLOG_DETAILS_LOADING = '[ blog details ] blog details loading';
export const BLOG_DETAILS_SUCCESS = '[ blog details ] blog details success';
export const BLOG_DETAILS_FAILURE = '[ blog details ] blog details failure';

export const blogDetailsLoading = (blogId) => ({
    type: BLOG_DETAILS_LOADING,
    payload: {blogId}
});

export const blogDetailsSuccess = (blogDetails) => ({
    type: BLOG_DETAILS_SUCCESS,
    payload: blogDetails 
});

export const blogDetailsFailure = (error) => ({
    type: BLOG_DETAILS_FAILURE,
    payload: error
});
