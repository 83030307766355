import { Link } from "react-router-dom";
import "./TripPage.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// import ProgressBar from "../../components/ProfilePages/components/ProgressBar/ProgressBar.jsx";
import RemarkText from "./views/Remark_Test.jsx";
import ScheduleCard from "./views/Schedule_Card.jsx";
import Copyright from "../../shared/components/copyright/Copyright.jsx";
import useCreateTrip from "./hooks/useCreateTrip.jsx";
import useTripPageData from "./hooks/useTripPageData.jsx";
import ProgressBar from "../profile-page/components/progress-bar/ProgressBar.jsx"
import ErrorHandler from '../../shared/components/error/ErrorHandler.jsx'
import { getAppUrl } from "../../assets/urls.jsx";

const TripPage = () => {
  const { createTrip, setTripDetail, tripPopUp, settripPopUp, xtokens } = useCreateTrip();
  const { getRemark, getcol, error, Data, OverallVal, LocationVal, VisitingVal } = useTripPageData(setTripDetail);

  if (error) {
    return (<ErrorHandler />)
  }
  if (!Data) {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    );
  }

  const schedules = Data && Data.schedule.map((Sch, i) => (
    <ScheduleCard data={Sch} expanded={i === 0 ? true : false} key={i} />
  ));

  const remarks = Data.err.map((rem, i) => <RemarkText Name={rem} key={i} />);

  return (
    <>
      <div className="trip-body-wrapper">
        <div className="trip-body-left">
          <h2> Awesome Your trip is created ... !! </h2>

          {/* Overall-rating-section */}
          <div className="trip-Overall-card">
            <h3> Overall Rating</h3>
            <div className="Overall-Progress">
              <CircularProgressbar
                styles={buildStyles({
                  textColor: getcol(Math.round(OverallVal)),
                  pathColor: getcol(Math.round(OverallVal)),
                  trailColor: `rgba(196, 196, 196, ${50 / 100})`,
                })}
                value={Math.round(OverallVal)}
                text={`${Math.round(OverallVal)} %`}
                strokeWidth={15}
              />
              <div className="Overall-Text">
                <p style={{ color: "#FBCC7A" }}>
                  “Your trip is rated{" "}
                  <span style={{ color: getcol(OverallVal) }}>
                    {getRemark(OverallVal)}
                  </span>{" "}
                  by Our AI,You can go Ahead !
                </p>
              </div>
            </div>
          </div>

          {/* Locaton-rating */}
          <div className="Location-Place-rating">
            <div className="Location-rating">
              <ProgressBar
                stroke_color={getcol(LocationVal)}
                tail_color={`rgba(196, 253, 150, ${22 / 100})`}
                percentage={Math.round(LocationVal)}
                name="Location"
              />
            </div>
            <div className="Place-rating">
              <ProgressBar
                stroke_color={getcol(VisitingVal)}
                tail_color={`rgba(196, 253, 150, ${22 / 100})`}
                percentage={Math.round(VisitingVal)}
                name="Visiting places"
              />
            </div>
          </div>

          {/* Remarks-section */}
          <div className="Remarks">
            <div className="Remark-Heading">
              <h2>Remarks</h2>
            </div>
            <div className="Remark-Texts">
              {Data.err.length >= 1
                ? remarks
                : "No Problems found in your trip !"}
            </div>
          </div>
        </div>

        {/* Schedule-section */}
        <div className="trip-body-right">
          <div className="Schedule-title">
            <h2> Schedule </h2>
          </div>
          <div className="Schedule-body">
            {/* <ScheduleCard data={Data.schedule[0]} expanded={true} /> */}
            {schedules}
          </div>
          <div className="trip-Continue-Btn" onClick={() => createTrip(Data)}>
            <h2>Continue</h2>
          </div>
        </div>
      </div>
      <Copyright />

      {tripPopUp && (
        <div className="search-popup" style={{ width: "auto" }}>
          <div
            className="search-overlay"
            onClick={() => settripPopUp(!tripPopUp)}
          ></div>
          <div className="tripPage-Popup-content">
            <div className="tripPage-wrapper">
              <div className="tripLogin-page">
                {
                  xtokens ? <>
                    <a href={getAppUrl} className="tripLogin-btn">
                      <div>Download</div>
                    </a>
                    <div className="tripLogin-text">
                      <p>
                        Plan Created Successfully <br /> For more detail Download Our App
                      </p>

                    </div>
                  </> : <>
                    <Link to={{ pathname: "/login" }} className="tripLogin-btn">
                      <div>LogIn</div>
                    </Link>
                    <div className="tripLogin-text">

                      <p>
                        Please LogIn <br />to Save Your Trip
                      </p>

                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  );
};

export default TripPage;
