import { useCallback } from 'react';

const useEnterKey = (callback) => {
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      callback();
    }
  }, [callback]);

  return handleKeyDown;
};

export default useEnterKey;
