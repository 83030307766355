import React from 'react';
import './ACInfoCard.css'

const ACInfoCard = ({ type, value, title }) => {
    return (
        <div className="ac-info-card align-ac-item">
            {
                (type === "audience") ?
                    <>
                        <img src="assets/icons/audience.svg" alt="" className='info-card-img' />
                    </> : (type === "rating") ? <>
                        <img src="assets/icons/halfstar.svg" alt="" className='info-card-img' />
                    </> : <>
                        <img src="assets/icons/location 2.svg" alt="" className='info-card-img' />
                    </>
            }
            <div className="ac-info-card-value align-ac-item">{value}</div>
            <div className="ac-info-card-name align-ac-item">{title}</div>
        </div>
    )
}

export default ACInfoCard;