import React from 'react'

import '../TripPage.css'

const RemarkText = (props) => {
    return(
        <>
            <div className='Text-Rem'>
                <div className='Rem-Dot'>
                    <div className='Rem-inner-dot'></div>
                </div>
                <p><span style={{color:"#FBCC7A"}}>{props.Name}</span></p>
            </div>
        </>
    )
}

export default RemarkText