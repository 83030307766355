import { useDispatch, useSelector } from "react-redux"
import { refreshTokenLoaded } from "../application/actions/getRefreshToken";
import { getRefreshToken } from "../application/selectors/refreshToken";
import { useEffect } from "react";
import { useMemo } from "react";

const useRefreshToken = () => {
    const dispatch = useDispatch();
    const refreshToken = useSelector(getRefreshToken); 

    const handleRefreshToken = (response1, rToken) => {

        if (response1.isExpired) {
            dispatch(refreshTokenLoaded(rToken))
        }
        else {
            localStorage.removeItem('xtoken');
            console.log("Session Time out...Login Again");
        }
    }

    const memoizedRefreshToken = useMemo(() => refreshToken, [refreshToken])

    const updateMemoizedRefreshToken = () => {
        if (refreshToken.status === false) {
            localStorage.removeItem('xtoken');
            console.log("Session Time out...Login Again");
        }
        if (refreshToken.status) {
            localStorage.setItem('xtoken', JSON.stringify(refreshToken.data))
        }
    }

    useEffect(() => {
        updateMemoizedRefreshToken()
    }, [memoizedRefreshToken]);

    return { handleRefreshToken }
}

export default useRefreshToken