export const TPID_LOADING = '[TPID] tpid loading';
export const TPID_SUCCESS = '[TPID] tpid success';
export const TPID_FAILURE = '[TPID] tpid loading';

export const tpIdLoading = (tpId)=> ({
    type:TPID_LOADING,
    payload:tpId
})

export const tpIdSuccess = (data) =>({
    type:TPID_SUCCESS,
    payload:data
})

export const tpIdFailure = (error) => ({
    type:TPID_FAILURE,
    payload:error
})
