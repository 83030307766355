import React, { useEffect, useState } from 'react'
import useShowToast from '../../../shared/hooks/useShowToast';
import PreArrow from '../components/PreArrow';
import NextArrow from '../components/NextArrow';
import { cartUrl, fetchByCategoryUrl } from '../../../assets/urls';

const useCategoryPage = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [catError, setCatError] = useState(false);
  const [placeData, setPlaceData] = useState({});
  const [openTime, setOpenTime] = useState("");
  const [desc, setDesc] = useState("");
  const [closeTime, setCloseTime] = useState("");

  const [img, setImg] = useState([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);

  const { showToast } = useShowToast();
  const notify = (text) => {
    showToast("info", text);
  };

  const getImageArray = (img1, img2, img3, img4, img5) => {
    let tempImg = [];
    if (img1 !== "" && img1 !== null) tempImg.push(img1);
    if (img2 !== "" && img2 !== null) tempImg.push(img2);
    if (img3 !== "" && img3 !== null) tempImg.push(img3);
    if (img4 !== "" && img4 !== null) tempImg.push(img4);
    if (img5 !== "" && img5 !== null) tempImg.push(img5);

    setImg(tempImg);
  };

  useEffect(() => {
    if (props.category !== "" && props.category !== undefined) {
      try {
        fetch(fetchByCategoryUrl(props.category))
          .then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              setCatError(!catError)
            }
          })
          .then((data) => {
            if (data) {
              setPlaceData(data.data[0]);
              setCategoryData(data.data);
              setDesc(data.data[0]["description"]);
              setOpenTime(data.data[0]["openingTime"]);
              setCloseTime(data.data[0]["closingTime"]);
              getImageArray(
                data.data[0].imageList[0],
                data.data[0].imageList[1],
                data.data[0].imageList[2],
                data.data[0].imageList[3],
                data.data[0].imageList[4],
              );
            }
          })
      } catch (err) {
        setCatError(!catError)
      }
    }
  }, [props.category]);


  const planTrip = async (data) => {
    if (localStorage.getItem("xtoken")) {
      const options = {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("xtoken")).accessToken,
        },
        body: JSON.stringify({
          tpid: data["tpId"],
          sl: data["sl"],
        }),
      };
      let response = await fetch(cartUrl, options).then((res) => res.json());
      notify(response.Msg);
    } else {
      if (localStorage.getItem("TPID")) {
        if (localStorage.getItem("TPID") !== data["tpId"]) {
          notify("Another plan exist!!");
        } else {
          let tempArr = JSON.parse(localStorage.getItem("Selected-Places"));
          if (tempArr.indexOf(data["sl"]) === -1) {
            tempArr.push(data["sl"]);
            localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
            notify("Place Added in Your Plan");
          } else {
            notify("Place already exist in Your Plan");
          }
        }
      } else {
        localStorage.setItem("TPID", data["tpId"]);
        let tempArr = [];
        tempArr.push(data["sl"]);
        localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
        notify("Trip Created");
      }
    }
  };

  const settingsPicture = {
    autoplay: true,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setImgIndex(next),
  };

  const settings = {
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "-15px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PreArrow />,
    beforeChange: (current, next) => {
      setCardIndex(next);
      setPlaceData(categoryData[next]);
      setDesc(categoryData[next]["description"]);
      setOpenTime(categoryData[next]["openingTime"]);
      setCloseTime(categoryData[next]["closingTime"]);
      getImageArray(
        categoryData[next].imageList[0],
        categoryData[next].imageList[1],
        categoryData[next].imageList[2],
        categoryData[next].imageList[3],
        categoryData[next].imageList[4],
      );
    },
  };

  return {
    catError,
    settings,
    settingsPicture,
    img,
    cardIndex,
    imgIndex,
    openTime,
    placeData,
    categoryData,
    desc,
    planTrip,
    closeTime
  }
}

export default useCategoryPage