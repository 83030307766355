import { SIGNUP_FAILURE, SIGNUP_SUCCESS } from "../actions/getSignUp"

const initialState ={
    token:{},
    error: null
}

const signupReducers =(state=initialState, action)=>{
    switch(action.type){
        case SIGNUP_SUCCESS:
            return{
                ...state,
                token:action.payload,
                error:null
            }
        
        case SIGNUP_FAILURE:
            return{
                ...state,
                token:action.payload,
                error:true
            }
        
        default:
            return state;
    }
}


export default signupReducers;