import  { useEffect} from 'react'
import {  useParams } from 'react-router-dom';
import { blogDetailsLoading } from '../applications/actions/getBlogDetail';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogDetails, getBlogDetailsError, getBlogLikeUnlike } from '../applications/selectors/getBlogData';

const useFetchBlogDetailById = () => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const BlogData = useSelector(getBlogDetails);
    const blogDetailError = useSelector(getBlogDetailsError);
    const prevBlogId = BlogData?BlogData.data.blogId:null;
    const blogLiked = useSelector(getBlogLikeUnlike)
    let isLikeChanged = blogLiked&&blogLiked.blogId&&blogLiked.blogId.status;

    useEffect(() => {
        if(prevBlogId!==id || isLikeChanged || !isLikeChanged){
            dispatch(blogDetailsLoading(id));
        }
    }, [id,blogLiked])
    
    return {BlogData,blogDetailError}
}

export default useFetchBlogDetailById