import React from 'react';
import './ReviewCard.css'

const ReviewCard = ({name,image,feedBack}) => {
    return (
        <div className="review-card">
            <div className="reviewer-img">
                <img src={image} alt="profilePic" />
            </div>
            <div className="reviewer-name">{name}</div>
            <div className="review">{feedBack}</div>
        </div>
    )
}

export default ReviewCard;