import { useEffect } from 'react';

function useMetadata({ title, description, keywords }) {
  useEffect(() => {
    // Set document title
    if (title) {
      document.title = title;
    }

    // Helper function to create/update meta tag
    const updateMetaTag = (name, content) => {
      let element = document.querySelector(`meta[name="${name}"]`);
      if (!element) {
        element = document.createElement('meta');
        element.name = name;
        document.head.appendChild(element);
      }
      element.content = content;
    };

    // Update meta description
    if (description) {
      updateMetaTag('description', description);
    }

    // Update meta keywords
    if (keywords) {
      updateMetaTag('keywords', keywords);
    }

    // Cleanup function to remove meta tags when component unmounts
    return () => {
      if (description) {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          document.head.removeChild(metaDescription);
        }
      }
      if (keywords) {
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          document.head.removeChild(metaKeywords);
        }
      }
    };
  }, [title, description, keywords]);
}

export default useMetadata;
