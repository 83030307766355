export const SINGLE_PLACE_DATA_LOADING = '[single place] single place data loading';
export const SINGLE_PLACE_DATA_SUCCESS = '[single place] single place data success'; 
export const SINGLE_PLACE_DATA_FAILURE = '[single place] single place data failure';

export const SINGLE_PLACE_DATA_REMOVING = '[single place] single place data removing';
export const SINGLE_PLACE_DATA_REMOVED = '[single place] single place data removed'; 
export const SINGLE_PLACE_DATA_REMOVE_FAILURE = '[single place] single place data failure';

export const singlePlaceDataLoading = (Sl)=>({
    type:SINGLE_PLACE_DATA_LOADING,
    payload:Sl
})

export const singlePlaceDataSuccess = (data)=>({
    type:SINGLE_PLACE_DATA_SUCCESS,
    payload:data
})

export const singlePlaceDataFailure = (error)=>({
    type:SINGLE_PLACE_DATA_FAILURE,
    payload:error
})

// REMOVING SINGLE PLACE DATA

export const singlePlaceDataRemoving = {
    type:SINGLE_PLACE_DATA_REMOVING
}

export const singlePlaceDataRemoved ={
    type:SINGLE_PLACE_DATA_REMOVED

}

export const singlePlaceDataRemoveFailure = (error)=>({
    type:SINGLE_PLACE_DATA_REMOVE_FAILURE,
    payload:error
})

