import React from 'react';
import './SignupPageStep2.css';
import Celebration from '../../assets/Images/celebration.svg'
import SignUpPageStep2Hook from '../../hooks/SignUpPageStep2Hook';
import { useSelector } from 'react-redux';
import { getLoading } from '../../../../shared/application/selectors/ui';
import { CircularProgress } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useEnterKey from '../../../../shared/hooks/useEnterKey';

const SignupPageStep2 = ({ register, handleStep }) => {
    const { dob, doBHandler, gender, handleGender, handleContinueTOHome, signupStep2Error } = SignUpPageStep2Hook(register);
    const handleKeyDown = useEnterKey(handleContinueTOHome);

    const loading = useSelector(getLoading);
    return (
        <>
            <div className="signup-back-arrow">
                <KeyboardBackspaceIcon className='back-arrow-iocn' onClick={handleStep} />
            </div>
            <div className="signup-form-heading step2-heading">Hurray, your Account <br />is Created!</div>
            <div className="signup-form-subheading step2-sub-heading">Welcome to <span>TravlPoint</span></div>
            <div className="step2-celebration">
                <img src={Celebration} alt="" />
            </div>
            <div className="step2-sub-heading2">Help us know you better</div>
            <div className="main-form-step2">
                <div className="gender">
                    <div className="label">Gender</div>
                    <div className="gender-options">
                        <span className={gender === "Male" ? 'gender-active' : 'gender-inactive'} onClick={handleGender}>Male</span>
                        <span className={gender === "Female" ? 'gender-active' : 'gender-inactive'}
                            onClick={handleGender}>Female</span>
                    </div>
                    {signupStep2Error.gender && (
                        <span className="Signup-error">
                            {signupStep2Error.gender}
                        </span>
                    )}
                </div>
                <div className="dob">
                    <div className="label">Date of Birth</div>
                    <input
                        type="date"
                        placeholder="DD-MM-YYYY"
                        value={dob}
                        onChange={doBHandler}
                        onKeyDown={handleKeyDown}
                        className={signupStep2Error.dob ? "error" : ""} />
                </div>
                {signupStep2Error.dob && (
                    <span className="Signup-error">
                        {signupStep2Error.dob}
                    </span>
                )}
            </div>
            <div className="continue-btn" onClick={handleContinueTOHome}>
                {loading
                    ? <CircularProgress
                        sx={{ color: "#EEEEEE" }}
                        size={30}
                    /> :
                    "Continue To Home"}
            </div>
        </>
    )
}

export default SignupPageStep2;