import './SignupPage.css'
import SignUpCarousel from '../components/signup-carousel/SignUpCarousel';
import { CarouselData } from '../assets/Data/CarouselData';
import { SignUpInputField } from '../assets/Data/SignUpInputField';
import LoginSingupInput from '../../../shared/components/login-signup-Input/LoginSingupInput';
import SignUpPageHook from '../hooks/SignUpPageHook';
import { Link } from 'react-router-dom';
import SignupPageStep2 from '../components/signup-page-step2/SignupPageStep2';
import { CircularProgress } from '@mui/material';
import { ScreenWidthUpdater } from '../../../shared/utils/screenWidthUpdater';
import useEnterKey from '../../../shared/hooks/useEnterKey';

const SignupPage = () => {
    const { handleSubmit, register, setRegister, step, handleInput, signUpErrors, checkValidation, handleStep } = SignUpPageHook();
    const { SignupInputFieldData } = SignUpInputField(register, setRegister);
    const handleKeyDown = useEnterKey(handleSubmit);


    const { screenWidth } = ScreenWidthUpdater();
    
    return (
        <div className="signup-wrapper">
            <div className="signup-page">
                {
                    !step && screenWidth <= 600 ? <></> :
                        <div className="signup-container-size signup-left" id='leftdiv'>
                            <div className="signup-left-heading">
                                <Link to="/">
                                    <img src="assets/icons/Main_Logo.svg" alt="" />
                                </Link>
                            </div>
                            <div className="signup-carousel">
                                <SignUpCarousel slidesData={CarouselData} />
                            </div>
                        </div>
                }
                <div className="signup-container-size signup-right" id='rightdiv'>
                    <div className="signup-right-content">
                        <div className="main-form-step1">
                            {
                                step ?
                                    <>
                                        <div className="signup-form-heading">Nice to see you joining us :)</div>
                                        <div className="signup-form-subheading">Welcome to <span>TravlPoint</span></div>
                                        {/* <div className="main-form-step1"> */}
                                        {
                                            SignupInputFieldData.map((inputField, id) => (
                                                <div className="signup-fields" key={id}>
                                                    <LoginSingupInput
                                                        inputType={inputField.inputType}
                                                        inputPlaceholder={inputField.inputPlaceholder}
                                                        value={inputField.value}
                                                        setInput={inputField.setInput}
                                                        inputIcon={inputField.inputIcon}
                                                        lockOpenIcon={inputField.lockOpenIcon}
                                                        visibilityIcon={inputField.visibilityIcon}
                                                        visibilityOffIcon={inputField.visibilityOffIcon}
                                                        errors={signUpErrors[inputField.inputLabel] && signUpErrors[inputField.inputLabel].length !== 0}
                                                        handleKeyDown={handleKeyDown}
                                                    />
                                                    {signUpErrors[inputField.inputLabel] && (
                                                        <span className="Signup-error">
                                                            {signUpErrors[inputField.inputLabel]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))
                                        }
                                        <div className="signup-bottom">
                                            <input
                                                type="checkbox"
                                                id="Checked"
                                                name="check"
                                                value="Checked"
                                                checked={register.check}
                                                onChange={handleInput}
                                            />
                                            <div className="signup-tc">
                                                By Signing up with TravlPoint Ventures I agree to accept
                                                <Link to="/ c terms-and-condition" className='tc'> Terms And Conditions</Link>  of the company
                                            </div>
                                        </div>
                                        {signUpErrors.check && (
                                            <span className="Signup-error">
                                                {signUpErrors.check}
                                            </span>
                                        )}

                                        <div className="signup-btn" onClick={handleSubmit}>
                                            {
                                                checkValidation
                                                    ? <CircularProgress
                                                        sx={{ color: "#EEEEEE" }}
                                                        size={30}
                                                    /> :
                                                    "SIGNUP"
                                            }
                                        </div>
                                        {/* </div> */}

                                        <div className="singup-link">
                                            Have an account? <Link to="/login" className='signup-link-span'> Login Now</Link>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <SignupPageStep2 register={register} handleStep={handleStep} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupPage;