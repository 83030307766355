import { CircularProgress } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './LoginPage.css'
import LoginSingupInput from '../../../shared/components/login-signup-Input/LoginSingupInput';
import LoginImageCard from '../components/login-image-card/LoginImageCard';
import LoginPageUtils from '../hooks/LoginPageHook';
import { getLoading } from '../../../shared/application/selectors/ui'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useShowToast from '../../../shared/hooks/useShowToast';
import { ImageUrlsData } from '../assets/ImageUrlsData';
import useEnterKey from '../../../shared/hooks/useEnterKey';


const LoginPage = () => {
    const {showToast} = useShowToast();
    const { username, setUsername, password, setPassword, handleSubmit } = LoginPageUtils(showToast);
    const {imageUrlsData} = ImageUrlsData();
    const handleKeyDown = useEnterKey(handleSubmit);


    const loading = useSelector(getLoading);

    return (
        <div className="login-page-wrapper">
            <div className="login-page">
                <div className="lp-left">
                    <div className="images">
                        {imageUrlsData.map((data, id) => (
                            <div className="image" key={id} style={{ position: "relative", top: `${data.top}`, bottom: `${data.bottom}` }}>
                                <LoginImageCard imgUrl={data.img} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="lp-right">
                    <div className="login-heading">Hey, Welcome Back!</div>
                    <div className="login-sub-heading">
                        Nice to see you Again,
                    </div>
                    <div className="login-username">
                        <LoginSingupInput
                            inputType="text"
                            inputPlaceholder="username"
                            inputIcon={<PersonIcon />}
                            value={username}
                            setInput={setUsername}
                            handleKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="login-password">
                        <LoginSingupInput
                            inputType="password"
                            inputPlaceholder="password"
                            inputIcon={<LockIcon />}
                            lockOpenIcon={<LockOpenIcon />}
                            visibilityIcon={<VisibilityIcon />}
                            visibilityOffIcon={<VisibilityOffIcon />}
                            value={password}
                            setInput={setPassword}
                            handleKeyDown={handleKeyDown}

                        />
                    </div>
                    <div className="forget-password">
                        <div className="fp">Forgot Password?</div>
                    </div>
                    <div className="lgn-btn" onClick={handleSubmit}>{loading
                        ? <CircularProgress
                            sx={{color:"#EEEEEE"}}
                            size={30}
                        /> :
                        "LOGIN"}</div>
                    <div className="login-singup-link">
                        Don’t have account?<Link to="/signup" className='lg-signup-link'> Signup Now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;