export const ValidationForm = async(register,dob)=>{
    const errors = {}
    const emailRegex = /^\S+@\S+\.\S+$/;
    
    const checkEPR = async (Email, Phone, referralCode) => {

        let result = await fetch(`https://api.travlpoint.com/app/validate_data/${Email}/${Phone}/${referralCode}`).then((res) => res.json())
        return result;
    }

    if (!register.FirstName){
        errors.FirstName = 'Please enter your first name';
    }
  
    if (!register.LastName){
        errors.LastName = 'Please enter your last name';
    }
  
    if (!register.Email){
        errors.Email = 'Please enter your email address';
    } else if (!emailRegex.test(register.Email)) {
        errors.Email = 'Please enter a valid email address';
    }

    if (!register.Phone){
        errors.Phone = 'Please enter a password';
    } else if (register.Phone.length !== 10) {
        errors.Phone = 'Mobile Number must be 10 digit ';
    }

    if(!dob){
        errors.dob ="Please enter DOB"
    }

    if(!register.Gender){
        errors.Gender ='Please select gender'
    }

    if(!register.Password) {
        errors.Password = 'Please enter a password';
    }else if(register.Password.length < 8){
        errors.Password = 'Password must be at least 8 characters long';
    }
  
    if(!register.ConfirmPassword){
        errors.ConfirmPassword = 'Please confirm your password';
    }else if(register.Password !== register.ConfirmPassword) {
        errors.ConfirmPassword = 'Passwords do not match';
    }

    if((register.Email && register.Password) || register.referralCode){
        if ( register.referralCode === "") {
            register.referralCode = '0';
        }
        
        const res = await checkEPR(register.Email, register.Phone, register.referralCode);
        if(res.Email){
            errors.Email="Email is already taken"
        }

        if(res.Phone){
            errors.Phone="Mobile number is already taken"
        }
        
        if(!register.check){
            errors.check = "Please Accept Terms and Condition"
        }

    }
  
      return errors;
  
}

export const UpdateProfileValidationForm = async( PDetails )=>{
    const errors = {}
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (!PDetails.FirstName){
        errors.FirstName = 'Please enter your first name';
    }
  
    if (!PDetails.LastName){
        errors.LastName = 'Please enter your last name';
    }
  
    if (!PDetails.Email){
        errors.Email = 'Please enter your email address';
    } else if (!emailRegex.test(PDetails.Email)) {
        errors.Email = 'Please enter a valid email address';
    }

    if (!PDetails.Phone){
        errors.Phone = 'Please enter a password';
    }else if(PDetails.Phone.length !== 10) {
        errors.Phone = 'Mobile Number must be 10 digit ';
        // errors.Phone = PDetails.Phone.length;
    }

    if(!PDetails.dob){
        errors.dob ="Please enter DOB"
    }
  
      return errors;
}

