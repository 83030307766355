import { setLoading } from "../../../../shared/application/actions/ui";
import { SINGLE_PLACE_DATA_LOADING, SINGLE_PLACE_DATA_REMOVING, singlePlaceDataFailure, singlePlaceDataRemoveFailure, singlePlaceDataRemoved, singlePlaceDataSuccess } from "../actions/getSinglePlaceData";

const singlePlaceDataFlow = ({api}) =>({dispatch}) => next => async(action)=>{
    next(action);

    if(action.type === SINGLE_PLACE_DATA_LOADING){
        try{
            dispatch(setLoading(true));
            const response = await api.singlePlaceApi.singlePlaceDetailData(action.payload);
            dispatch(singlePlaceDataSuccess(response))
            dispatch(setLoading(false));
        }catch(error){
            dispatch(singlePlaceDataFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const singlePlaceRemoveDataFlow = () =>({dispatch}) => next => async(action)=>{
    next(action);

    if(action.type === SINGLE_PLACE_DATA_REMOVING){
        try{
            dispatch(setLoading(true));
            dispatch(singlePlaceDataRemoved)
            dispatch(setLoading(false));
        }catch(error){
            dispatch(singlePlaceDataRemoveFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const singlePlaceFlow = [
    singlePlaceDataFlow,
    singlePlaceRemoveDataFlow 
]
export default singlePlaceFlow