export   const reviewData = [
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2018/03/27/21/43/startup-3267505_960_720.jpg",
      "Feedback": "The app is amazing. The booking experience is great, Interface is simply awesome. Everything about the app great.",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Mohit Prasad"
    },
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2015/01/08/18/30/entrepreneur-593371_960_720.jpg",
      "Feedback": "Amazing Experience. Such a great app. This app makes my trip feel wonderful and awesome. Really love this app.",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "SATYA PRAKASH"
    },
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2016/11/29/09/38/adult-1868750_960_720.jpg",
      "Feedback": "Really a great work, it will help us in making travelling plan. Very smooth user experience. It is fully ai baised . Really a amazing experience!!!!",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Parth Bahadur"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1520953027650-0067908555fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1604004555489-723a93d6ce74?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://media.istockphoto.com/photos/young-man-with-backpack-taking-selfie-portrait-on-a-mountain-smiling-picture-id1329031407?b=1&k=20&m=1329031407&s=170667a&w=0&h=J6qRqj9hbKtSVwIfMJhcRXf3AEyAOshph2IAbPHwNUo=",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://media.istockphoto.com/photos/happy-woman-sightseeing-around-cusco-around-the-cathedral-picture-id1324227698?b=1&k=20&m=1324227698&s=170667a&w=0&h=beYP1IJVIrBgUZQ83K-Rdsa8EeNnRt2gsqkEjuO3TM4=",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://media.istockphoto.com/photos/young-couple-taking-break-from-sightseeing-for-selfie-picture-id1291682560?b=1&k=20&m=1291682560&s=170667a&w=0&h=nt6JJOV82jMhE23a0S2X7OHgYR9mA7Y2pfByJXUQoP0=",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1609137144813-7d9921338f24?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8dG91cmlzbXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1536698658763-878a02695d1c?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHRvdXJpc218ZW58MHx8MHx8&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1496440737103-cd596325d314?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1514315384763-ba401779410f?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1474073705359-5da2a8270c64?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://cdn.pixabay.com/photo/2015/04/20/17/39/woman-731894_960_720.jpg",
      "Feedback": "User Experience is really smooth and flawless. The App feels good and easy to use, The AI powered Schedule is really plus point. Greak Work !",
      "Opacity": "0",
      "viewFeedback": "0",
      "Name": "Pranshu Kumar"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1474073705359-5da2a8270c64?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.5",
      "viewFeedback": "1",
      "Name": "xyz"
    },
    {
      "ProfilePicture": "https://images.unsplash.com/photo-1494790108377-be9c29b29330?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=60&raw_url=true&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Z2lybHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500",
      "Feedback": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt nisi ipsum atque quidem, voluptate praesentium ducimus.",
      "Opacity": "0.75",
      "viewFeedback": "1",
      "Name": "xyz"
    },
  ];
