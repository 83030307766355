export const VALIDATION_LOADED = '[signupValidation] signupValidation loaded';
export const VALIDATION_SUCCESS = '[signupValidation] signupValidation success';
export const VALIDATION_FAILURE = '[signupValidation] signupValidation failure';

export const signupValidationLoaded = (email,mobileNumber,referralCode) => ({    
    type:VALIDATION_LOADED,
    payload: {email,mobileNumber,referralCode},
})

export const signupValidationSuccess = (data) =>({
    type:VALIDATION_SUCCESS,
    payload:data
})

export const signupValidationFailure = (error) =>({
    type:VALIDATION_FAILURE,
    payload:error
})