import { useLayoutEffect, useState } from "react";

const useCustomSlice = () => {
    const [Slice, setSlice] = useState(4);
    const screenSize = window.innerWidth;

    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth < 600) {
                setSlice(4);
            } else if (window.innerWidth <= 1024) {
                setSlice(3);
            } else {
                setSlice(4);
            }
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [screenSize]);

  return {Slice}
}

export default useCustomSlice