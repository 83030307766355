export const WISHLIST_LOADED = '[wishlist] wishlist loaded';
export const WISHLIST_SUCCESS = '[wishlist] wishlist success';
export const WISHLIST_FAILURE = '[wishlist] wishlist failure';

export const wishlistLoaded = {
    type:WISHLIST_LOADED
}

export const wishlistSuccess =(data)=>({
    type:WISHLIST_SUCCESS,
    payload:data
})

export const wishlistFailure = (error)=>({
    type:WISHLIST_FAILURE,
    payload:error
})