import ImageIcon from '@mui/icons-material/Image';
const Gallery = (props) => {
    return <div className="package_gallery">
        <div className="package_img1" style={{ background: `url(${props.img[0]}) center / cover no-repeat`, borderRadius: "1.5vmin" }}></div>
        <div className='rhs_gallery'>
            <div className="package_img2" style={{ background: `url(${props.img[1]}) center / cover no-repeat`, borderRadius: "1.5vmin" }}></div>
            <div className="package_img3" style={{ background: `url(${props.img[2]}) center / cover no-repeat`, borderRadius: "1.5vmin" }}>
                <div className="showAllPicture" onClick={props.previewHandler} ><div className="showAllPictureWrapper"><ImageIcon style={{color:"#FBCC7A", fontSize:"20px", marginRight:"1vmin"}}/>Show All Photos</div></div>
            </div>
        </div>
    </div>
}

export default Gallery;