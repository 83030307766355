import { useState,useEffect } from 'react';
import './SignUpCarousel.css'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { ScreenWidthUpdater } from '../../../../shared/utils/screenWidthUpdater';

const SignUpCarousel = ({ slidesData }) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const {screenWidth} = ScreenWidthUpdater();
    const gotoprevious = (e) => {
        e.stopPropagation();
        const isFirstIndex = currentIndex === 0;
        const newIndex = isFirstIndex ? slidesData.length - 1 :
            currentIndex - 1;
        setCurrentIndex(newIndex)
     
    }

    const gotonext = (e) => {
        // e.stopPropagation();
        const isLastIndex = currentIndex === slidesData.length - 1;
        const newIndex = isLastIndex ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex)
      
    }
    const gotoslides = (slideIndex,e) => {
        e.stopPropagation();
        setCurrentIndex(slideIndex)
    }

    // Autoplay functionality
    useEffect(() => {
        const intervalId = setInterval(() => {
            gotonext();
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentIndex]);

    const sliderStyle = {
        width: '100%',
        height: '236px',
        backgroundImage: `url(${slidesData[currentIndex].img})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat:'no-repeat'
    }
    const sliderMobStyle = {
        width: '200px',
        height: '144px',
        borderRadius: '3px',
        backgroundImage: `url(${slidesData[currentIndex].img})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat:'no-repeat'
    }
    return (
        <div className="slider">
            <div className="slider-image-title" style={{ whiteSpace: 'pre-line' }}>{slidesData[currentIndex].text}</div>
            <div className="slider-main">
                <div className='Larrow' onClick={gotoprevious}><ArrowBackIosNewRoundedIcon className='sgn-arrow-icon'/></div>
                <div style={screenWidth < 600 ? sliderMobStyle : sliderStyle}></div>
                <div className='Rarrow' onClick={gotonext}><ArrowForwardIosRoundedIcon className='sgn-arrow-icon' /></div>
            </div>
            <div className='dotContainer'>
                {slidesData.map((slide, slideIndex) => (
                    <div key={slideIndex} className={currentIndex === slideIndex ? 'active-dot' : 'dot'}
                        onClick={(e) => gotoslides(slideIndex)}>
                        {/* <CircleIcon style={{ fontSize: '10px' }} /> */}
                    </div>
                ))}
            </div>
        </div>
    )
}
export default SignUpCarousel
