export const travlpointLinks = [
    {
      id: 1,
      name: "Home",
      path: "/",
    },
    {
      id: 2,
      name: "Explore",
      path: "/package",
    },
    {
      id: 3,
      name: "Wishlist",
      path: "/wishlist",
    },
    {
      id: 4,
      name: "Blogs",
      path: "/blog-articles",
    },
    {
      id: 5,
      name: "Cart",
      path: "/cart",
    },
    {
      id: 6,
      name: "Login/signIn",
      path: "/login",
    },
    {
      id: 7,
      name: "Signup/Register",
      path: "/signup",
    },
    {
      id: 8,
      name: "About Us",
      path: "/about",
    },
    {
      id: 9,
      name: "Search Page",
      path: "/search",
    },
  ];

export const categoriesLinks =[
    {
        id: 1,
        type:"Hill Station",
        path:"/categories/hill station",
      },
      {
        id: 2,
        type:"Historical",
        path: "/categories/historical",
      },
      {
        id: 3,
        type:"Holy",
        path: "/categories/holy",
      },
      {
        id: 4,
        type:"Bachelors",
        path: "/categories/bachelors",
      },
      {
        id: 5,
        type:"Family",
        path: "/categories/family"
      },
      {
        id: 6,
        type:"Vacations",
        path: "/categories/vacations",
      },
      {
        id: 7,
        type:"Science",
        path: "/categories/science",
      },
      {
        id: 8,
        type:"Amusement",
        path: "/categories/amusement",
      },
      {
        id: 9,
        type:"Mythology",
        path: "/categories/mythology",
      },
      {
        id: 10,
        type:"Monuments",
        path: "/categories/monuments",
      },
      // {
      //   id: 11,
      //   type:"British",
      //   path: "/categories/british",
      // },
      {
        id: 12,
        type:"Honeymoon",
        path: "/categories/honey-moon",
      },
]