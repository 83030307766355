import React from 'react';
import './HeadingPara.css'

const HeadingPara = ({heading,subHeading,location}) => {
    return (
        <div className="paragraph-sec">
            <p className="upper-heading" >{heading} <span>{location}</span></p>
            <p className="lower-para">{subHeading}</p>
        </div>
    )
}

export default HeadingPara;