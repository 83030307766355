import { setLoading } from "../../../../shared/application/actions/ui";
import { VALIDATION_LOADED, signupValidationFailure, signupValidationSuccess } from "../actions/validateData";

const validateDataFlow = ({ api }) => ({ dispatch }) => next => async (action) => {
    next(action);

    if (action.type === VALIDATION_LOADED) {
        const { email,mobileNumber,referralCode} = action.payload;
        try {
            dispatch(setLoading(true));
            const response = await api.validateDataApi.validateData(email,mobileNumber,referralCode);
            dispatch(signupValidationSuccess(response));
            dispatch(setLoading(false));

        }catch (error) {
            dispatch(signupValidationFailure(error))
            dispatch(setLoading(false));
        }
    }
}

const validateFlow = [
    validateDataFlow,
]
export default  validateFlow ;