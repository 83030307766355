export const SINGLE_WISHLIST_ITEMS_LOADED = '[single wishlist Items ] loaded'
export const SINGLE_WISHLIST_ITEMS_SUCCESS = '[single wishlist Items ] success'
export const SINGLE_WISHLIST_ITEMS_FAILURE = '[single wishlist Items ] failure'

export const singleWishlistItemsLoaded = {
    type:SINGLE_WISHLIST_ITEMS_LOADED
}

export const singleWishlistItemsSuccess = (data) =>({
    type:SINGLE_WISHLIST_ITEMS_SUCCESS,
    payload:data
})

export const singleWishlistItemsFailure = (error) =>({
    type:SINGLE_WISHLIST_ITEMS_FAILURE,
    payload:error
})
