import { useState } from 'react'

export const useLocalStorage = () => {
    const initialArray = localStorage.getItem('recent_visited_places');
    const [recentVisitedPlaces, setRecentVisitedPlaces] = useState(initialArray === null ? [] : JSON.parse([initialArray]));

    const addObjectToStorage = (newObject) =>{
        const newArray = [...recentVisitedPlaces];

        const isObjectUnique = newArray.every(
            (obj) => obj.uniqueIdentifier !== newObject.uniqueIdentifier
        );

        if (isObjectUnique) {
            newArray.unshift(newObject);
            if (newArray.length > 5) {
                newArray.pop();
            }

            setRecentVisitedPlaces(newArray);
            localStorage.setItem('recent_visited_places', JSON.stringify(newArray));
        }
    };

    const handleAddObject = (place, type) => {
        // console.log("useLocalStorage", place)
        if (type === "single") {
            const uniqueIdentifier = place.TP_ID.toString();
            const newObject = {
                uniqueIdentifier,
                tpId: place.TP_ID,
                sl: place.Sl,
                place: place.Place,
                rating: place.Rating,
                openingTime: place.Opening_time,
                closingTime: place.Closing_time,
                imageUrl: place.IMG[0],
                entry:place.Entry
            };
            addObjectToStorage(newObject);
        } else {
            const uniqueIdentifier = place.data.details.tpId.toString();
            const newObject = {
                uniqueIdentifier,
                tpId: place.data.details.tpId,
                place: place.data.details.name,
                rating: place.data.details.rating,
                overView: place.data.details.overview,
                imageUrl: place.data.imageList[0],
            };
            addObjectToStorage(newObject);
        }
    };

    return { handleAddObject }
}