import React from "react";
import "./Copyright.css";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>© 2023 TravlPoint Venture</p>
    </div>
  );
};
export default Copyright;
