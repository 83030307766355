import React from 'react';
import './PlaceCard.css'
import PlaceImage from '../../components/place-image/PlaceImage';
import PlaceName from '../../components/place-name/PlaceName';
import PlaceAddressRow from '../../components/place-address-row/PlaceAddressRow';
import WeekRow from '../../components/week-row/WeekRow';
import RedBtnSolid from '../../../../shared/components/redbtn-solid/RedBtnSolid';
import PlaceTiming from '../../components/place-timing/PlaceTiming';
import { SetLength } from '../../../../shared/hooks/useSetLength';
import { ScreenWidthUpdater } from '../../../../shared/utils/screenWidthUpdater';
import usePackageDetails from '../../../../shared/hooks/usePackageDetails';


const PlaceCard = ({ type, placeData, getPopUpData}) => {
  const { sl, place, district, state, entry, tpId, name, coverPicture, totalPlaces, address, rating, overview, categories, image1, openingTime, closingTime, holiday } = placeData;
  
  const {packageDetails} = usePackageDetails();
  const {screenWidth} = ScreenWidthUpdater();

  const handleMobPlaceDetails =()=>{
    if(screenWidth<600){
      sl?getPopUpData(sl):packageDetails(tpId)
    }
  }

  return (
    <div className="place-card" onClick={handleMobPlaceDetails}>
      <PlaceImage type={type} imageUrl={type === "single" ? image1 : coverPicture} />
      <div className="place-card-details">
        <PlaceName placeName={type === "single" ? place : name} cost={entry} type={type} />
        <PlaceAddressRow district={district} state={state} address={address} type={type} rating={rating} />
        {
          type === "single" ? <>
            <WeekRow type={type} holiday={holiday} openingTime={openingTime} closingTime={closingTime} />
            <div className="place-category">
              {
                categories.map((category) => (
                  <RedBtnSolid btnName={category} key={category} />
                ))
              }
            </div>
          </> :
            <>
              <div className="place-description">
                {overview&&SetLength(overview, 224)}
              </div>
            </>
        }
        <div className="place-timings">
          <div className="place-clock">
            <PlaceTiming type={type} openingTime={openingTime} closingTime={closingTime} totalPlaces={totalPlaces} />
          </div>
          <div className="btn-more" onClick={()=>{type==="single"?getPopUpData(sl) :packageDetails( tpId)}}>
            <RedBtnSolid btnName={"More Info"} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaceCard;