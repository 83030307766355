import React from 'react';
import './Categories.css'
import HeadingPara from '../../../../shared/components/heading-para/HeadingPara';
import CategoryCard from '../../components/category-card/CategoryCard';
// import '../../../../components/CategoryPage/CategoryPage.css'
import './Categories.css'
import { CategoryTitle } from '../../assets/CategoryTitle';

const Categories = ({handleCategory}) => {

  return (
    <div className="categories-wrapper">
        <div className="categories">
            <HeadingPara heading={"Popular Categories"}/>

            <div className="categories-cards">
                {
                    CategoryTitle.map((data,id)=>(
                        <div className="categories-card" key={id}>
                            <CategoryCard 
                                title ={data.title}
                                image = {data.imgUrl}
                                handleCategory={handleCategory}
                                path={data.path}
                                type={data.type}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Categories;