export const BLOG_DATA_LOADED = '[ blog ] blog data loaded';
export const BLOG_DATA_SUCCESS = '[ blog ] blog data success';
export const BLOG_DATA_FAILURES = '[ blog ] blog data failures';

export const blogDataLoaded  = {
    type:BLOG_DATA_LOADED,
}

export const blogDataSuccess = (data) =>({
    type:BLOG_DATA_SUCCESS,
    payload:data
})

export const blogDataFailure = (error) => ({
    type:BLOG_DATA_FAILURES,
    payload:error
})