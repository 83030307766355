import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VarNavItem from "../var-navItem/VarNavItem";
import { ScreenWidthUpdater } from "../../../shared/utils/screenWidthUpdater";

export const NavbarHandler = () => {
  const [MobileNav, SetMobileNav] = useState(false);
  const { screenWidth } = ScreenWidthUpdater();

  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();
  const xtoken = localStorage.getItem("xtoken");

  function toggleNav(){
    if (screenWidth < 600) {
      if (MobileNav === true) {
        SetMobileNav(false);
      } else {
        SetMobileNav(true);
      }
    }
  }

  const { getComponent } = VarNavItem(toggleNav, MobileNav);

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.body.addEventListener("touchmove", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  return { xtoken, location, getComponent, scrolling, MobileNav,toggleNav};
};
