import { Link } from "react-router-dom";
import "./SiteMap.css";
import { supportData } from "../../assets/data/FooterData";
import { categoriesLinks, travlpointLinks } from "./assets/sitemapdata";

const Section = ({ title, links, handleCategory }) => (
  <div className="sitemp-1">
    <h3>{title}</h3>
    <div className="site-links">
      {links.map((data) => (
        <div key={data.id} className="link-div">
          <Link
            to={data.path}
            className="site-link"
            onClick={data.type ? () => handleCategory(data.type) : undefined}
          >
            {data.name || data.title || data.type}
          </Link>
          <div className="divider"></div>
        </div>
      ))}
    </div>
  </div>
);

const SiteMap = ({ handleCategory }) => {
  const sections = [
    { title: "TravlPoint", links: travlpointLinks },
    { title: "Support", links: supportData },
    { title: "Travlpoint Categories", links: categoriesLinks.map((link) => ({ ...link, name: link.type })) },
    { title: "Mobile", links: [{ id: "mobile-app", path: "https://play.google.com/store/apps/details?id=com.app.travlpoint", name: "TravlPoint App" }] }
  ];

  return (
    <div className="SiteMap">
      <div className="SiteMap-Wrapper">
        <h2 className="title">SiteMap</h2>
        {sections.map((section) => (
          <Section key={section.title} title={section.title} links={section.links} handleCategory={handleCategory} />
        ))}
      </div>
    </div>
  );
};

export default SiteMap;
