import React from "react";
import "./PromotedCard.css";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import { sortState } from "../../../../shared/utils/sortState";

const PromotedCard = (props) => {
  const BackgroundImage = {
    background: `url(${props.data.coverPicture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  function setName(Place, value) {
    if (Place.length <= value) {
      return Place;
    } else {
      return Place.slice(0, value - 1) + "...";
    }
  }

  // const tempRate = Math.floor(Math.random() * (4 - 2 + 1)) + 2;

  return (
    <div className="promoted-card">
      <div
        className="p-card-image"
        style={BackgroundImage}
        onClick={() => props.PackageDetails(props.data["tpId"])}
      >
        <div className="pcard-rating">
          <StarBorderPurple500Icon
            style={{ fontSize: "19px", marginRight: "4px" }}
          />{" "}
          <span style={{ fontSize: "14px" }}>
            {props.data.rating}
          </span>
        </div>
        <div className="pcard-btn">
          <button className="Book-Now-btn">Book Now</button>
        </div>
      </div>
      <div className="pcard-details">
        <div className="pcard-place">
          <p className="pcard-name">{setName(props.data.name, 13)}</p>
          <p className="pcard-address">
            {setName(
              props.data.name.slice(0, -7) + ", " + sortState(props.data.tpId),
              18
            )}
          </p>
        </div>
        <div className="unknown-icon">#{props.star + 1}</div>
      </div>
    </div>
  );
};

export default PromotedCard;
