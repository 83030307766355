export const validatePassword = (Security, Password) => {
  const errors = {};

  if (!Security.OldPassword) {
    errors.OldPassword = "Please enter your password";
  }
  if (!Security.NewPassword || !Security.ConfirmPassword) {
    if (!Security.NewPassword) {
      errors.NewPassword = "Please enter your New password";
    }
    if (!Security.ConfirmPassword) {
      errors.ConfirmPassword = "Please confirm your password";
    }
  } else if (Security.NewPassword !== Security.ConfirmPassword) {
    errors.ConfirmPassword = "Password do not match";
  }
  return errors;
};
