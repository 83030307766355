import React from 'react';
import { Link} from 'react-router-dom';
import './RedBtn.css'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const RedBtn = ({ id, btnName, title,bannerType,getPopUpData,PackageDetails}) => {
    const formattedTitle = title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
    return (
        <>
            {
                (bannerType === "B") ?
                    <>
                        <Link to={`/blog-articles/${formattedTitle}/${id}`} className="banner-btn" > 
                            <p id="banner-txt">{btnName}</p>
                            <ArrowCircleRightOutlinedIcon className='red-btn-icon' />
                        </Link>
                    </> :
                    <>
                        <div className="banner-btn" onClick={() => {bannerType === "S" ?getPopUpData(id):PackageDetails(id)}}>
                            <p id="banner-txt">{btnName}</p>
                            <ArrowCircleRightOutlinedIcon className='red-btn-icon' />
                        </div>
                    </>
            }
        </>
    )
}

export default RedBtn;