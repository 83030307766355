import React from 'react';
import './MostVisited.css'
import DownloadAppCard from '../../components/download-app-card/DownloadAppCard';
import MostVisitedCard from '../most-visited-card/MostVisitedCard';
import usePopup from '../../../../shared/hooks/usePopup';

const MostVisited = () => {
  const {getPopUpData} = usePopup();
  const mostRecentSearchPlaces = localStorage.getItem('recent_visited_places');
  return (
    <div className="most-visited">
      <DownloadAppCard />
      <div className="most-visited-package">
        <div className="mvp-heading">Most Recent Visited Places</div>
        {
          mostRecentSearchPlaces ? JSON.parse(mostRecentSearchPlaces).map((data, id) => (
            <>
              <MostVisitedCard type={data.sl ? "single" : "package"} key={id} data={data} getPopUpData={getPopUpData} />
              <div className="mv-line" ></div>
            </>

          )):
            <div className='no-result'>No Recent Search Available !!!!</div>
        }
      </div>
      {/* DO NOT DELETE THIS CODE */}
      {/* <div className="most-visited-package">
        <div className="mvp-heading">Most Visited Package This Week</div>
        <MostVisitedCard type={"package"} />
        <div className="mv-line"></div>
        <MostVisitedCard type={"package"} />
        <div className="mv-line"></div>
        <MostVisitedCard type={"package"} />
      </div> */}
      {/* <div className="most-visited-places">
        <div className="mvp-heading">Most Visited Places This Week</div>
        <MostVisitedCard type={"single"} />
        <div className="mv-line"></div>
        <MostVisitedCard type={"single"} />
        <div className="mv-line"></div>
        <MostVisitedCard type={"single"} />
      </div> */}
    </div>
  )
}

export default MostVisited;