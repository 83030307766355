import {useState} from "react";
import { TrendingData , getPackageByID, getPlacestoVisitReviewByTPID, addToCartForPlaces } from "../services/PackageData";

export const PackageViewModel =()=>{
    const [trendPlaces, setTrendPlaces] = useState([])

    async function getTrendData(){
        const result = await TrendingData();
        setTrendPlaces(result);
    }

    function GetOnePackage(tpid) {
        return new Promise((resolve, reject) => {
            getPackageByID(tpid).then(details => {
                resolve({
                    "error":details.error,
                    "package_data":details.package_data
                })
            })
        })
    }

    // function GetOnePackageReview(tpid) {
    //     return new Promise((resolve, reject) => {
    //         getPackageReviewByID(tpid).then(details => {
    //             resolve({
    //                 "error":details.error,
    //                 "review_data":details.review_data
    //             })
    //         })
    //     })
    // }

    function GetAllPlacestoVisitReviewByTPID(tpid) {
        return new Promise((resolve, reject) => {
            getPlacestoVisitReviewByTPID(tpid).then(details => {
                resolve({
                    "error":details.error,
                    "placestovisit_data":details.placestovisit_data
                })
            })
        })
    }

    function CartPageForTPID(tpid, token, alOfAllPlaces) {
        return new Promise((resolve, reject) => {
            addToCartForPlaces(tpid, token, alOfAllPlaces).then(details => {
                resolve({
                    "error":details.error,
                    "message":details.message
                })
            })
        })
    }

    return{
        getTrendData,
        trendPlaces,
        GetOnePackage,
        // GetOnePackageReview,
        GetAllPlacestoVisitReviewByTPID,
        CartPageForTPID
    }
}

