import "./Populars.css"
import HeadingPara from '../../../../shared/components/heading-para/HeadingPara';
import TopPlaceCard from '../../components/top-place-card/TopPlaceCard';
import { getPopularInStateData } from '../../../../shared/application/selectors/homeData';
import { useSelector } from 'react-redux';
import useCustomSlice from '../../hooks/useCustomSlice';
import useMemoizedState from '../../hooks/useMemoizedState';

const Populars = () => {
    const getPopularData = useSelector(getPopularInStateData);
    const {Slice} = useCustomSlice();
    const {data} = useMemoizedState(getPopularData);
    let popular = data;

    if ( (popular && (popular.length === 0))) {
        return (
            <div className="Loading">
                <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
            </div>
        )
    } 

    return (
        <div className="popular-wrapper">
            <div className="populars">
                <div className="popular-heading">
                    <HeadingPara heading={"Popular in "} location={popular.state} subHeading={"Explore the top places at your location"} />
                </div>
                <div className="top-popular-cards">
                    {
                    popular.places
                        .slice(0, Slice)
                        .map((data, id) =>(
                            <div className="popular-card" key={id}>
                                <TopPlaceCard
                                    sl={data.sl}
                                    tpId={data.tpId}
                                    image={data.image1}
                                    name={data.place}
                                    rating={data.rating}
                                    totalPlaces={data.totalPlaces}
                                    locality={data.locality}
                                    state={data.state}
                                    placeData = {data}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Populars;