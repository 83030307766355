import { ScreenWidthUpdater } from "../../../shared/utils/screenWidthUpdater";


export const ImageUrlsData = () => {
    const { screenWidth } = ScreenWidthUpdater();

    const imageUrlsData = [
        {
            img: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Taj_Mahal%2C_Agra%2C_India_edit3.jpg",
            bottom: screenWidth < 601 ? "-24px" : "-44px",
            top: "auto"

        },
        {
            img: "https://youngstatues.net/wp-content/uploads/2021/09/HawaMahal-Jaipur.jpg",
            top: screenWidth < 601 ? "-12px" : "-24px",
            bottom: "auto"
        },
        {
            img: "https://www.andbeyond.com/wp-content/uploads/sites/5/A-female-elephant-with-two-young-Kaziranga-national-park-in-north-east-India..jpg",
            bottom: screenWidth < 601 ? "-24px" : "-44px",
            top: "auto"
        },
        {
            img: "https://i0.wp.com/touristjourney.com/wp-content/uploads/2021/03/Architecture-Culture-of-Mumbai-5-Day-Tour-Package_10.jpg",
            top: screenWidth < 601 ? "-12px" : "-24px",
            bottom: "auto"
        }
    ]
    return { imageUrlsData };
} 