export const SEARCH_BLOGS_LOADING = '[ search blog ] search blogs loading';
export const SEARCH_BLOGS_SUCCESS = '[ search blog ] search blogs success';
export const SEARCH_BLOGS_FAILURE = '[ search blog ] search blogs failure';

export const searchBlogsLoading  = (searchKey,pageNo)=> ({
    type:SEARCH_BLOGS_LOADING,
    payload:{searchKey,pageNo}
})

export const searchBlogsSuccess = (data) =>({
    type:SEARCH_BLOGS_SUCCESS ,
    payload:data
})

export const searchBlogsFailure = (error) => ({
    type:SEARCH_BLOGS_FAILURE,
    payload:error
})