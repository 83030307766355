import ViewButton from "../../components/view-btn/ViewButton";
import WhislistCard from "../wishlist-card/WhislistCard";
import "./WishlistContainer.css";
import { sortState } from "../../../../shared/utils/sortState";
import { WishlistContainerHandler } from "../../utils/WishlistContainerHandler";

const WishlistContainer = (props) => {
  const { WishlistItem, placeType } = props;
  const { handleViewAll, slice, view } = WishlistContainerHandler(WishlistItem);

  return (
    <div className="places">
      <p id="container-title">{placeType}</p>
      <div className="whislistCards">
        {WishlistItem&&WishlistItem.length !== 0 ? (
          <>
            {WishlistItem&&WishlistItem.slice(0, slice).map((items) => {
              return (
                <div className="w-card" key={items.sl + items.tpId}>
                  <WhislistCard
                    items={items}
                    Sl={items.sl}
                    place={items.sl ? items.place : items.name}
                    locality={
                      items.sl ? items.locality : sortState(items.tpId)
                    }
                    state={items.sl ? items.state : ""}
                    img={items.sl ? items.image1 : items.coverPicture}
                    TP_ID={items.tpId}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <div className="No-place">
            <img
              src="https://freepngimg.com/thumb/sad_emoji/36885-5-sad-emoji-photos.png"
              alt=""
              style={{ width: "96px", marginBottom: "10px" }}
            />
            <p id="noti"> Ooops! No Place In Your {placeType} WishList !!</p>
          </div>
        )}
      </div>
      {WishlistItem&&WishlistItem.length>8&&(
        <ViewButton handleViewAll={handleViewAll} view={view} />
      )}
    </div>
  );
};

export default WishlistContainer;
