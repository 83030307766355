import { useCallback } from 'react';
import { useState, useEffect } from 'react';

export const useAddress = () => {
    const [address, setAddress] = useState(
        !localStorage.getItem("state") ? localStorage.setItem("state", "N/A") : localStorage.getItem("state"));

    const [location, setLocation] = useState({
        latitude: "",
        longitude: ""
    })

    useEffect(() => {
        const requestLocationPermission = async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
                if (permissionStatus.state === 'granted' || localStorage.getItem("geolocationPermission") === "granted") {
                    navigator.geolocation.getCurrentPosition((position) => {
                        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                    });
                } else if (permissionStatus.state === 'prompt') {
                    navigator.geolocation.getCurrentPosition((position) => {
                        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
                        localStorage.setItem("geolocationPermission", "granted");
                    },
                        () => {
                            setLocation({ latitude: "", longitude: "" });
                            localStorage.setItem("state", "N/A")
                            localStorage.setItem("geolocationPermission", "denied");
                        }
                    );
                }
            } catch (error) {
                console.error('Error checking or requesting geolocation permission', error);
            }
        };
        if(address === "N/A" && !localStorage.getItem("geolocationPermission")){
            requestLocationPermission();
        }
    }, []);

    const fetchData = useCallback(async () => {
        if (location.latitude && location.longitude) {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}`
                );
                const data = await response.json();
                localStorage.setItem("state", data.address.state)
                setAddress(data);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
    }, [location.latitude && location.longitude]);

    useEffect(() => {
        if (location.latitude && location.longitude) {
            fetchData();
        }
    }, [location.latitude, location.longitude]);

    return { address };

};
