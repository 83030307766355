import { setLoading } from '../../../../shared/application/actions/ui';
import { CART_DATA_LOADED, cartDataFailure, cartDataSuccess } from '../actions/getCartData';
import { RECOMENDED_CART_DATA_LOADED, recomendedCartDataFailure, recomendedCartDataSuccess } from '../actions/getRecomendedCartData';

const getCartDataFlow = ({api}) =>({dispatch})=>next => async(action)=>{
  next(action);

  if(action.type === CART_DATA_LOADED){
      try{
          dispatch(setLoading(true));
          const response = await api.cartApi.cartData();
          dispatch(cartDataSuccess(response))
          dispatch(setLoading(false));
      }
      catch(error){
        dispatch(cartDataFailure(error));
    }
  }

}

const getRecomendedCartDataFlow = ({api}) =>({dispatch})=>next => async(action)=>{
  next(action);

  if(action.type === RECOMENDED_CART_DATA_LOADED){
      try{
          dispatch(setLoading(true));
          const response = await api.cartApi.recomendedCartData(action.payload);
          console.log("recomn",response)
          dispatch(recomendedCartDataSuccess(response))
          dispatch(setLoading(false));
      }
      catch(error){
        dispatch(recomendedCartDataFailure(error));
        dispatch(setLoading(false));
    }
  }

}

const CartDataFlow = [
  getCartDataFlow,
  getRecomendedCartDataFlow
] 
export default CartDataFlow;