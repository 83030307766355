import "./TrendingSec.css";
import TrendingSecCard from "./trending-sec-card/TrendingSecCard";
import { useSelector } from "react-redux";
// import DropMenu from "./drop-menu/DropMenu";
import HeadingPara from "../../../../shared/components/heading-para/HeadingPara";
import useCustomSlice from "../../hooks/useCustomSlice";
import { getSpotLightPlaces } from "../../../../shared/application/selectors/homeData";
import useMemoizedState from "../../hooks/useMemoizedState";


const TrendingSec = () => {
  const getTrendData = useSelector(getSpotLightPlaces)
  const { Slice } = useCustomSlice();

  const {data} = useMemoizedState(getTrendData);
  let trending = data;

  //////////////////////////////////////////////////////////////////////////////////
  // old code
  // const [filter, setFilter] = useState("All");
  // const copyTrending = props.trending;
  // const [trending, setTrending] = useState(props.trending);

  // dropDown code to filter category
  // const [Arrow, setArrow] = useState(false);

  // function handlingArrow(){
  //   Arrow ? setArrow(false) : setArrow(true);
  // }

  // function filterCategory(e) {
  //   console.log(e.target.value);
  //   setFilter(e.target.value);
  // }

  // useEffect(() => {
  //   if (filter !== "All") {
  //     let tempTrending = [];
  //     let index = [];
  //     copyTrending.forEach((element, i) => {
  //       if (element.Category.includes(filter)) {
  //         tempTrending.push(element);
  //         index.push(i);
  //       }
  //     });
  //     copyTrending.forEach((element, i) => {
  //       if (!index.includes(i)) {
  //         tempTrending.push(element);
  //         index.push(i);
  //       }
  //     });
  //     setTrending(tempTrending);
  //   } else {
  //     setTrending(copyTrending)
  //   }
  // }, [filter, copyTrending]);
  ////////////////////////////////////////////////////////////

  if (getTrendData && getTrendData.length === 0) {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    )
  }
  
  return (
    <>
      <div className="TrendingSec">
        <div className="main-div">
          <div className="upper_part">
            <HeadingPara heading={"Discover Weekly"} subHeading={"We’ve selected some trending places of this week for you"} />

            {/* <DropMenu filterCategory={filterCategory} filter={filter} Arrow={Arrow} handlingArrow={handlingArrow} /> */}

          </div>
          <div className="trending_card">
            {/* // .sort((a, b) => b["bookedBy"] - a["bookedBy"]) */}
            {
              trending.slice(0, Slice)
                .map((element, i) => {
                  return (
                    <div className="card-section" key={i}>
                      <TrendingSecCard
                        sl={element.sl}
                        name={element.place}
                        imgURL={element.image1}
                        bookedby={element.bookedBy}
                        locationName={element.locality}
                        stateName={element.state}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendingSec;
