export const LIKE_BLOG_LOADING = '[ blog like ] like blog loading';
export const LIKE_BLOG_SUCCESS = '[ blog like ] like blog success';
export const LIKE_BLOG_FAILURE = '[ blog like ] like blog failure';

export const likeBlogLoading = (blogId) => ({
    type: LIKE_BLOG_LOADING,
    payload: { blogId }
});

export const likeBlogSuccess = (blogId, updatedData) => ({
    type: LIKE_BLOG_SUCCESS,
    payload: { blogId, updatedData }
});

export const likeBlogFailure = (blogId, error) => ({
    type: LIKE_BLOG_FAILURE,
    payload: { blogId, error }
});