import { TRENDING_PACKAGE_FAILURE, TRENDING_PACKAGE_SUCCESS } from "../actions/getTrendingPackage"

const initialState = {
    trendingPackage:[],
    error:null
}

const TrendingPackageReducers = (state=initialState, action) => {
    switch(action.type){
        case TRENDING_PACKAGE_SUCCESS:
            return{
                ...state,
                trendingPackage:action.payload,
                error:null
            }
        case TRENDING_PACKAGE_FAILURE:
            return{
                ...state,
                trendingPackage:action.payload,
                error:true
            }

        default:
            return state;

    }
}

export default TrendingPackageReducers;