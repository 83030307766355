import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getTripDataUrl, planTripUrl } from '../../../assets/urls';

const useTripPageData = (setTripDetail) => {
    const location = useLocation()
    const tripdata = location.state.data;

    const [Data, setData] = useState(null);
    const [error, setError] = useState("")
    let [OverallVal, setOverallVal] = useState(0);

    let [LocationVal, setLocationVal] = useState(80);
    let [VisitingVal, setVisitingVal] = useState(0);

    useEffect(() => {
        fetch(planTripUrl(tripdata.tpid,tripdata.places,tripdata.sdate,tripdata.edate))
          .then((res) => {
            if(res.ok){
              return res.json();
            }else{
              throw new Error("Error Found");
            }
          })
          .then((data) => {
            setVisitingVal(
              (data["Number of Places"] / tripdata.places.length) * 100
            );
            setOverallVal((VisitingVal + LocationVal) / 2);
            setData(data);
          }).catch(err =>{
            setError(err)
          })
        getTripData();
      }, [tripdata, OverallVal, VisitingVal, LocationVal]);
    
      function getcol(val) {
        if (val < 50) {
          return "#FF0404";
        } else if (val >= 50 && val < 80) {
          return "#FA6A41";
        } else if (val >= 80 && val < 90) {
          return "#B2F87A";
        } else {
          return "#00ED26";
        }
      }
    
      function getRemark(val) {
        if (val < 50) {
          return "'SATISFACTORY'";
        } else if (val >= 50 && val < 70){
          return "'AVERAGE'";
        } else if (val >= 70 && val < 90){
          return "'GOOD'";
        } else {
          return "'EXCELLENT'";
        }
      }

      const getTripData = async() => {
        const xtokens = JSON.parse(localStorage.getItem("xtoken"));
        if (xtokens) {
          const url = getTripDataUrl;
          try {
            await fetch(url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: xtokens.accessToken,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                setTripDetail(data);
              });
          } catch (e) {

            console.log(e);
          }
        }
      };
  return {getRemark,getcol,error,Data,OverallVal,LocationVal,VisitingVal};
}
export default useTripPageData