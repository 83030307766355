import { useSelector } from 'react-redux';
import { getToken } from '../../login/application/selectors/getToken';
import { getSignupToken } from '../../signup/applications/selectors/getToken';
import { profilDataUrl } from '../../../assets/urls';

const useProfileApi = () => {
    const loginToken = useSelector(getToken);
    const signUpToken = useSelector(getSignupToken);
    const Token = loginToken && Object.keys(loginToken).length !== 0 ? loginToken : signUpToken


    //////////////////////////////////profile data code
    const xtokens = JSON.parse(localStorage.getItem('xtoken'));

    const getProfileData = async () => {
        if (Token.data || xtokens&& xtokens.accessToken) {
            const url = profilDataUrl;
            const options = {
                method: "GET",
                headers: {
                    "Authorization": xtokens.accessToken
                }
            }

            const response = await fetch(url, options);
            const data = await response.json();

            if(response.ok){
                return data;
            }else{
                throw new Error(data)
            }
        }
    }

    return { getProfileData }
}

export default useProfileApi;