export const sortState = (id) => {
  let shortState = {
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chhattisgarh",
    GA: "Goa",
    GJ: "Gujrat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "West Bengal",
    AN: "Andman and Nicobar Island",
    CH: "Chandigarh",
    DHDD: "Dadra Nagar Haveli and daman and Diu",
    JK: "Jammu and Kashmir",
    LEH: "Ladakh",
    LD: "Lakshadweep",
    PY: "Puducherry",
    DL: "Delhi",
  };
  return shortState[id.slice(2, 4)];
};
