import "./Toast.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import useToast from "../../features/Toast/hooks/useToast";
import { SetLength } from "../../shared/hooks/useSetLength";

const Toast = () => {
  const { toastlist, deleteToast } = useToast();

  return (
    <div className="main-toast-container-wrapper">
      <div className="container  buttom-right">
        {toastlist.map((toast, i) => (
          <div
            key={i}
            className="notification toast buttom-right"
            style={{ backgroundColor: toast.backgroundColor }}
          >
            <div className="toast-icon">
              {" "}
              <span id="t-icon">
                {/* {showIcon()} */}
                {toast.title === "added" ? (
                  <>
                    <DoneAllIcon
                      style={{ fontSize: "36px", color: " #4ecb71" }}
                    />{" "}
                  </>
                ) : toast.title === "removed" ? (
                  <>
                    <CloseRoundedIcon
                      style={{ fontSize: "44px", color: " #F24E1E" }}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ fontSize: "36px", color: "#4ecb71" }}> !</div>
                  </>
                )}
              </span>
            </div>
            <button
              className="toast-del"
              style={{ backgorund: "grey", color: "#fbcc7a", opacity: "1" }}
              onClick={() => deleteToast(toast.id)}
            >
              <CloseRoundedIcon style={{ fontSize: "28px" }} />
            </button>
            <div className="toast-text">
              <p className="description">{toast.description}</p>
              {/* <p className='description'>{toast.description}</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Toast;