import React from "react";
import "./AboutBoards.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';

function AboutBoards() {
    return (
        <div className="AboutBoardsWrapper" id="meetWithUs">
            <p className="AboutBoardsHeading">MEET WITH US</p>
            <p className="AboutBoardsDesc">We endeavour to change the way of tourism of india</p>
            <div className="AboutBoardsMember">
                <div className="AboutBoardsCard">
                    <div className="AboutBoardsIMG" style={{
                        background: "url(./assets/images/CEO.jpg) center / cover no-repeat"
                    }}></div>
                    <p className="AboutBoardsName">Abhijeet Bhardwaj</p>
                    <p className="AboutBoardsDesignation">CEO & Founder</p>
                    <div className="AboutBoardsSocial">
                        <a className="AboutBoardsIcon" href="https://www.facebook.com/me.abhijeetbhardwaj" target={"_blank"} rel="noreferrer"><FacebookIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="https://www.instagram.com/me_abhi_cool/" target={"_blank"} rel="noreferrer"><InstagramIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="https://www.twitter.com/me_abhi_jeet" target={"_blank"} rel="noreferrer"><TwitterIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="mailto:cool_abhi@outlook.com" rel="noreferrer"><MailIcon sx={{fontSize: "28px"}}/></a>
                    </div>
                </div>
                <div className="AboutBoardsCard">
                    <div className="AboutBoardsIMG" style={{
                        background: "url(./assets/images/CPO.jpg) center / cover no-repeat"
                    }}></div>
                    <p className="AboutBoardsName">Vivek Kumar Gupta</p>
                    <p className="AboutBoardsDesignation">CPO & Co-Founder</p>
                    <div className="AboutBoardsSocial">
                        <a className="AboutBoardsIcon" href="mailto:imvivek94@outlook.com" rel="noreferrer"><MailIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="https://www.twitter.com/LunaticVKG" target={"_blank"} rel="noreferrer"><TwitterIcon sx={{fontSize: "28px"}}/></a>
                    </div>
                </div>
                <div className="AboutBoardsCard">
                    <div className="AboutBoardsIMG" style={{
                        background: "url(./assets/images/CMO.jpg) center / cover no-repeat"
                    }}></div>
                    <p className="AboutBoardsName">Avinash Kumar</p>
                    <p className="AboutBoardsDesignation">CMO & Co-Founder</p>
                    <div className="AboutBoardsSocial">
                        <a className="AboutBoardsIcon" href="https://www.facebook.com/profile.php?id=100007700175409" target={"_blank"} rel="noreferrer"><FacebookIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="https://www.instagram.com/me_avinash.ak" target={"_blank"} rel="noreferrer"><InstagramIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="https://www.twitter.com/Avinash3132" target={"_blank"} rel="noreferrer"><TwitterIcon sx={{fontSize: "28px"}}/></a>
                        <a className="AboutBoardsIcon" href="mailto:kumaravinash2966@gmail.com" rel="noreferrer"><MailIcon sx={{fontSize: "28px"}}/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutBoards;