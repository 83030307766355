import './SearchResult.css'
import PlaceCard from '../place-card/PlaceCard';
import { useSelector } from 'react-redux';
import { getLoading } from '../../../../shared/application/selectors/ui';
import { getFilterData, getFilterDataError, getNoOfResult, getPackageSearchData, getSinglePlacesSearchData } from '../../application/selectors/getSearchData';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useState } from 'react';
import { useEffect } from 'react';
import usePopup from '../../../../shared/hooks/usePopup';
import ErrorHandler from '../../../../shared/components/error/ErrorHandler';

const SearchResult = () => {
  const [placeData, SetPlaceData] = useState([])
  const {getPopUpData}=usePopup();
  const loading = useSelector(getLoading);
  const noOfResult = useSelector(getNoOfResult);
  const filterData = useSelector(getFilterData);
  const packageSearchData = useSelector(getPackageSearchData);
  const singlePlacesSearchData = useSelector(getSinglePlacesSearchData);
  const filterError = useSelector(getFilterDataError);

  useEffect(() => {
    if (packageSearchData || singlePlacesSearchData) {

        const combinedArray = packageSearchData.concat(singlePlacesSearchData);
        for (let i = combinedArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [combinedArray[i], combinedArray[j]] = [combinedArray[j], combinedArray[i]];
        }

        SetPlaceData(combinedArray);
    }
  }, [packageSearchData, singlePlacesSearchData])

  
  if (loading) {
    return (
      <div className="Loading" style={{ width: "100%" }}>
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    )
  }else if(filterError){
    return <ErrorHandler/>
  }
  else if (!loading && Object.keys(filterData).length === 0) {
    return (
      <div className="search-result-content">
        <div className="search-result-body">
          <div className="search-desination">
            <TravelExploreIcon className='search-travl' />
            Search Your Destinations......
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="search-result-content">
        <div className="search-result-body">
          <div className="search-result-header">
            <div className="s-result">RESULTS ({noOfResult})</div>
            <div className="s-sort">Sort by <span>Relevance</span></div>
          </div>
          <div className="place-cards">
            {
              placeData && placeData.map((data,id) => (
                <div key={id} >
                  <PlaceCard
                    type={data.sl ? "single" : "package"}
                    placeData={data}
                    getPopUpData={getPopUpData}
                  />
                  <div className="place-card-line"></div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchResult;