import { RECOMENDED_CART_DATA_FAILURE, RECOMENDED_CART_DATA_SUCCESS } from "../actions/getRecomendedCartData";

const initialState = {
    recomendedCartData:[],
    error:null
}

const recomendedCartReducers = (state=initialState, action)=>{
    switch(action.type){
        case RECOMENDED_CART_DATA_SUCCESS:
            return{
                ...state,
                recomendedCartData:action.payload,
                errror:false,
            }

        case RECOMENDED_CART_DATA_FAILURE:
            return{
                ...state,
                recomendedCartData:action.payload,
                error:true,
            }
        default:
            return initialState;
    }
}

export default  recomendedCartReducers;