import './SubscribeBar.css'
import RedBtnSolid from '../redbtn-solid/RedBtnSolid';
import useSubscription from '../../hooks/useSubscription';
import useEnterKey from '../../hooks/useEnterKey';

const SubscribeBar = () => {
    const {handleChange,handleSubmit,email} = useSubscription();
    const handleKeyDown = useEnterKey(handleSubmit);

    return (
        <div className="subscribe">
            <input type="text" placeholder='Enter Your email here.....' id='email' value={email} onChange={handleChange} onKeyDown={handleKeyDown}/>
            <RedBtnSolid btnName={"Subscribe"} handleSubmit = {handleSubmit} />
        </div>
    )
}

export default SubscribeBar;