import {useEffect, useState} from 'react'
import { SignupValidationStep2 } from '../utils/SignupValidation';
import { useDispatch, useSelector } from 'react-redux';
import { signupLoaded } from '../applications/actions/getSignUp';
import { OS } from '../../../shared/utils/deviceInfo';
import { useNavigate } from 'react-router-dom';
import { getSignupToken, getSignupTokenError } from '../applications/selectors/getToken';
import { useAddress } from '../../../shared/hooks/useAddress';
import useShowToast from "../../../shared/hooks/useShowToast";

const SignUpPageStep2Hook = (register) => {
    const { address } = useAddress();
    const [gender, setGender] = useState("Male");
    const [dob,setDob] = useState(findDate());
    const {showToast } = useShowToast();
    const error = useSelector(getSignupTokenError)

    const [signupStep2Error,setSignupStep2Error] = useState({
        gender:"",
        dob:"",
    })

    function findDate(today = new Date()) {
        let date = ("0" + today.getDate()).slice(-2);
        let month = ("0" + (today.getMonth() + 1)).slice(-2);
        let year = today.getFullYear();
        let _Date = year + "-" + month + "-" + date;
        return _Date;
      }
    
      const doBHandler = (e) => {
        setDob(e.target.value);
      };

      const handleGender = ()=>{
        setGender(gender==="Male"?"Female":"Male")
      }

      const dispatch = useDispatch();
      const handleContinueTOHome = async()=>{
        const errors = await SignupValidationStep2(dob,gender);

        if(Object.keys(errors).length ===0){
            const signUpAddress = address.display_name ? address.display_name : address;
            dispatch(signupLoaded(register,dob,gender,OS(window),signUpAddress))
        }else{
            setSignupStep2Error(errors)
        }
      }

      const users = useSelector(getSignupToken);
      const navigate = useNavigate();
      useEffect(() => {
        if (users && users.status) {
          navigate("/");
        }else if(error){
          showToast("info","SomeThing Went Wrong Try Again")
        }
      }, [navigate, users, users.status]);

  return {dob,doBHandler,gender,handleGender,handleContinueTOHome,signupStep2Error}
}

export default SignUpPageStep2Hook