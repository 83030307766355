import { useState } from "react";
import "./CopyLinkPopup.css";
import SocialLinks from "../../../../shared/components/social-links/SocialLinks";

const CopyLinkPopup = ({ url, onClose,title,image }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setIsCopied(true);
        setInterval(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return(
    <div className="copy-link-popup">
      <div className="copy-link-overlay-screen" onClick={onClose}></div>
      <div className="popup">
        <div className="popup-tp">
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
          <h2>Share This Awesome Blog!</h2>
        </div>
        <SocialLinks url ={url} title ={title} image ={image} />

        <p>or copy link</p>
        <div className="copy-link-container">
          <input type="url" value={url} readOnly />
          <button className="copy-button" onClick={copyToClipboard}>
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CopyLinkPopup;
