import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscription } from '../application/actions/getSubscription';
import { useLocation } from 'react-router-dom';
import { getNewsSubscriptionData, getNewsSubscriptionError } from '../application/selectors/subscription';
import useShowToast from './useShowToast';

// funtion to notify Subscription
const NotifySubscription = (notifyTrigger) => {
    const { showToast } = useShowToast();
    const newsSubscriptionInfo = useSelector(getNewsSubscriptionData);
    const newsSubscriptionError = useSelector(getNewsSubscriptionError)

    const notifySubscription = () => {
        if (newsSubscriptionError) {
            showToast("removed", "something went wrong try after sometime")
        } else if (newsSubscriptionInfo) {
            showToast("info", "Congrats! You Subscribed Successfully")
        }
    }

    useEffect(() => {
        if(notifyTrigger){
            notifySubscription()
        }
    }, [newsSubscriptionError, newsSubscriptionInfo])

    return notifySubscription;
}


// Function to Subscribe newsletter and Blog Post
const useSubscription = () => {
    const [email, setEmail] = useState("");
    const [notifyTrigger, setNotifyTrigger] = useState(false);
    const dispatch = useDispatch();
    const notify = NotifySubscription(notifyTrigger);

    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const location = useLocation();
    const type = location.pathname === "/blog-articles" ? "Blog" : "News";

    const handleSubmit = () => {
        if (email !== "") {
            dispatch(getSubscription(email, type));
            document.getElementById('email').value = ''
            setNotifyTrigger(true);
            notify();
        }
        setEmail("");
    }

    return { handleChange, handleSubmit, email };
}

export default useSubscription;