import { Link, useLocation } from "react-router-dom";
import useProfileData from "../../profile-page/hooks/useProfileData";
import "../navItem/NavItem.css"
import "../navbar/Navbar"

const VarNavItem = (toggleNav,MobileNav) => {
    const { profileData } = useProfileData();
    const location = useLocation();
    const xtoken = localStorage.getItem("xtoken");

  const getComponent = () => {
    if (!xtoken) {
      return (
        <>
          <Link
            to="/login"
            className={
              location.pathname === "/login"
                ? " login-nav-btn nav-item "
                : " login-nav-btn nav-item"
            }
            onClick={toggleNav}
          >
            LogIn
          </Link>
          {/* <Link
            to="/login"
            className={
              location.pathname === "/login"
                ? " login-nav-btn nav-item "
                : " login-nav-btn nav-item"
            }
            onClick={toggleNav}
          >
            SignUp
          </Link> */}
        </>
      );
    } else {
      if (profileData) {
        if (MobileNav) {
          return (
            <>
              <Link
                to="/profile"
                className={
                  location.pathname === "/profile"
                    ? "nav-item "
                    : "nav-item hover-underline-animation "
                }
                onClick={toggleNav}
              >
                Profile
              </Link>
            </>
          );
        }
        return (
          <>
            <Link to="/profile">
              <div
                style={{
                  backgroundImage: `url(${profileData.user_data.profilePicture})`,
                  backgroundPosition: "center",
                  backgroundColor: "white",
                  width: "44px",
                  height: "44px",
                  borderRadius: "30px",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Link>
          </>
        );
      } else {
        return (
          <div className="Loading" style={{ height: "auto" }}>
            <img
              src="assets/images/Icon_Animation.gif"
              alt="logo"
              style={{ width: "40px", height: "40px" }}
            ></img>
          </div>
        );
      }
    }
  };

  return { getComponent };
};

export default VarNavItem;
