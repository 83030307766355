import "./WhislistPage.css";
import { Link} from "react-router-dom";
import SinglePlace from "../single-place-page/views/SinglePlace";

import Copyright from "../../shared/components/copyright/Copyright";
import WishlistContainer from "./views/wishlist-container/WishlistContainer";
import { WishlistPageHandler } from "./utils/WishlistPageHandler";


const WhislistPage = () => {
  const { sWishlistItem, pWishlistItem, placedata, loading  } =
    WishlistPageHandler();

  if (placedata&&placedata.status) {
    document.body.classList.add("scroll-lock");
  } else {
    document.body.classList.remove("scroll-lock");
  }

  if ((!placedata ) || loading) {
    return (
      <>
        <div className="Loading">
          <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="whilist-wrapper">
          <div className="whislist-top">
            <p id="wTp1">My Wishlist</p>
            <p id="wTp2">We’ve kept your favourites very safely.</p>
            <Link to={{ pathname: "/search" }} className="addMore-btn">
              {" "}
              Add More
            </Link>
          </div>

          <div className="whislist-body">
            {/* Single Places */}
            <WishlistContainer
              WishlistItem={sWishlistItem}
              placeType={"Single Places"}
            />

            {/* Travlpoint packages */}
            <WishlistContainer
              WishlistItem={pWishlistItem}
              placeType={"TravlPoint Package"}
            />
          </div>
        </div>
        <Copyright />

        {placedata&&placedata.status  && (
            <div className="search-popup">
              <SinglePlace />
            </div>
          )}
      </>
    );
  }
};

export default WhislistPage;
