import { combineReducers } from "redux";
import ui from "./ui";
import homeData from './homeData'
import subscription from "./subscription";
import loginToken from "../../../features/login/application/reducers";
import refreshToken from "./refreshToken";
import validateData from "../../../features/signup/applications/reducers/validateData";
import signupToken from '../../../features/signup/applications/reducers'
import wishlist from "../../../features/wishlist/applications/reducers";
import cartData from "../../../features/cart-page/applications/reducers/getCartData";
import  recomendedCartData from "../../../features/cart-page/applications/reducers/getRecomendedCartData";
import filterData from "../../../features/search-page/application/reducers/getSearchFilterData";
import toastData from "../../../features/Toast/applications/reducers";
import allBlogData from "../../../features/blog-page/applications/reducers/getBlogData";
import blogDetails from "../../../features/blog-page/applications/reducers/getBlogDetails";
import blogLikeUnlike from "../../../features/blog-page/applications/reducers/getlikeUnlike";
import searchedBlogs from "../../../features/blog-page/applications/reducers/getSearchBlogsData"
import singlePlaceData from "../../../features/single-place-page/applications/reducers/singlePlaceData";
import TPID from "./TPID";
import trendingPackages from "../../../features/package-page/applications/reducers/getTrendingPackage";
import  newPlacePackages from "../../../features/package-page/applications/reducers/getNewPlacesPackage";
import packageById from "../../../features/package-page/applications/reducers/getPackageById";
import packageReview from "../../../features/package-page/applications/reducers/getPackageReviewById";
import  placeToVisit from "../../../features/package-page/applications/reducers/getPlacesToVisitById";
import  singleWishlistItems from "../../../features/whishlist-page/applications/reducers/singleWishlistItems"
import packageWishlistItems from "../../../features/whishlist-page/applications/reducers/packageWishlistItems"

const rootReducers = combineReducers({
    ui,
    homeData,
    subscription,
    loginToken,
    refreshToken,
    validateData,
    signupToken,
    wishlist,
    cartData,
    recomendedCartData,
    filterData,
    allBlogData,
    blogDetails,
    blogLikeUnlike,
    searchedBlogs,
    toastData,
    singlePlaceData,
    TPID,
    trendingPackages,
    newPlacePackages,
    packageById,
    packageReview,
    placeToVisit,
    singleWishlistItems,
    packageWishlistItems,

})

export default rootReducers;