import PersonIcon from '@mui/icons-material/Person';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HubIcon from '@mui/icons-material/Hub';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const SignUpInputField =(register,setRegister)=>{
    const SignupInputFieldData = [
        {
            inputLabel:"firstName",
            inputType: "text",
            inputPlaceholder: "First Name",
            inputIcon: < PersonIcon />,
            value:register.firstName,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, firstName: newValue })),
        },
        {
            inputLabel:"lastName",
            inputType: "text",
            inputPlaceholder: "Last Name",
            inputIcon: < PersonIcon />,
            value: register.lastName,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, lastName: newValue })),
        },
        {
            inputLabel:"email",
            inputType: "text",
            inputPlaceholder: "Email",
            inputIcon: < MarkEmailUnreadRoundedIcon />,
            value: register.email,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, email: newValue })),
        },
        {
            inputLabel:"mobileNumber",
            inputType: "text",
            inputPlaceholder: "Mobile Number",
            inputIcon: < LocalPhoneIcon />,
            value: register.mobileNumber,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, mobileNumber: newValue })),
        },
        {
            inputLabel:"referralCode",
            inputType: "text",
            inputPlaceholder: "Referral Code (Optional)",
            inputIcon: < HubIcon />,
            value: register.referralCode,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, referralCode: newValue })),
        },
        {
            inputLabel:"password",
            inputType: "password",
            inputPlaceholder: "Password",
            inputIcon: <LockIcon />,
            lockOpenIcon: <LockOpenIcon />,
            visibilityIcon: <VisibilityIcon />,
            visibilityOffIcon: <VisibilityOffIcon />,
            value: register.password,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, password: newValue })),
        },
        {
            inputLabel:"confirmPassword",
            inputType: "password",
            inputPlaceholder: "Confirm Password",
            inputIcon: <LockIcon />,
            lockOpenIcon: <LockOpenIcon />,
            visibilityIcon: <VisibilityIcon />,
            visibilityOffIcon: <VisibilityOffIcon />,
            value: register.confirmPassword,
            setInput:(newValue) => setRegister((prevRegister) => ({ ...prevRegister, confirmPassword: newValue })),
        }
    ]

    return {SignupInputFieldData}
}
 