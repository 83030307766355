import { SUBSCRIPTION_FAILURE, SUBSCRIPTION_SUCCESS } from "../actions/getSubscription"

const initialState = {
    result:"",
    error :null
}

const subscriptionReducer = (state=initialState,action)=>{
    switch(action.type){
        case SUBSCRIPTION_SUCCESS:
            return{
                ...state,
                result:action.payload,
                error:null
            }
        case SUBSCRIPTION_FAILURE:
            return{
                ...state,
                result:action.payload,
                error:true
            }

        default:
            return state;
    }
}

export default subscriptionReducer;