import { useDispatch } from 'react-redux'
import { singlePlaceDataLoading, singlePlaceDataRemoving } from '../../features/single-place-page/applications/actions/getSinglePlaceData';

const usePopup = () => {
  const dispatch = useDispatch();

    const getPopUpData = (Sl) =>{
        dispatch(singlePlaceDataLoading(Sl));
    }

    const popUpHandler = () => {
      // dispatch function to remove data
      dispatch(singlePlaceDataRemoving)
    }
  return {getPopUpData,popUpHandler};
}

export default usePopup;