import React from 'react'
import './SubHeading.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SubHeading = ({subHeading,handleFilter}) => {

  return (
    <div className="sf-sub-header">
            <div className="sf-sub-heading">{subHeading} <span><InfoOutlinedIcon className='filter-info-icon'/></span></div>
            <div className="search-clear-all" onClick={handleFilter}>Clear All</div>
    </div>
  )
}

export default SubHeading;