import './Banner.css'
import RedBtn from "../../components/redbtn/RedBtn";
import usePopup from '../../../../shared/hooks/usePopup';
import usePackageDetails from '../../../../shared/hooks/usePackageDetails';

const SimpleSlider = (props) => {
    let { id, BannerType, img, head_1, head_2, sub_head} = props;
    const { getPopUpData} = usePopup();
    const {packageDetails} = usePackageDetails();

    return (
        <>
            <div
                className="slide"
                key={id}
                style={{
                    backgroundImage: `url(${img})`
                }}
            >
                <div className="left-gradient">
                    <div className="main-banner-content">
                        <div className="banner-content">
                            {/* <h3>{title}</h3>
                            <div className="line-1"></div> */}
                            <h2>{head_1}</h2>
                            <h1>{head_2}.</h1>
                            <p className="sub-head">{sub_head}</p>
                            <RedBtn
                                id={id}
                                title = {head_2}
                                btnName={BannerType === "S" ? "More Info" : (BannerType === "B") ? "Read More" : "Let’s Plan Trip"}
                                getPopUpData={getPopUpData}
                                bannerType={BannerType}
                                PackageDetails={packageDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default SimpleSlider;
