import { useState } from 'react';
import useUpdateProfileApi from './useUpdateProfileApi'
import { UpdateProfileValidationForm } from '../../../shared/utils/validationform';

const useUpdateProfile = (isValid,profileData,setLoading,setUpdate,
    setUpdatedetail) => {
    // const {profileData,setLoading} =useProfileData();
    const [PDetails, setPDetails] = useState({
        FirstName:profileData.firstName,
        LastName: profileData.lastName,
        Email:profileData.email,
        Phone:`${profileData.phone}`,
        dob:profileData["dateOfBirth"]
    })
    
    const [PDetailsError, setPDetailsError] = useState({
        FirstName:"",
        LastName: "",
        Email:"",
        Phone:"",
        dob:""
    })

    const handleInput = (e) => {
        let name = e.target.name;
        const value = e.target.value;
        setPDetails({ ...PDetails, [name]: value })
    }
    
    const {UpdateData} = useUpdateProfileApi(isValid,PDetails,profileData,setUpdate,
        setUpdatedetail);

    const handleDetail = async()=>{
        const UpdateProfileErrors = await UpdateProfileValidationForm(PDetails);
        if (Object.keys(UpdateProfileErrors).length === 0) {
            // submit form
               setLoading(true)
               await UpdateData();
           } else {
             setPDetailsError(UpdateProfileErrors);
           }
    }

  return {PDetails,handleDetail,PDetailsError,handleInput}
}

export default useUpdateProfile