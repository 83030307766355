import React from 'react';
import './RatingStar.css'
import StarIcon from '@mui/icons-material/Star';

const RatingStar = ({rating}) => {
    return (
        <div className="rating ">
            <StarIcon className='starIcon' />
            <span className='rate'>{rating}</span>
        </div>
    )
}

export default RatingStar;