import React from "react";
import "./AboutReview.css";
import { reviewData } from "../../assets/AboutPageReviewData";

function AboutReview() {
  return (
    <div className="AboutReviewWrapper" id="review">
      <p className="AboutReviewHeading">WE HAVE SPREAD SOME HAPPINESS</p>
      <p className="AboutReviewDesc">We have manage to bring smiles on some of faces</p>
      <div className="AboutReviews">
        {reviewData.map((item, i) => (
          <div className={`AboutReviewCard AboutReviewCard-${i + 1}`} key={i}>
            <div className={`AboutReviewDP AboutReviewDP-${i + 1}`} style={{ background: `linear-gradient(0deg, rgba(65, 64, 64, ${item["Opacity"]}), rgba(65, 64, 64, ${item["Opacity"]})), url(${item["ProfilePicture"]}) center / cover no-repeat` }}>
              <div className={`AboutReviewFeedback AboutReviewFeedback-${i + 1}`} style={{
                "display": item["viewFeedback"] === "0" ? "unset" : "none"
              }}>{item["Feedback"]}
                <div className="AboutReviewName">~ {item["Name"]}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutReview;
