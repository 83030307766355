export const PACKAGE_WISHLIST_ITEMS_LOADED = '[package wishlist Items ] loaded'
export const PACKAGE_WISHLIST_ITEMS_SUCCESS = '[package wishlist Items ] success'
export const PACKAGE_WISHLIST_ITEMS_FAILURE = '[package wishlist Items ] failure'

export const packageWishlistItemsLoaded = {
    type:PACKAGE_WISHLIST_ITEMS_LOADED
}

export const packageWishlistItemsSuccess = (data) =>({
    type:PACKAGE_WISHLIST_ITEMS_SUCCESS,
    payload:data
})

export const packageWishlistItemsFailure = (error) =>({
    type:PACKAGE_WISHLIST_ITEMS_FAILURE,
    payload:error
})
