export const TOAST_LOADED = '[toast] toast loaded';
export const TOAST_SUCCESS = '[toast] toast success';
export const TOAST_FAILURES = '[toast] toast failure';

export const toastLoaded = (id,type , description) => ({
    type: TOAST_LOADED,
    payload:{id,type , description}
})

export const toastSuccess = (data) => ({
    type: TOAST_SUCCESS,
    payload: data
})

export const toastFailure = (error) => ({
    type:TOAST_FAILURES,
    payload:error,
})

