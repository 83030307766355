import { Link, useLocation } from "react-router-dom";
import "./NavItem.css";

export const NavItem = ({ item, showToast }) => {
  const location = useLocation();
  const handleNotification = () => {
    if (item.path === "") {
      showToast("info", "Please Login");
    }
  };

  return (
    <div className="animated-link" >
      <Link
        to={item.path}
        className={
          location.pathname === item.path
            ? "nav-item"
            : "nav-item hover-underline-animation "
        }
        onClick={handleNotification}
      >
        {item.navName} {item.icon && item.icon}
      </Link>
      <div
        className={
          location.pathname === item.path
            ? "nav-item-active"
            : " hover-underline-animation"
        }
      ></div>
    </div>
  );
};

export const NavItemMob = ({ item, toggleNav,showToast }) => {
  const location = useLocation();

  const handleNotification = () => {
    if (item.path === "") {
      showToast("info", "Please Login");
    }
    toggleNav()
  };
  return (
    <Link
      key={item.id}
      to={item.path}
      className={
        location.pathname === item.path
          ? "nav-item nav-item-active"
          : "nav-item hover-underline-animation "
      }
      onClick={handleNotification}
    >
      {item.navName} {item.icon && item.icon}
    </Link>
  );
};
