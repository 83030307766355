import { trendingPackageUrl,newPackageUrl,packageByIdUrl,placesToVisitByIdUrl } from "../../../assets/urls";

export default {
    trendingPackage:async()=>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }

        const response = await fetch(trendingPackageUrl,options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data)
        }

    },
    newPlacePackage:async()=>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }

        const response = await fetch(newPackageUrl,options)
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data)
        }
    },
    packageById:async(tpId)=>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }
        const response = await fetch(packageByIdUrl(tpId),options).then(res => res.json());
        return response;
    },

    placesToVisitById:async(tpId)=>{
        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }
        const response = await fetch(placesToVisitByIdUrl(tpId),options).then(res => res.json());
        return response;
    },

}