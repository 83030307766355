export const HOME_DATA= '[home] home data loaded';
export const HOME_DATA_SUCCESS = '[home] home data success';
export const HOME_DATA_FAILURE = '[home] home data failure';

export const homeData = (state)=>({
    type: HOME_DATA,
    payload:state,
});

export const homeDataSuccess =(data)=> ({
    type: HOME_DATA_SUCCESS,
    payload: data
})

export const homeDataFailure = (error)=>({
    type:HOME_DATA_FAILURE,
    payload:error
})