import React from "react";

import "../TripPage.css";

const SchedulePlaceCard = (props) => {
  const background = {
    background: `url(${props.data["Cover Picture"]})no-repeat`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  return (
    <>
      <div className="Schedule-Place">
        <div className="Place-card-time">
          <h2>{props.data["Visiting Time"].slice(0, 5)}</h2>
        </div>
        <div className="Place-connector">
          <div className="Line-connector"></div>
          <div className="Dot-connector">
            <div className="Connector-Inner-Circle"></div>
          </div>
          <div className="Line-connector"></div>
        </div>
        <div className="Schedul-Place-Card">
          <div className="Schedule-Place-Img" style={background}></div>
          <div className="Schedule-Place-Details">
            <h3>
              {props.data["Name"].slice(0, 12)}
              {props.data["Name"].length >= 12 ? "..." : null}
            </h3>
            <p>{props.data["Visiting Time"]}</p>
            <p>
              Visiting Time: <span>{props.data["Time Given"]} hrs</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchedulePlaceCard;
