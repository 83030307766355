import {
  SINGLE_WISHLIST_ITEMS_LOADED,
  singleWishlistItemsFailure,
  singleWishlistItemsSuccess,
} from "../actions/getSingleWishlistItems";

const getSingleWishlistItemsFlow =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === SINGLE_WISHLIST_ITEMS_LOADED) {
      try {
        const response = await api.singleWishlistItemsApi.singleWishlistItems();
        dispatch(singleWishlistItemsSuccess(response));
      } catch (error) {
        dispatch(singleWishlistItemsFailure(error));
      }
    }
  };

const singleWishlistItemsFlow = [getSingleWishlistItemsFlow];

export default singleWishlistItemsFlow;
