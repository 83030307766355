import { useState ,useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../login/application/selectors/getToken";
import { getSignupToken } from "../../signup/applications/selectors/getToken";
import useRefreshToken from "../../../shared/hooks/useRefreshToken";
import { getRefreshToken } from "../../../shared/application/selectors/refreshToken";
import { wishlistLoaded } from "../../wishlist/applications/actions/getWishlist";
import useProfileApi from "./useProfileApi";
import { getWishlistData } from "../../wishlist/applications/selectors/getWishlist";

const useProfileData = () => {
    const [profileData, setProfileData] = useState(null);
    const wishlistData = useSelector(getWishlistData);

    const [Updatedetail, setUpdatedetail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    
    const handleProfileUpdate = () => {
        setUpdate(!update)
        setUpdatedetail(false)
        setLoading(false)
      }

    const loginToken = useSelector(getToken);
    const signUpToken = useSelector(getSignupToken);
    const Token = loginToken && Object.keys(loginToken).length !== 0 ? loginToken : signUpToken
    const refreshToken = useSelector(getRefreshToken);

    const dispatch = useDispatch();
    const { handleRefreshToken } = useRefreshToken()
    const {getProfileData} =useProfileApi();

    const xtokens = JSON.parse(localStorage.getItem('xtoken'));

    useEffect(() => {
        if (Token.data && Token.data.accessToken && !wishlistData) {
            localStorage.setItem('xtoken', JSON.stringify(Token.data))
            dispatch(wishlistLoaded);
        }
        if ((profileData === null) && (Token.data || xtokens)) {
            isValid()
        }
    }, [Token.data,update,refreshToken, Updatedetail])

    useEffect(() => {
        if (xtokens  && !wishlistData){
            dispatch(wishlistLoaded);
        }
    })

    const updateToken = (response1) => {
        if(xtokens && xtokens.refreshToken){
            handleRefreshToken(response1, xtokens.refreshToken);
        }
    }

    async function isValid(){
        try{
            const response1 = await getProfileData();
            // console.log("profile.js",response1.status)
            if (response1&&response1.status){
                setProfileData(response1&&response1.data)
            }
            else{
                updateToken(response1)
            }
        } catch(error){
            console.log(error.message)
        }
    }



    const memoizedProfileData = useMemo(() => profileData, [profileData]);

    return {Updatedetail,setUpdatedetail, loading,setLoading, update,setUpdate,handleProfileUpdate,profileData: memoizedProfileData , isValid}
}

export default useProfileData;