import { useEffect, useState } from "react";
import { cartUrl, removeCartUrl } from "../../../assets/urls";

const useIconHandler = (props) => {
    const [added, setAdded] = useState(props.added);

    useEffect(() => {
        let isMounted = true; 
    
        if (isMounted) {
            setAdded(props.added);
        }
    
        return () => {
            isMounted = false;
        };
    }, [props.added]);

    const iconHandler = async (sl) => {
        if (localStorage.getItem("xtoken")) {
            let num = props.selectedPlaces.length;
            // let url = added ? cartUrl : num === 1 ? cartUrl : removeCartUrl
            let url = added ? cartUrl : removeCartUrl

            const options = {
                method: added ? `PUT` : num === 1 ? `DELETE` : `PUT`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: JSON.parse(localStorage.getItem("xtoken")).accessToken,
                },
                body: added
                    ? JSON.stringify({
                        // tpid: props.cart["TPID"],
                        tpid: props.tpid,
                        sl: sl,
                    })
                    : JSON.stringify({
                        sl: sl,
                    }),
            };

            try{
                let response = await fetch(url, options);
                const data = await response.json();
                if(response.ok){
                    if((url === removeCartUrl) && (num === 1)){
                        props.deleteCartData();
                    }
                    setAdded(!added);
                }else{
                    throw new Error(data.Msg)
                }
            }catch(err){
                console.log("Something Went Wrong")
            }

        } else {
            let tempArr = JSON.parse(localStorage.getItem("Selected-Places"));

            if (tempArr.indexOf(sl) !== -1) {
                if (tempArr.length === 1) {
                    localStorage.removeItem("Selected-Places");
                    localStorage.removeItem("TPID");
                    props.deleteCartData();
                } else {
                    tempArr.splice(tempArr.indexOf(sl), 1);
                    localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
                }
            } else {
                tempArr.push(sl);
                localStorage.setItem("Selected-Places", JSON.stringify(tempArr));
            }
        }
        props.updateCopySelectedPlaces(sl);
    };

    return { added, iconHandler }
}

export default useIconHandler