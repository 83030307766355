import './UpdateProfile.css'
import useUpdateProfile from '../../hooks/useUpdateProfile';

const UpdateProfile = (props) => {
    const {profileData,setLoading,setUpdate,setUpdatedetail,isValid} =props;
    const {PDetails,handleDetail,PDetailsError,handleInput} = useUpdateProfile(isValid, profileData,setLoading,setUpdate,setUpdatedetail);

  return (
    <div className="UpdateProfile-wrapper">
        <div className="updateP-page">
            <div className="updateP-heading">
                <p>Update Details</p>
            </div>
            <div className="updateP-body">
                <div className="updateP-name">
                    <div className="updateP-fname">
                        <p>First Name</p>
                        <input type="text" className='UP-input' name='FirstName' value={PDetails.FirstName} onChange={handleInput}/>
                        {PDetailsError.FirstName && <span className='Security-error'>{PDetailsError.FirstName}</span>}
                    </div>
                    <div className="updateP-lname">
                        <p>Last Name</p>
                        <input type="text" className='UP-input' name='LastName' value={PDetails.LastName} onChange={handleInput} />
                        {PDetailsError.LastName && <span className='Security-error'>{PDetailsError.LastName}</span>}
                    </div>
                </div>
                <div className="Up-email">
                    <p>Email Address</p>
                    <input type="text" className='UP-input' name='Email' value={PDetails.Email} onChange={handleInput} />
                    {PDetailsError.Email && <span className='Security-error'>{PDetailsError.Email}</span>}
                </div>
                <div className="Up-PhoneNo">
                    <p>Phone Number</p>
                    <input type="text" className='UP-input' name='Phone' value={PDetails.Phone} onChange={handleInput}/>
                    {PDetailsError.Phone && <span className='Security-error'>{PDetailsError.Phone}</span>}
                </div>
                <div className="Up-Dob">
                    <p>Date Of Birth</p>
                    <input type="date" className='UP-input' name='dob' value={PDetails.dob}  onChange={handleInput}/>
                    {PDetailsError.dob && <span className='Security-error'>{PDetailsError.dob}</span>}
                </div>
                {/* <div className="Up-Dob">
                    <p>Date Of Birth</p>
                    <input type="text" className='UP-input' name='Gender' value={PDetails.Gender}  onChange={handleInput}/>
                </div> */}
            </div>
            <div className="updateP-btn" onClick={handleDetail}>
                <p>Update</p>
            </div>
        </div>
    </div>
  )
}

export default UpdateProfile;
