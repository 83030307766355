import React from 'react';
import './Footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Copyright from '../copyright/Copyright';
import { Link } from 'react-router-dom';
import { getAppUrl } from '../../../assets/urls';
import { supportData,quickLinkData,travlPointData } from '../../../assets/data/FooterData';

const Footer = () => {
    const handleDaownloadApp = ()=>{
        window.location.href = getAppUrl;
    }
    
    return (
        <div className="footer-wrapper">
            <div className="footer align-footer-item">
                <div className="footer-body align-footer-item">
                    <div className="footer-sec">
                        <div className="footer-title">Support</div>
                        <div className="footer-line"></div>
                        {
                            supportData.map((data,id) => (
                                <Link to={data.path} className='f-link' key={id}><div className="footer-item" >{data.title}</div></Link>
                            ))
                        }
                    </div>

                    <div className="footer-sec">
                        <div className="footer-title">Quick Links</div>
                        <div className="footer-line"></div>
                        {
                            quickLinkData.map((data,id) => (
                                <Link to={data.path} className='f-link' key={id}> <div className="footer-item" >{data.title}</div></Link>
                            ))
                        }
                    </div>

                    <div className="footer-sec">
                        <div className="footer-title">TravlPoint</div>
                        <div className="footer-line"></div>
                        {
                            travlPointData.map((data,id) => (
                                <Link to={data.path} className='f-link' key={id}> 
                                {
                                    data.title === "Download App"?
                                  <div className="footer-item" onClick={handleDaownloadApp}>{data.title}</div>
                                    :
                                    <div className="footer-item" >{data.title}</div>
                                }
                                </Link>
                            ))
                        }
                        <div className="footer-social-media">
                        <a href="https://instagram.com/travlpoint"> <InstagramIcon className="icon"/> </a>
                        <a href="https://twitter.com/travlpoint_IN"> <img src="assets/icons/twitter.svg" alt="" className='icon'/></a>
                        <a  href="https://www.facebook.com/profile.php?id=100066492614941"> <FacebookIcon  className="icon"/></a>
                        <a href="https://www.linkedin.com/company/travlpoint/"><LinkedInIcon className="icon"/> </a>
                        </div>
                    </div>
                </div>
            </div>
                <Copyright/>
        </div>
    )
}

export default Footer