import './SearchBar.css'
import SearchIcon from "@mui/icons-material/Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useSearchKey from '../../hooks/useSearchKey';
import { useDispatch } from 'react-redux';
import { filterDataLoaded } from '../../../features/search-page/application/actions/getSearchFilterData';
import usePagination from '../../../features/search-page/hooks/usePagination';
import useEnterKey from '../../hooks/useEnterKey';

const SearchBar = () => {
    const {searchKey,SearchHandler} = useSearchKey();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {currentPage } = usePagination();

    const handleSearchData = ()=>{
        const query = localStorage.getItem("SearchKey")

        dispatch(filterDataLoaded({ query,pageNo:currentPage,state:"",category:[],entryType:[],rating:
        '',searchType:[]}))

        if(location.pathname==="/"){
            navigate("search")
        } 
       
    }

    const handleKeyDown = useEnterKey(handleSearchData);

    return (
        <div className="search-card-wrapper">
            <div className="search-card">
            <div className="main-search-card-content">
                <input type="text" placeholder="Where to go next?" value={searchKey} 
                onChange={SearchHandler} onKeyDown={handleKeyDown}/>
                <Link to={location.pathname==="/"?"search":""} onClick={handleSearchData} >
                    <div className="search-btn">
                        <SearchIcon  className='search-icon'/>
                    </div>
                </Link>
            </div>
            </div>
        </div>
    )
}

export default SearchBar;