import BagPacks from '../Images/backpacks.svg';
import StreetSign from '../Images/street-sign.svg'
import StreetMap from '../Images/map.svg'

export const CarouselData =[
    {
        img:BagPacks ,
        text: 'Pack your Bags and \nGet Ready!'

    },
    {
        img: StreetSign,
        text: 'Confusion less \nTravel Planning'
    },
    {
        img: StreetMap,
        text: 'Leave hassles on us \nJust Enjoy your trip'
    }
]