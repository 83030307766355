import React from "react";
import "./Security.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useSecurity from "../../hooks/useSecurity";

const Security = (props) => {
  const {
    securityError,
    Security,
    handleInput,
    UpdatePassword,
    passwordError,
  } = useSecurity(props);
  return (
    <div className="SecurityPage">
      <div className="Security-Password">
        <p>Old Password</p>
        <input
          type="password"
          className="S-P-input"
          name="OldPassword"
          value={Security.OldPassword}
          onChange={handleInput}
        />
        {securityError.OldPassword && (
          <span className="Security-error">{securityError.OldPassword}</span>
        )}
      </div>
      <div className="Security-Password">
        <p>New Password</p>
        <input
          type="text"
          className="S-P-input"
          name="NewPassword"
          value={Security.NewPassword}
          onChange={handleInput}
        />
        {securityError.NewPassword && (
          <span className="Security-error">{securityError.NewPassword}</span>
        )}
      </div>
      <div className="Security-Password">
        <p>Confirm Password</p>
        <input
          type="password"
          className="S-P-input"
          name="ConfirmPassword"
          value={Security.ConfirmPassword}
          onChange={handleInput}
        />
        {securityError.ConfirmPassword && (
          <span className="Security-error">
            {securityError.ConfirmPassword}
          </span>
        )}
      </div>
      <div className="Security-Password-btn">
        <button
          id="Updt-btn"
          onClick={UpdatePassword}
          style={{ marginTop: "22px" }}
        >
          Update
        </button>
      </div>
      {passwordError && <span className="Security-error">{passwordError}</span>}
      {/* <div className="Security-Password">
        <p>Security Question</p>
        <div className="Security-input">
          <select className="sec-qn">
            <option value="volvo">Select One Question</option>
            <option value="volvo">Name of your Primary School</option>
            <option value="volvo">Name of your Favourite Pet</option>
            <option value="volvo">Name of your Place</option>
            <option value="volvo">Name of your Favourite Freind</option>
            <option value="volvo">Name of your Favorite Game</option>
          </select>
          <div className="dropdown-arrow">
            <KeyboardArrowDownIcon sx={{ color: "#fff", fontSize: "35px" }} />
          </div>
        </div>
        <input type="text" id="S-Q-input" />
        <div className="Security-Password-btn">
          <button id="Updt-btn">Update</button>
        </div>
      </div> */}
    </div>
  );
};
export default Security;
