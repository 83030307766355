import { validateSignupDataUrl } from "../../../assets/urls";

export const  SignupValidationStep1= async(register)=>{
    const errors = {}
    const emailRegex = /^\S+@\S+\.\S+$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    // const dispatch = useDispatch();
    const checkEPR = async (Email, Phone, referralCode) => {
        let result = await fetch(validateSignupDataUrl(Email, Phone, referralCode))
        const data = await result.json();

        try{
            if(result.ok){
                return data;
            }else{
                errors.url =data; 
                return data;
            }
        }catch(err){
            console.log(err)
        }
    }


    if (!register.firstName){
        errors.firstName = 'Please enter your first name';
    }
  
    if (!register.lastName){
        errors.lastName = 'Please enter your last name';
    }
  
    if (!register.email){
        errors.email = 'Please enter your email address';
    } else if (!emailRegex.test(register.email)) {
        errors.email = 'Please enter a valid email address';
    }

    if (!register.mobileNumber){
        errors.mobileNumber = 'Please enter a password';
    } else if (register.mobileNumber.length !== 10) {
        errors.mobileNumber = 'Mobile Number must be 10 digit ';
    }

    else if(!register.password) {
        errors.password = 'Please enter a password';
    }else if(!passwordRegex.test(register.password)){
        errors.password = `Password atleast be 8 characters long and include 
        at least 1 uppercase,
        1 lowercase, 
        1 number,  and 
        1 symbol.`;
    }
  
    if(!register.confirmPassword){
        errors.confirmPassword = 'Please confirm your password';
    }else if(register.password !== register.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }

    // const res = useSelector(getValidation);

    if((register.email && register.password) || register.referralCode){
        if ( register.referralCode === "") {
            register.referralCode = '0';
        }
        
        const res = await checkEPR(register.email, register.mobileNumber, register.referralCode);

        if(res.Email){
            errors.email="Email is already taken"
        }

        if(res.Phone){
            errors.mobileNumber="Mobile number is already taken"
        }
        
        if(!register.check){
            errors.check = "Please Accept Terms and Condition"
        }

    }
      return errors;
  
}

export const SignupValidationStep2= async(dob,gender)=>{
    const errors = {};

    if(!dob){
        errors.dob ="Please enter DOB"
    }

    if(!gender){
        errors.Gender ='Please select gender'
    }

    return errors;
}