//middleware
import { setLoading } from "../../../../shared/application/actions/ui";
import { PACKAGE_BY_ID_LOADING, packageByIdFailure, packageByIdSuccess } from "../actions/getPackageById";

const packageByIdFlow = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    next(action);

    if (action.type === PACKAGE_BY_ID_LOADING) {
        try {
            dispatch(setLoading(true));
            const response = await api.packageApis.packageById(action.payload);
            dispatch(packageByIdSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(packageByIdFailure(error));
            dispatch(setLoading(false));
        }
    }
};

const packageFlow = [packageByIdFlow];
export default packageFlow;
