import SearchIcon from "@mui/icons-material/Search";
import "./SearchInput.css";

const SearchInput = ({ placeHolder, handleKeyDown, value, onChange }) => {
  return (
    <div className="blog-input-wrapper">
      <SearchIcon className="blog-search-icon" />
      <input
        type="text"
        placeholder={placeHolder}
        className="search-input"
        onKeyDown={handleKeyDown}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
