import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNoOfResult } from '../application/selectors/getSearchData';
import { filterDataLoaded } from '../application/actions/getSearchFilterData';
import useRatingFilter from './useRatingFilter';
import useCategoryFilter from './useCategoryFilter';
import useCheckBoxFilter from './useCheckBoxFilter';
import { ScreenWidthUpdater } from '../../../shared/utils/screenWidthUpdater';

const usePagination = () => {
    const { entryTypes, searchTypes } = useCheckBoxFilter();
    const { selectedCategories } = useCategoryFilter();
    const { rating } = useRatingFilter();

    const noOfPages = useSelector(getNoOfResult);
    const totalPages = noOfPages ? Math.ceil(noOfPages / 10) : 10;
    const [currentPage, setCurrentPage] = useState(1);

    const {screenWidth} = ScreenWidthUpdater()

    const dispatch = useDispatch();
    const visiblePageNumbers = () =>{
        const pageNumbers = [];
        const middleVisibleCount = 1; // Number of visible pages around the current page in the middle
        const sideVisibleCount = screenWidth<600?2:4;   // Number of visible pages at the beginning and end

        if (totalPages <= middleVisibleCount * 2 + sideVisibleCount * 2 + 1) {
            // Show all pages if there are not enough pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Show the first sideVisibleCount pages
            for (let i = 1; i <= sideVisibleCount; i++) {
                pageNumbers.push(i);
            }

            // Show dots if there are more pages between the first visible pages and the middle
            if (currentPage > sideVisibleCount + 1 + middleVisibleCount) {
                pageNumbers.push('...');
            }

            // Show pages around the current page
            for (let i = Math.max(currentPage - middleVisibleCount, sideVisibleCount + 1); i <= Math.min(currentPage + middleVisibleCount, totalPages - sideVisibleCount); i++) {
                pageNumbers.push(i);
            }

            // Show dots if there are more pages between the middle and the last visible pages
            if (currentPage + middleVisibleCount < totalPages - sideVisibleCount) {
                pageNumbers.push('...');
            }

            // Show the last sideVisibleCount pages
            for (let i = totalPages - sideVisibleCount + 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        }
        return pageNumbers;
    };

    const handleDispatch = (page) => {
        const query = localStorage.getItem("SearchKey");
        const ratings = rating.min;

        // console.log("usePagination", { query, pageNo: page, state: "", category: selectedCategories, entryType: entryTypes, rating: ratings, searchType: searchTypes })

        dispatch(filterDataLoaded({ query, pageNo: page, state: "", category: selectedCategories, entryType: entryTypes, rating: ratings, searchType: searchTypes }))
    }

    const handlePageChange = (page) => {
            setCurrentPage(page);
            handleDispatch(page)
    };
    const handlePrevPage =(currentPage)=>{
        if(currentPage >= 1){
            console.log("prev")
            handlePageChange(currentPage)
        }
    }
    
    const handleNextPage =(currentPage)=>{
        if(currentPage <= totalPages){
            handlePageChange(currentPage)

        }
    }

    return { visiblePageNumbers, handlePrevPage,handlePageChange,handleNextPage, currentPage }
}

export default usePagination;