import { REFRESH_TOKEN_FAILURE, REFRESH_TOKEN_SUCCESS } from "../actions/getRefreshToken"

const initialState={
    refreshToken:{},
    error:null
}

const refreshToken =(state=initialState, action)=>{
    switch(action.type){
        case REFRESH_TOKEN_SUCCESS:
            return{
                ...state,
                refreshToken:action.payload,
                error:null
            }

        case REFRESH_TOKEN_FAILURE:
            return{
                ...state,
                refreshToken:action.payload,
                error:true
            }
        
        default:
            return state
    }
}

export default refreshToken;