import React from 'react';
import './PlaceAddressRow.css'
import RatingStar from '../../../../shared/components/rating-star/RatingStar';
import RoomSharpIcon from '@mui/icons-material/RoomSharp';
import { SetLength } from '../../../../shared/hooks/useSetLength';

const PlaceAddressRow = ({ address, district, state, type,rating }) => {
    return (
        <div className="place-address-row">
            <div className="place-address">
                <RoomSharpIcon className='place-location' />
                {
                    type === "single" ? <span>{SetLength(district,15)},{SetLength(state,5)}</span> :
                        <span>{SetLength(address,20)}</span>
                }
            </div>
            <RatingStar rating={rating} />
        </div>
    )
}

export default PlaceAddressRow;