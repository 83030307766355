import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blogDataLoaded } from '../applications/actions/getBlogsData';
import { getBlogData } from '../applications/selectors/getBlogData';

export const useFetchData = () => {
    const dispatch = useDispatch();
    const AllBlogs = useSelector(getBlogData);

    const dispatchBlogDataLoaded = useCallback(() => {
        dispatch(blogDataLoaded);
    }, [dispatch])


    useEffect(() => {
        if (AllBlogs.length===0) {
            dispatchBlogDataLoaded();
        }
    }, [dispatch,AllBlogs.length]);

    return {};
}
