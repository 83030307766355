import React from 'react'

const PlaceTiming = ({type,openingTime,closingTime,totalPlaces}) => {

    return (
        <div className='place-timing'>
            {
                type === "single" ? <img src="assets/icons/clock.svg" alt="" className='single-place-clock' /> :
                    <img src="assets/icons/locations.svg" alt="" className='package-place-clock' />
            }
           {
            type === "single" ?
            <div className="place-time">{openingTime && (openingTime.slice(0,5))} - {closingTime && (closingTime.slice(0,5))}</div>
            :
            <div className="place-time">{totalPlaces}  Places to Visit</div>
           }
        </div >
    )
}

export default PlaceTiming;