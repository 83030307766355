import { refreshTokenUrl} from "../../../../assets/urls"

export default {
    refreshToken: async (rToken) =>{
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "refreshToken":rToken
            }),
        }
        
        const response  = await fetch(refreshTokenUrl,options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data);
        }
    }
}
