export const SIGNUP_LOADED = '[signup] signup loaded';
export const SIGNUP_SUCCESS = '[signup] signup success';
export const SIGNUP_FAILURE = '[signup] signup failure';

export const signupLoaded = (register,dob,gender,OS,signUpAddress) => ({    
    type:SIGNUP_LOADED,
    payload: {register,dob,gender,OS,signUpAddress},
})

export const signupSuccess = (data) =>({
    type:SIGNUP_SUCCESS,
    payload:data
})

export const signupFailure = (error) =>({
    type:SIGNUP_FAILURE,
    payload:error
})
