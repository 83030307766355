import React from 'react';
import './CategoryCard.css'
import { Link } from 'react-router-dom';
import './CategoryCard.css'

const CategoryCard = ({ type,title, image,path,handleCategory }) => {
  return (
    <>
      <Link to={path}>
        <div className="category-card"
          style={{
            background: `url(${image}) no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onClick={()=>handleCategory(type)}
        >
          <div className="category-title"> <span>{title}</span></div>
        </div>
      </Link>
    </>
  )
}

export default CategoryCard;