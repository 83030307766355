import { useState } from 'react'

const useCategoryFilter = () => {
    ///////////////////////// Categories Filteration function ///////////////////////////////////
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [notSelectedCategories, setNotSelectedCategories] = useState([
        "Holy",
        "Date",
        "Family",
        "Vacation",
        "Devotional",
        "Bachelors",
        "Science",
        "Hill Station",
        "Amusement",
        "Mythology",
        "Monument",
        "British",
        "Honey Moon",
    ]);

    const addCategory = (category) => {
        const updatedNotSelected = notSelectedCategories.filter((cat) => cat !== category);
        setNotSelectedCategories(updatedNotSelected);
        setSelectedCategories([...selectedCategories, category]);
    };

    const removeCategory = (category) => {
        const updatedSelected = selectedCategories.filter((cat) => cat !== category);
        setSelectedCategories(updatedSelected);
        setNotSelectedCategories([...notSelectedCategories, category]);
    };

    const [show, setShow] = useState(false);
    const handleClick = (e) => {
        setShow(!show)
    }

    const handleCategoryClear = ()=>{
        setSelectedCategories([])
        setNotSelectedCategories([
            "Holy",
            "Date",
            "Family",
            "Vacations",
            "Devotional",
            "Bachelors",
            "Science",
            "Hill Station",
            "Amusement",
            "Mythology",
            "Monuments",
            "British",
            "Honey Moon",
        ])
    }
    return {selectedCategories,notSelectedCategories,addCategory,removeCategory,show,handleClick,handleCategoryClear}
}

export default useCategoryFilter