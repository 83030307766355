import { PACKAGE_WISHLIST_ITEMS_FAILURE, PACKAGE_WISHLIST_ITEMS_SUCCESS } from "../actions/getPackageWishlistItems"

const initialState ={
    packageWishlistItems:[],
    error:null
}

const packageWishlistReducer = (state=initialState, action)=>{
    switch(action.type){
        case PACKAGE_WISHLIST_ITEMS_SUCCESS:
            return{
                ...state,
                packageWishlistItems:action.payload,
                error:null,
            }

        case PACKAGE_WISHLIST_ITEMS_FAILURE:
            return{
                ...state,
                packageWishlistItems:action.payload,
                error:true
            }
        
        default:
            return state
    }
}

export default packageWishlistReducer;