import { VALIDATION_FAILURE, VALIDATION_SUCCESS } from "../actions/validateData";

const initialState = {
    signupValidation:{},
    error:null
}

const signupValidationReducers =(state=initialState, action)=>{
    switch(action.type){
        case VALIDATION_SUCCESS:
            return{
                ...state,
                signupValidation:action.payload,
                error:null
            }
        
        case VALIDATION_FAILURE:
            return{
                ...state,
                signupValidation:action.payload,
                error:true
            }
        
        default:
            return state;
    }
}

export default signupValidationReducers;