//middleware
import { setLoading } from "../../../../shared/application/actions/ui";
import { PACKAGE_REVIEW_BY_ID_LOADING, packageReviewByIdFailure, packageReviewByIdSuccess } from "../actions/getPackageReviewById";

const packageReviewByIdFlow = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    next(action);

    if (action.type === PACKAGE_REVIEW_BY_ID_LOADING) {
        try {
            dispatch(setLoading(true));
            const response = await api.packageApis.packageReviewById(action.payload);
            dispatch(packageReviewByIdSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(packageReviewByIdFailure(error));
            dispatch(setLoading(false));
        }
    }
};

const packageReviewFlow = [packageReviewByIdFlow];
export default packageReviewFlow ;
