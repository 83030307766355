import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function NextArrow(props) {
    const { onClick } = props;

    return (
        <ArrowForwardIcon
            className={"catePage-arrow catePage-arrow-next"}
            sx={{ color: "white" }}
            onClick={onClick}
        />
    );
}

export default NextArrow;