import homeData from "./homeData";
import ui from "./ui";
import subscription from './subscription'
import getLogin from "../../../features/login/application/middlewares/getLogin";
import refreshToken from "./refreshToken";
import getSignUp from "../../../features/signup/applications/middlewares/getSignUp";
import getWishlist from "../../../features/wishlist/applications/middlewares/getWishlist";
import getCartData from "../../../features/cart-page/applications/middlewares/getCartData";
import getSearchFilterData from "../../../features/search-page/application/middlewares/getSearchFilterData";
import validateData from "../../../features/signup/applications/middlewares/validateData";
import getToast from "../../../features/Toast/applications/middlewares/getToast";
import getBlogsData from "../../../features/blog-page/applications/middlewares/getBlogsData";
import getBlogsLikeUnlike from "../../../features/blog-page/applications/middlewares/getLikeUnlike";
import getSinglePlaceData from '../../../features/single-place-page/applications/middlewares/getSinglePlaceData'
import getTPID from "./getTPID";
import getTrendingPackage from "../../../features/package-page/applications/middlewares/getTrendingPackage";
import getNewPlacesPackage from "../../../features/package-page/applications/middlewares/getNewPlacesPackage";
import getPackageById from "../../../features/package-page/applications/middlewares/getPackageById";
import getPackageReviewById from "../../../features/package-page/applications/middlewares/getPackageReviewById";
import getPlacesToVisitById from "../../../features/package-page/applications/middlewares/getPlacesToVisitById";
import getSingleWishlistItems from "../../../features/whishlist-page/applications/middlewares/getSingleWishlistItems"
import getPackageWishlistItems from "../../../features/whishlist-page/applications/middlewares/getPackageWishlistItems"

export default [
    ...ui,
    ...homeData,
    ...subscription,
    ...getLogin,
    ...refreshToken,
    ...getSignUp,
    ...getWishlist,
    ...getCartData,
    ...getSearchFilterData,
    ...validateData,
    ...getBlogsData,
    ...getBlogsLikeUnlike,
    ...getToast,
    ...getTPID,
    ...getSinglePlaceData,
    ...getTrendingPackage,
    ...getNewPlacesPackage,
    ...getPackageById,
    ...getPackageReviewById,
    ...getPlacesToVisitById,
    ...getSingleWishlistItems,
    ...getPackageWishlistItems
]