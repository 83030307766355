import { FILTER_DATA_FAILURE, FILTER_DATA_SUCCESS } from "../actions/getSearchFilterData";

const initialState = {
    filterData:{},
    error:null
}

const filterDataReducers = (state=initialState, action)=>{
    switch(action.type){
        case FILTER_DATA_SUCCESS:
            return{
                ...state,
                filterData:action.payload,
                error:null
            }
        
        case FILTER_DATA_FAILURE:
            return{
                ...state,
                filterData:action.payload,
                error:true,
            }
        default:
            return state;
    }
}

export default filterDataReducers;