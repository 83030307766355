import { Link } from "react-router-dom";
import "./CategoryPage.css";
import PlaceIcon from "@mui/icons-material/Place";
import Slider from "react-slick";
import { setTime } from "./utils/setTime";
import { setName } from "./utils/setName";
import useCategoryPage from "./hooks/useCategoryPage";
import ErrorHandler from "../../shared/components/error/ErrorHandler";

const CategoryPage = (props) => {
  const {
    catError,
    settings,
    settingsPicture,
    img,
    cardIndex,
    imgIndex,
    openTime,
    placeData,
    categoryData,
    desc,
    planTrip,
    closeTime
  } = useCategoryPage(props);

  if (catError && categoryData.length === 0) {
    return <ErrorHandler />
  }
  if (categoryData.length === 0) {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    );
  }  else {
    return (
      <div
        className="catePage-wrapper"
        style={{
          background: `url(${img[imgIndex]}) center / cover no-repeat`,
        }}
      >
        <div className="catePage-overlay">
          <div className="catePage-header">
            <h1 className="catePage-title">{props.category}</h1>
            <div className="catePage-menu">
              <Link to={{ pathname: "/" }} className="catePage-menu-item">
                Home
              </Link>
              <p className="catePage-menu-item">MyTrip</p>
            </div>
          </div>
          <div className="catePage-body">
            <div className="catePage-text">
              <div className="catePage-Place-Title">
                <PlaceIcon sx={{ fontSize: "35px" }} />
                <h2 className="catePage-Place-name">{placeData.place}</h2>
              </div>
              <div className="catePage-HR-20"></div>
              <div className="catePage-description">
                <p className="catePage-desc">{`${setName(desc, 220)}`}</p>
              </div>
              <div className="catePage-HR-35"></div>
              <div
                className="catePage-PlanButton"
                onClick={() => planTrip(placeData)}
              >
                Plan
              </div>
            </div>
            <div className="catePage-img">
              <Slider {...settingsPicture} className="catePage-Slider-Image">
                {img.map((item, i) => (
                  <div className="catePage-Picture-wrapper" key={i}>
                    <div
                      className="catePage-Picture"
                      style={{
                        background: `url(${item}) center / cover no-repeat`,
                      }}
                    ></div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="catePage-bottom">
            <div className="catePage-details">
              <div className="cate-details-item">
                <p className="catePage-Key">Location</p>
                <p className="catePage-value">{`${placeData.locality}, ${placeData.state}`}</p>
              </div>
              <div className="cate-details-item">
                <p className="catePage-Key">Entry Fee</p>
                <p className="catePage-value">{`${placeData.entry}`}</p>
              </div>
            </div>
            <div className="catePage-details">
              <div className="cate-details-item">
                <p className="catePage-Key">Rating</p>
                <p className="catePage-value">{`${placeData.rating} (20k)`}</p>
              </div>
              <div className="cate-details-item">
                <p className="catePage-Key">Timing</p>
                <p className="catePage-value">{`${setTime(openTime, closeTime)}`}</p>
              </div>
            </div>
            <div className="catePage-cards">
              <Slider {...settings}>
                {categoryData.map((item, i) => (
                  <div className="catePage-Card-wrapper" key={i}>
                    <div
                      className={
                        cardIndex === i
                          ? "catePage-card-image catePage-card-image-active"
                          : "catePage-card-image"
                      }
                      style={{
                        background: `url(${item.IMG1}) center / cover no-repeat`,
                      }}
                    ></div>
                    <div
                      className={
                        cardIndex === i
                          ? "catePage-card-name catePage-card-name-active"
                          : "catePage-card-name"
                      }
                    >
                      <p>{setName(item.place, 10)}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CategoryPage;
