import './ErrorHandler.css';

const ErrorHandler = (error) => {
  return (
    <div className="error-handler">
      <div className="err-heading"><h3>Its look like something went wrong!</h3> <h4>Referesh Page </h4></div> 
    </div>
  )
}

export default ErrorHandler;
