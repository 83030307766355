import React from 'react';
import "./Subscription.css"
import SubscribeBar from '../../../../shared/components/subscribe-bar/SubscribeBar';

const NewsLetter = () => {

    return (
        <div className="newsletter-wrapper">
            <div className="newsletter">
                <div className="newsletter-image">
                    <div className="newsletter-overlay">
                        <div className="newsletter-heading">Love Traveling ?</div>
                        <div className="news-description">Join Us and get new awesome travel destinations every week right into your inbox.
                            <br />Travel and enjoy worry free Travel with Us.
                        </div>
                        <div className="news-subscribe">
                            <SubscribeBar/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default NewsLetter;