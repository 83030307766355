import { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getTokenError } from '../application/selectors/getToken';
import { useAddress } from '../../../shared/hooks/useAddress';
import { useNavigate } from 'react-router-dom';
import { loginLoaded } from '../application/actions/getLogin';
import { OS } from '../../../shared/utils/deviceInfo';

const LoginPageUtils = (showToast) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const token = useSelector(getToken);
    const loginError = useSelector(getTokenError);
    const dispatch = useDispatch();

    const { address } = useAddress();
    const handleSubmit = () => {
        if (username === "" || password === "") {
            showToast("info", "Invalid Login Credentials !");
        } else {
            const loginAdress = address.display_name ? address.display_name : address;
            dispatch(loginLoaded(username, password, OS(window), loginAdress));
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (token) {
            if (token && token.status){
                localStorage.setItem('xtoken', JSON.stringify(token.data))
                navigate("/");
            } else if (!token.status && token.message || loginError) {
                showToast("info", token.message);
            }
        }
    }, [navigate, token]);

    return { username, setUsername, password, setPassword, handleSubmit }
}

export default LoginPageUtils;