import React from "react";
import SearchInput from "../../components/search-input/SearchInput";
import BlogBtn from "../../components/blog-btn/BlogBtn";
import "./BlogBanner.css";
import useEnterKey from "../../../../shared/hooks/useEnterKey";

const BlogBanner = (props) => {
  const {searchkey,blogSearchHandler,handleBlogSearch}=props
  const handleKeyDown = useEnterKey(handleBlogSearch);

  return (
    <div className="blog-banner-wrapper">
      <div className="blog-banner-body">
        <div className="blog-banner-heading">Our Blogs</div>
        <div className="blog-banner-sub-heading">
          Discover the Finest Handcrafted Blogs <br />
          from TravlPoint's Talented Team
        </div>
        <div className="blog-banner-search-bar">
          <SearchInput
            placeHolder="Looking For Something .... ??"
            value={searchkey}
            onChange={blogSearchHandler}
            handleKeyDown={handleKeyDown}
          />
          <div className="handleClick" onClick={handleBlogSearch}>
            <BlogBtn btnName={"Search"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
