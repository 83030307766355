import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import './Pagination.css'

const Pagination = (props) => {
    const { visiblePageNumbers,handlePrevPage,handlePageChange,handleNextPage, currentPage } = props;

    return (
        <div className="paginations">
            <ArrowCircleLeftIcon className='pagination-icon' 
            onClick={() => handlePrevPage(currentPage - 1)} />
            <div className="pages">

                {visiblePageNumbers().map((page, index) => (
                    <div
                        key={index}
                        onClick={() => (page !== '...' ? handlePageChange(page) : null)}
                        className={currentPage === page ?"page active-page" :"page"}
                    >
                        {page}
                    </div>
                ))}
            </div>
            <ArrowCircleRightIcon className='pagination-icon'  onClick={() =>handleNextPage(currentPage + 1)} />
        </div>
    )
}

export default Pagination;