import { setLoading } from "../../../../shared/application/actions/ui";
import { WISHLIST_LOADED, wishlistFailure, wishlistSuccess } from "../actions/getWishlist";

const getWishlistFlow =({api})=>({dispatch})=>next => async(action)=>{
    next(action);

    if(action.type === WISHLIST_LOADED){
        try{
            // dispatch(setLoading(true));
            const response = await api.wishlistApi.wishlist();
            dispatch(wishlistSuccess(response));
            // dispatch(setLoading(false));
        }catch(error){
            console.log(error)
            dispatch(wishlistFailure(error))
            dispatch(setLoading(false));
        }
    }
}
 
const wishlistFlow = [
    getWishlistFlow
]
export default wishlistFlow;