//reducers
import { PLACES_TO_VISIT_BY_ID_FAILURE, PLACES_TO_VISIT_BY_ID_SUCCESS } from "../actions/getPlacesToVisitById";

const initialState = {
    placesToVisitById: null,
    error: null,
};

const PlacesToVisitByIdReducers = (state = initialState, action) => {
    switch (action.type) {
        case PLACES_TO_VISIT_BY_ID_SUCCESS:
            return {
                ...state,
                placesToVisitById: action.payload,
                error: null,
            };
        case PLACES_TO_VISIT_BY_ID_FAILURE:
            return {
                ...state,
                placesToVisitById: null,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default PlacesToVisitByIdReducers;
