import { useState } from "react";
import { validatePassword } from "../utils/ValidateUpdatePassword";
import { resetPasswordUrl } from "../../../assets/urls";

const useSecurity = (props) => {
    const { handleProfileUpdate,setLoading,setUpdate,setUpdatedetail,isValid} = props;
    const [passwordError, setPasswordError] = useState("");

    const [securityError, setSecurityError] = useState({
        OldPassword: "",
        ConfirmPassword: "",
      });
    
      const [Security, setSecurity] = useState({
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      });
    
      const handleInput = (e) => {
        let name = e.target.name;
        const value = e.target.value;
        setSecurity({ ...Security, [name]: value });
      };
    
      const UpdatePassword = async () => {
        
        const errors = validatePassword(Security);
        if (Object.keys(errors).length === 0) {
          setSecurityError("");
          handleProfileUpdate();
          setLoading(true);
          setUpdatedetail(true);
    
          const xtokens = JSON.parse(localStorage.getItem("xtoken"));
          const url = resetPasswordUrl;
          const options = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: xtokens.accessToken,
            },
            body: JSON.stringify({
              Password: Security.NewPassword,
              Old_password:Security.OldPassword,
            }),
          };
          try {
            const res = await fetch(url, options);
            const data = await res.json()
            if (res.ok) {
              isValid()
              setUpdatedetail(false);
              setTimeout(() => {
                setUpdate(false);
              }, 1000);
              setSecurity({
                OldPassword: "",
                NewPassword: "",
                ConfirmPassword: "",
              });
            }else if(res.status == 406||res.status == 409){
              setPasswordError(data.message)
              setUpdatedetail(false);
              setTimeout(() => {
                setUpdate(false);
              }, 1000);
            }else{
              throw new Error(res.message);
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          setSecurityError(errors);
        }
      };

  return {securityError,Security, handleInput, UpdatePassword,passwordError}
}

export default useSecurity;