import { loginUrl } from "../../../assets/urls"

export default {
    logIn: async (username,password,OS,address) =>{
        // console
        let options ={
            method:"POST",
            headers:{
                "Content-Type":"application/json",
            },
            body: JSON.stringify({
                "email":username,
                "password":password,
                "device":"web_app",
                "deviceID":"web_app",
                "androidVersion":OS,
                "brand":"web_app",
                "appVersion":"1.0.0",
                "address":address

          }),
        }
        
        const response  = await fetch(loginUrl,options);
        const data = await response.json();

        if(response.ok){
            return data;
        }else{
            throw new Error(data.message);
        }
    }
}