import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function PreArrow(props) {
    const { onClick } = props;
    return (
        <ArrowBackIcon
            className={"catePage-arrow catePage-arrow-pre"}
            sx={{ color: "white" }}
            onClick={onClick}
        />
    );
}

export default PreArrow;