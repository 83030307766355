import Navbar from './navbar/Navbar'
import { Outlet } from 'react-router-dom';

const MainNavbar = () => {
  return (
    <>
      <Navbar/>
      <Outlet />
    </>
  )
}

export default MainNavbar;