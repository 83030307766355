import { LIKE_BLOG_SUCCESS, LIKE_BLOG_FAILURE } from "../actions/getBlogLiked";
import { UNLIKE_BLOG_SUCCESS,UNLIKE_BLOG_FAILURE} from "../actions/getBlogUnliked";

const initialState = {
  likedBlogs: {},
  error: null,
};

const blogLikeUnlikeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIKE_BLOG_SUCCESS:
      return {
        ...state,
        likedBlogs: action.payload,
        error: null,
      };

    case LIKE_BLOG_FAILURE:
      return {
        ...state,
        error:true,
      };

    case UNLIKE_BLOG_SUCCESS:
      return {
        ...state,
        likedBlogs: action.payload,
        error: null,
      };

    case UNLIKE_BLOG_FAILURE:
      return {
        ...state,
        error:true,
      };

    default:
      return state;
  }
};

export default blogLikeUnlikeReducer;
