import {useCallback, useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { getToastData } from '../applications/selectors/getToastData';
import { toastDeleting } from '../applications/actions/getToastDeleted';

const useToast = () => {
  const dispatch = useDispatch();
  let toastlist = useSelector(getToastData);

  const deleteToast = useCallback(
    (id) => {
      if (toastlist.length > 0) {
        dispatch(toastDeleting(id))
      }

    },
    [dispatch,toastlist.length]
  );

  
  useEffect(() => {
    let isMounted = true;
    const interval = setInterval(() => {
      if (isMounted && toastlist.length > 0) {
        deleteToast(toastlist[0].id);
      }
    }, 2000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [toastlist]);

  return {toastlist,deleteToast};
}

export default useToast;