import * as uiActions from '../actions/ui'

const initialState = {
    loading:false
}

export default (state = initialState, action)=>{
    switch(action.type){
        case uiActions.SET_LOADING_ON:
            return {
                ...state, loading : action.payload
            }
        case uiActions.SET_LOADING_OFF:
            return {
                ...state, loading : action.payload
            }

        default:
            return state;
    }
}