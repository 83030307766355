//reducers
import { PACKAGE_BY_ID_FAILURE, PACKAGE_BY_ID_SUCCESS } from "../actions/getPackageById";

const initialState = {
    packageById: null,
    error: null,
};

const PackageByIdReducers = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_BY_ID_SUCCESS:
            return {
                ...state,
                packageById: action.payload,
                error: null,
            };
        case PACKAGE_BY_ID_FAILURE:
            return {
                ...state,
                packageById: null,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default PackageByIdReducers;
