import { useEffect } from "react";
import { socials } from "../../../assets/data/SocialLinksData";
import "./SocialLinks.css";
import { updateMetaTags } from "../../../features/blog-page/utils/updateMetaTags";

const SocialLinks = ({ url, title,image,description }) => {
//   useEffect(() => {
//     updateMetaTags({ title, description, image, url });
//   }, [title, description, image, url]);

  const encodeUrl = (url) => encodeURIComponent(url);
  const encodeTitle = (title) => encodeURIComponent(title);

  return (
    <div className="Container">
      <h4>Share this link via</h4>
      <div className="ShareContainer">
        {socials.map((social, index) => {
          return (
            <a
              className="Icons"
              style={{
                backgroundColor: `${social.background}`,
                color: `${social.color}`,
              }}
              href={social.href(encodeUrl(url), encodeTitle(title))}
              target="_blank"
              rel="noreferrer"
              aria-label={social.label}
              role="button"
              key={index}
            >
              {social.icon}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialLinks;
