import { TPID_FAILURE, TPID_SUCCESS } from "../actions/getTPID";

const initialState = {
    TPID:"",
    error:null
}

const TPIDReducers = (state = initialState, action)=>{
    switch (action.type) {
        case TPID_SUCCESS:
            return {
                ...state,
                TPID: action.payload,
                error: null
            }

        case TPID_FAILURE:
            return {
                ...state,
                TPID: action.payload,
                error: true
            }
        default:
            return state;
    }
}

export default TPIDReducers;