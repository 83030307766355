import React from 'react';
import './RedBtnSolid.css'
import { PlanTrip } from '../../../shared/utils/planTrip';
// import useShowToast from '../../hooks/useShowToast';

const RedBtnSolid = ({ btnName, handleSubmit, Sl, TP_ID, placeData }) => {
  const { planTrip, singlePlacePlanTrip } = PlanTrip(Sl ? Sl : TP_ID);
  
  return (
    <button className="redbtn-type2"
      onClick={btnName === "Add To Plan" ? Sl ? ()=>singlePlacePlanTrip(placeData) : planTrip : handleSubmit}
    >{btnName}</button>
  )
}

export default RedBtnSolid;