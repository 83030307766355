import React from 'react';
import './LoginImageCard.css'

const LoginImageCard = (props) => {
  const {imgUrl} = props;

  return (
    <div className="login-image-card">
      <img src={imgUrl} alt="" />
    </div>
  )
}

export default LoginImageCard;