import StarIcon from '@mui/icons-material/Star';

const ReviewCard = ({ array }) => {
    return <>
        {array.length === 0 ? <div style={{height:"80%",display:"flex",justifyContent:"center", alignItems:"center",color:"#FBCC7A"}}>No Review found!!!</div> :
            array.map((item, i) => {
                return (<div className="package_review_card" key={i}>
                    <div className="top_layer">
                        <div className="review_userdata">
                            <div className="pack_review_dp" style={{ background: `url(${item["Profile Picture"]}) center / cover no-repeat` }}></div>
                            <div className="pack_review_name">{(item["First Name"] + " " + item["Last Name"]).length > 17 ? `${(item["First Name"] + " " + item["Last Name"]).slice(0, 17)}..` : (item["First Name"] + " " + item["Last Name"])}</div>
                        </div>
                        <div className="pack_review_rating"><StarIcon style={{ fontSize: "20px", color: "#F24E1E", marginRight: "2px" }} /><p>{`${item["Rate"]}`}</p></div>
                    </div>
                    <div className="package_review_of_person">{`${item["Review"]}`}</div>
                </div>)
            })
        }
    </>
}

export default ReviewCard;