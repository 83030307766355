// Action types for getPackageById
export const PACKAGE_BY_ID_LOADING = '[package] package by id loading';
export const PACKAGE_BY_ID_SUCCESS = '[package] package by id success';
export const PACKAGE_BY_ID_FAILURE = '[package] package by id failure';


export const packageByIdLoading = (id)=> ({
    type: PACKAGE_BY_ID_LOADING,
    payload:id
})

export const packageByIdSuccess = (data) => ({
    type: PACKAGE_BY_ID_SUCCESS,
    payload: data
})

export const packageByIdFailure = (error) => ({
    type: PACKAGE_BY_ID_FAILURE,
    payload: error
})

