import { TOAST_FAILURES, TOAST_SUCCESS } from "../actions/getToast"
import { TOAST_DELETED_FAILURE, TOAST_DELETED_SUCCESS } from "../actions/getToastDeleted";

const initialState = {
    toast: [],
    error: null
}
const toastReducers = (state = initialState, action) => {
    switch (action.type) {
        case TOAST_SUCCESS:
            return {
                ...state,
                toast: [...state.toast, action.payload],
                error: null,
            }
        case TOAST_FAILURES:
            return {
                ...state,
                toast: action.payload,
                error: true
            }

        case TOAST_DELETED_SUCCESS:
            const deletedToastId = action.payload;
            const updatedToasts = state.toast.filter(toast => toast.id !== deletedToastId);

            return {
                ...state,
                toast: updatedToasts,
                error: null
            }

        case TOAST_DELETED_FAILURE:
            return {
                ...state,
                toast: action.payload,
                error: true
            }

        default:
            return state;
    }
}

export default toastReducers;