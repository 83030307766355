export const SUBSCRIPTION_LOADED= '[subscription] subscription loaded';
export const SUBSCRIPTION_SUCCESS = '[subscription] subscription success';
export const SUBSCRIPTION_FAILURE = '[subscription] subscription failure';


export const getSubscription = (email,type)=>({
    type:SUBSCRIPTION_LOADED,
    payload:{email,type},
})

export const getSubscriptionSuccess = (data)=>({
    type:SUBSCRIPTION_SUCCESS,
    payload:data,
})

export const getSubscriptionFailure = (error)=>({
    type:SUBSCRIPTION_FAILURE,
    payload:error
})