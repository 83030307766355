import "./BlogDetails.css";
import {
  Facebook,
  Instagram,
  Twitter,
  Link,
  ThumbUp,
} from "@mui/icons-material";
import useFetchBlogDetailById from "../../hooks/useFetchBlogDetailById";
import ErrorHandler from "../../../../shared/components/error/ErrorHandler";
import { lazy, Suspense } from "react";
import CopyLinkPopup from "../copy-link-popup/CopyLinkPopup";
import useCopyLinkPopupHelper from "../../hooks/useCopyLinkPopupHelper";
import useMetadata from "../../../../shared/hooks/useMetaData";
import { useHandleLikeUnlike } from "../../hooks/useHandleLikeUnlike";
import { CircularProgress } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import Footer from "../../../../shared/components/footer/Footer";
const HTMLRender = lazy(() => import("../html-rendering/HTMLRender"));

const BlogDetails = () => {
  const { BlogData, blogDetailError } = useFetchBlogDetailById();
  const { closePopup, handleClick, isPopupOpen, currentUrl } =
    useCopyLinkPopupHelper();
  const { handleLikeUnlike, loading } = useHandleLikeUnlike(BlogData);

  useMetadata({
    title: BlogData && BlogData.data["heading"],
    description: BlogData && BlogData.data["description"],
    keywords: BlogData && BlogData.data["tags"],
  });

  if (blogDetailError) {
    return <ErrorHandler />;
  }

  if (!BlogData) {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    );
  }

  const BlogCoverPicStyle = {
    backgroundImage: `url(${
      BlogData != null ? BlogData.data.coverPicture : ""
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  return (
    <>
      <div className="Blog_Detail_Body_wrapper">
        <div className="Blog_Details_Header">
          <div className="Blog_Detail_Heading">
            <h3>Published On {BlogData.data["date"]}</h3>
            <h2>{BlogData.data["heading"]}</h2>
            <div className="Blog_Header_line"></div>
          </div>
          <div className="Blog_Detail_Links">
            <div className="Blog_share_links">
              <a href={`https://www.facebook.com/sharer.php?u=${currentUrl}`}>
                {" "}
                <Facebook
                  sx={{ color: "#55CDEC", fontSize: 40 }}
                  className="Blog_Share_icons"
                />
              </a>
              <a
                href={`https://x.com/intent/tweet?url=${currentUrl}&text=${
                  BlogData && BlogData.data["heading"]
                }`}
              >
                {" "}
                <Twitter
                  sx={{ color: "#55CDEC", fontSize: 40 }}
                  className="Blog_Share_icons"
                />
              </a>
              {/* <a href="https://instagram.com/travlpoint">
                {" "}
                <Instagram
                  sx={{ color: "#FFFFFF", fontSize: 40 }}
                  className="Blog_Share_icons"
                />{" "}
              </a> */}

              <Link
                sx={{ fontSize: 40 }}
                className="Blog_Share_icons link_share"
                onClick={handleClick}
              />
            </div>
            <div className="Blog_Like_links">
              {loading ? (
                BlogData.data["isLiked"] ? (
                  <ThumbUp className="isLiked" onClick={handleLikeUnlike} />
                ) : (
                  <ThumbUpOutlinedIcon
                    className="isLiked"
                    onClick={handleLikeUnlike}
                  />
                )
              ) : (
                <CircularProgress
                  sx={{ color: "#EEEEEE", marginRight: "0.25rem" }}
                  size={24}
                />
              )}
              <h3>({BlogData.data["numberOfLikes"]})</h3>
            </div>
          </div>
        </div>
        <div className="Blog_details_body">
          <div className="Blog_Cover_Picture" style={BlogCoverPicStyle}></div>
          <Suspense
            fallback={
              <div className="Loading">
                <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
              </div>
            }
          >
            <HTMLRender htmlString={BlogData.data.content} />
          </Suspense>
        </div>
        <Footer />
      </div>
      {isPopupOpen && (
        <CopyLinkPopup
          url={currentUrl}
          onClose={closePopup}
          title={BlogData.data["heading"]}
          image={BlogData.data.coverPicture}
        />
      )}
    </>
  );
};

export default BlogDetails;
