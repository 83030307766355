import React from "react";
import "./AboutCompany.css";
import UnderlineHeading from "../../../../shared/components/underline-heading/UnderlineHeading";
import ACInfoCard from "../../components/ac-info-card/ACInfoCard";
import ReviewCard from "../../components/review-card/ReviewCard";
import { ReviewData } from "../../../../assets/data/ReviewData";

const AboutCompany = () => {
  return (
    <>
      <div className="about-company-wrapper ac-color">
        <div className="about-company">
          <div className="ac-top-sec">
            <UnderlineHeading heading={"Why Chose TravlPoint"} />
            <div className="ac-info-cards">
              <ACInfoCard
                type={"audience"}
                value={"1500+"}
                title={"Audiences"}
              />
              <ACInfoCard type={"rating"} value={"4.5"} title={"High Rating"} />
              <ACInfoCard
                type={"location"}
                value={"2000+"}
                title={"Locations"}
              />
            </div>
            <div className="ac-top-para">
              "Choose us for your ultimate travel experience as we offer a
              seamless blend of curated itineraries and personalised services,
              tailored to your preferences. Our expert team's in-depth local
              knowledge ensures that you uncover hidden gems and experience
              authentic cultural immersion.{" "}
            </div>
            <div className="ac-top-para">
              With a diverse range of handpicked accommodations and activities,
              we guarantee unforgettable moments, whether you seek adventure,
              relaxation, or cultural exploration
            </div>
            <div className="ac-top-para">
              Embrace hassle-free planning through our user-friendly platform,
              allowing you to effortlessly book and manage your entire journey.
              Elevate your travel adventures with our dedication to exceptional
              customer service and our commitment to creating cherished memories
              that last a lifetime."
            </div>
          </div>
        </div>
      </div>
      <div className="about-company-wrapper">
        <div className="about-company">
          <div className="ac-bottom-sec">
            <UnderlineHeading heading={"Let’s see what people say about Us!"} />
            <div className="review-cards">
              {ReviewData.map((data, id) => (
                <ReviewCard
                  key={id}
                  name={data.Name}
                  image={data.ProfilePicture}
                  feedBack={data.Feedback.slice(0, 92)}
                />
              ))}
              {/* <ReviewCard/>
                        <ReviewCard/> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
