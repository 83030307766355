import React from 'react';
import './PlaceName.css'
import RedBtnSolid from '../../../../shared/components/redbtn-solid/RedBtnSolid'
import { SetLength } from '../../../../shared/hooks/useSetLength';
import { ScreenWidthUpdater } from '../../../../shared/utils/screenWidthUpdater';

const PlaceName = ({ placeName, cost, type }) => {
    const {screenWidth} = ScreenWidthUpdater()
    return (
        <div className="place-name-header">
            <span>{screenWidth<600?SetLength(placeName, 12):SetLength(placeName, 20)}</span>
            {
                type === "single" ? <>
                    {
                        cost === "Paid" ?
                            <RedBtnSolid btnName={"PAID"} />
                            :
                            <div className="free">
                                <RedBtnSolid btnName={"FREE"}/>
                            </div>
                    }
                </> : <></>
            }
        </div>
    )
}

export default PlaceName;