import { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { findDate } from '../utils/findDate';
import { getCartData, getCartDataError} from '../applications/selectors/getCartData';
import { cartDataLoaded } from '../applications/actions/getCartData';
import { planTripUrl, recomendedCartUrl } from '../../../assets/urls';

const useCart = () => {
    const [startDate, setStartDate] = useState(findDate());
    const [endDate, setEndDate] = useState(findDate());
    const [adults, setAdults] = useState(2);
    const [children, setChildren] = useState(2);

    const [tpid, setTpid] = useState("");
    const [recommendedCart, setRecommendedCart] = useState([]);
    const [recomendedCartError, setRecommendedCartError] = useState("");
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    
    const [copySelectedPlaces, setCopySelectedPlaces] = useState([]);
    const [cartDataLoadedDispatched, setCartDataLoadedDispatched] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector(getCartData);
    const cartError = useSelector(getCartDataError);

    useEffect(() => {
        if (Object.keys(cart).length === 0 && !cartDataLoadedDispatched) {
            dispatch(cartDataLoaded);
            setCartDataLoadedDispatched(true);
            // handleCartDataLoaded()
        }else  if (cart["TPID"]){
            setTpid(cart["TPID"]);
            setSelectedPlaces(cart["selectedPlaces"]);
            setCopySelectedPlaces(cart["selectedPlaces"]);
        }
    }, [dispatch, cart, cartDataLoadedDispatched]);


    const fetchData = async (id = tpid) => {
        if (id !== "") {
            try {
                const response = await fetch(recomendedCartUrl(id));
                const data = await response.json();
                if(response.ok){
                    setRecommendedCart(data.data);
                }else{
                    throw new Error(data)
                }
            } catch (error) {
                setRecommendedCartError(error)
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        if((recommendedCart.length === 0)){
            fetchData();
        }
    }, [tpid]);

    const deleteCartData = () => {
        setRecommendedCart([]);
    };

    const startDateHandler = (e) => {
        setStartDate(e.target.value);
    };

    const endDateHandler = (e) => {
        setEndDate(e.target.value);
    };

    const setPeople = (number) => {
        return number < 10 ? "0" + number : number;
    };

    const addSubtract = (key, operator) => {
        if (key === "adults" && operator === "add") {
            setAdults((prevAdults) => prevAdults + 1);
        } else if (key === "adults" && operator === "sub") {
            setAdults((prevAdults) => prevAdults - 1);
        } else if (key === "children" && operator === "add") {
            setChildren((prevChildren) => prevChildren + 1);
        } else if (key === "children" && operator === "sub") {
            setChildren((prevChildren) => prevChildren - 1);
        }
    };

    const updateCopySelectedPlaces = (sl) => {
        if (copySelectedPlaces.indexOf(sl) !== -1) {
            setCopySelectedPlaces((prevArr) => prevArr.filter((place) => place !== sl));
        } else {
            setCopySelectedPlaces((prevArr) => [...prevArr, sl]);
        }
        setCartDataLoadedDispatched(false);

    };

    // const planTrip = () => {
    //     fetch(planTripUrl(tpid,copySelectedPlaces,startDate,endDate))
    //         .then((res) => res.json())
    //         .then(() => navigate("/"));
    // };

    return {
        cartError,
        selectedPlaces,
        deleteCartData,
        updateCopySelectedPlaces,
        tpid, 
        recomendedCartError,
        recommendedCart,
        startDateHandler,
        startDate,
        endDateHandler,
        endDate,
        addSubtract,
        setPeople,
        adults,
        children,
        copySelectedPlaces
    }
}

export default useCart;