import { getHomePageDataUrl } from "../../../../assets/urls"

export default {
    getAllData : async (state) =>{
        const xtokens  = localStorage.getItem('xtoken');
        let options = {
            method:"POST",
            headers:{
                'Content-Type': 'application/json;charset=utf-8',
                'Authorizations': xtokens,
            },

            body:JSON.stringify({
                location:state
            })
        }

        const response = await fetch(getHomePageDataUrl,options)
        const data = await response.json();
        if(response.ok){
           return data;
        }else{
            throw new Error(data)
        }
    }
}