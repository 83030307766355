import { usePagination } from "../../shared/hooks/usePagination";
import PackageCard from "./views/package-card/PackageCard";
import { Pagination } from "@mui/material";
import "./PackagePage.css"

const PlacesPage = (props) => {
  const Card = props.width < 1024 ? 6 : 8;
  const [
    totalPages,
    startPageIndex,
    endPageIndex,
    currentPageIndex, //eslint-disable-line
    displayPage,
  ] = usePagination(Card, props.Places.length);

  return (
    <>
      <div className="package-cards">
        {
          props.Places && props.Places.length > 0 ? (
            <>
              {(() => {
                const displayPosts = [];
                for (let i = startPageIndex; i <= endPageIndex; i++) {
                  displayPosts.push(
                    <div className="P-Card" key={props.Places[i].tpId}>
                      {
                        <PackageCard
                          PackageDetails={props.PackageDetails}
                          data={props.Places[i]}
                        />
                      }
                    </div>
                  );
                }
                return (
                  <>
                    <div
                      className="r1"
                      style={
                        displayPosts.slice(0, Card / 2).length < 4
                          ? props.width < 1024 && Card / 2 === 3
                            ? { justifyContent: "space-between" }
                            : { justifyContent: "flex-start" }
                          : { justifyContent: "space-between" }
                      }
                    >
                      {displayPosts.slice(0, Card / 2)}
                    </div>
                    <div
                      className="r2"
                      style={
                        displayPosts.slice(Card / 2, Card).length < 4
                          ? props.width < 1024 && Card / 2 === 3
                            ? { justifyContent: "space-between" }
                            : { justifyContent: "flex-start" }
                          : { justifyContent: "space-between" }
                      }
                    >
                      {displayPosts.slice(Card / 2, Card)}
                    </div>
                  </>
                );
              })()}
            </>
          ) : (
            <></>
          )
          // : <p>Loading...</p>
        }
      </div>
      <div className="Blog_Search_Pagination" style={{ margin: "24px auto" }}>
        <Pagination
          className="pagination"
          count={totalPages}
          onChange={(event, value) => displayPage(value)}
        />
      </div>
    </>
  );
};

export default PlacesPage;
