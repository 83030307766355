import "./Profile.css";
import Logout from "../../shared/components/Logout/logout";
import Copyright from "../../shared/components/copyright/Copyright";
import Security from "./views/security/Security";
import Status from "./views/status/Status";
import UpdateProfile from "./views/update-profile/UpdateProfile";
import useProfileData from "./hooks/useProfileData";

import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { CircularProgress } from "@mui/material";

const Profile = () => {
  const {
    profileData,
    Updatedetail,
    setUpdatedetail,
    loading,
    setLoading,
    update,
    setUpdate,
    handleProfileUpdate,
    isValid,
  } = useProfileData();

  if (!profileData) {
    return (
      <div className="Loading">
        <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
      </div>
    );
  }

  return (
    <>
      <div className="ProfilePage">
        <div className="Profile-top">
          <div
            className="Profile-top1"
            style={{
              backgroundImage: `url(${profileData.user_data.coverPicture})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="Profile-top-overlay">
              <div className="Pedit">
                <EditIcon
                  style={{
                    color: "#F24E1E",
                    position: "absolute",
                    right: "20px",
                    top: "10px",
                  }}
                />
              </div>
              <div className="Profile-top-right">
                <div
                  className="Profile-pic"
                  style={{
                    backgroundImage: `url(${profileData.user_data.profilePicture})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
              <div className="Profile-top-left">
                <div className="Profile-name">
                  <p>
                    {profileData.user_data.firstName}{" "}
                    {profileData.user_data.lastName}
                  </p>
                  <div className="Pedit" onClick={handleProfileUpdate}>
                    <EditIcon id="pediticon" />
                  </div>
                  <div className="Logout-btn" style={{ marginLeft: "24px" }}>
                    <Logout />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Profile-top2">
            <div className="Profile-top2a"></div>
            <div className="Profile-top2b">
              <div className="Pdetails" id="Pdetails1">
                <EmailIcon style={{ color: "#55CDEC", marginRight: "12px" }} />
                <p>{profileData.user_data.email}</p>
              </div>
              <div className="Pdetails2">
                <div className="Pdetails" id="Pdetails2a">
                  <DateRangeOutlinedIcon
                    style={{ color: "#55CDEC", marginRight: "12px" }}
                  />
                  <p>{profileData.user_data.dateOfBirth}</p>
                </div>
                <div className="Pdetails" id="Pdetails2b">
                  <PhoneIcon
                    style={{ color: "#55CDEC", marginRight: "12px" }}
                  />
                  <p> {profileData.user_data.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Profile-body">
          <div className="Profile-body-Cards">
            <div className="Profile-right">
              <Security
                handleProfileUpdate={handleProfileUpdate}
                setLoading={setLoading}
                setUpdate={setUpdate}
                setUpdatedetail={setUpdatedetail}
                isValid={isValid}
              />
            </div>
            <div className="Profile-left">
              <Status profileData={profileData} />
            </div>
          </div>
        </div>
        <Copyright />
      </div>
      {update && (
        <div className="search-popup" style={{ width: "auto" }}>
          <div className="search-overlay" onClick={handleProfileUpdate}></div>
          <div className="profile-Popup-content">
            {!loading ? (
              <>
                <UpdateProfile
                  profileData={profileData.user_data}
                  setLoading={setLoading}
                  setUpdate={setUpdate}
                  setUpdatedetail={setUpdatedetail}
                  isValid={isValid}
                />
              </>
            ) : (
              <>
                {loading && Updatedetail ? (
                  <>
                    <div className="UpdateProfile-wrapper">
                      <div
                        className="updateP-page updateP-page-align"
                        style={{ width: "auto" }}
                      >
                        <div className="Pupdate-progress">
                          <CircularProgress sx={{ color: "#fff" }} />
                        </div>
                        <div className="updates-text">
                          <p>Checking Request ... !!</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="UpdateProfile-wrapper">
                      <div
                        className="updateP-page updateP-page-align"
                        style={{ width: "auto" }}
                      >
                        <div className="Pupdate-progress">
                          <DoneAllIcon
                            style={{ fontSize: "48px", color: "#4ecb71" }}
                          />
                        </div>
                        <div className="updates-text">
                          <p style={{ textAlign: "center" }}>
                            Details Update <br /> <span>Successfully !</span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
