import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ProgressBar.css";

function progress_bar(props) {
  const value = props.percentage;
  return (
    <div className="progress_bar">
      <div className="semicircle">
        <CircularProgressbar
          value={value}
          text={`${value}%`}
          circleRatio={0.5}
          styles={{
            trail: {
              strokeLinecap: "round",
              transform: "rotate(-90deg)",
              transformOrigin: "center center",
              stroke: props.tail_color,
            },
            path: {
              strokeLinecap: "round",
              transform: "rotate(-90deg)",
              transformOrigin: "center center",
              stroke: props.stroke_color,
            },
            text: {
              fill: "#fff",
              dominantBaseline: "alphabetic",
            },
          }}
          strokeWidth={10}
        />
      </div>
      <div
        className="progress_bar_name"
        style={{ borderTop: `1px solid ${props.stroke_color}` }}
      >
        <span style={{ color: "#fff" }}>{props.name}</span>
      </div>

      <div className="info" style={{ backgroundColor: props.stroke_color }}>
        ｉ
      </div>
    </div>
  );
}

export default progress_bar;
