import React from 'react';
import './PlaceImage.css'

const PlaceImage = ({ type,imageUrl }) => {
    return (
        <div className="place-img">
            {
                type === "" ? <></> :
                    <div className={type === "single" ? "place-tag single-color" : "place-tag package-color"}>{type === "single" ? "Single" : "Package"}</div>

            }
            <img src={imageUrl} alt="" />
        </div>
    )
}

export default PlaceImage;