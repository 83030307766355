import { HOME_DATA_FAILURE, HOME_DATA_SUCCESS } from "../actions/getHomePageData";

const initialState ={
    allHomeData:{},
    error:null
};

const reducer =(state = initialState, action)=>{
    switch(action.type){
        case HOME_DATA_SUCCESS:
            return{
                ...state,
                allHomeData:action.payload,
                error:null,
            }
        
        case HOME_DATA_FAILURE:
            return {
                ...state,
                allHomeData:action.payload,
                error:true,
            }
        default:
            return state;
    }
}

export default reducer

