import './CheckBoxItem.css'

const CheckBoxItem = ({ value,boxName,checkBoxTypes,handleCheckboxChange,filterType }) => {
    return (
        <>
            <label className="chk-box-container">{boxName}
                <input
                    type="checkbox"
                    // value={value}
                    // onChange={handleChange}
                    value={value}
                    checked={checkBoxTypes.includes(value)}
                    onChange={() => handleCheckboxChange(filterType, value)}
                />
                <span className="checkmark"></span>
            </label>
        </>
    )
}

export default CheckBoxItem;