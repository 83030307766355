import React from "react";
import "./About.css";
import AboutBoards from "./views/AboutBoards/AboutBoards";
import AboutBanner from "./views/AboutBanner/AboutBanner";
import AboutReview from "./views/AboutReview/AboutReview";
import MemberDetails from "./views/MemberDetails/MemberDetails";
import AboutDetails from "./views/AboutDetails/AboutDetails";
import Footer from "../../shared/components/footer/Footer";

function AboutPage() {
  window.scrollTo(0, 0);
  return (
    <>
      <AboutBanner/>
      <AboutDetails />
      <AboutBoards />
      <MemberDetails />
      <AboutReview />
      <Footer />
    </>
  );
}

export default AboutPage;
