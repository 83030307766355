import PlaceImage from '../../components/place-image/PlaceImage';
import PlaceName from '../../components/place-name/PlaceName';
import RatingStar from '../../../../shared/components/rating-star/RatingStar';
import PlaceTiming from '../../components/place-timing/PlaceTiming';
import "./MostVisitedCard.css"
import { SetLength } from '../../../../shared/hooks/useSetLength';
import usePackageDetails from '../../../../shared/hooks/usePackageDetails';

const MostVisitedCard = ({type,data,getPopUpData}) => {
  const {uniqueIdentifier,sl,tpId,place,rating,openingTime,closingTime,imageUrl, overView,entry} = data;
  const {packageDetails} = usePackageDetails();

  return (
    <div className="most-visited-card">
      <div className="recent-place-img" onClick={()=>(sl&&tpId)?getPopUpData(sl):packageDetails(uniqueIdentifier)}>
        <PlaceImage type={type} imageUrl={imageUrl}/>
      </div>
        <div className="most-visited-place-detail">
          <PlaceName placeName={SetLength(place,16)} cost={JSON.stringify(entry)} type={type}/>
          <RatingStar rating={rating}/>
          {
            type === "single"?<>
            <PlaceTiming type = {type} openingTime={openingTime} closingTime={closingTime}/>
            </>:
            <div className="mvp-description">
             {overView && SetLength(overView,65)}
            </div>
          }
        </div>
    </div>
  )
}

export default MostVisitedCard;