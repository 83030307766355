import { searchWithFilterUrl } from "../../../assets/urls"

export default {
    getFilterData : async (filters) =>{
        const {query,pageNo,state,category,entryType,rating,searchType} = filters;
        // console.log("filterApi",filters, category)

        let options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json",
            }
        }

        let params = {
            "q": query,
            "page_no": pageNo,
            "state": state,
            "categories": category,
            "entry": entryType,
            "rating": rating,
            "search_type": searchType,
        }

        let queries = Object.keys(params)
        .map(k =>{ 
            if (k === "state"){
                return encodeURIComponent(k) + '=' + `[${params[k]}]`;
              }else if(k ==="categories" && Array.isArray(params[k])){
                return encodeURIComponent(k) + '=' + encodeURIComponent(JSON.stringify(params[k]));
              }
              else if((k==="entry" && params[k].length>1) || (k ==="search_type"&& params[k].length>1)){
                return encodeURIComponent(k) + '=' ;
              }
              else{
                return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
            }
        }).join('&');

        const response = await fetch(searchWithFilterUrl+queries,options);
        const data = await response.json();

        if(response.ok){
            return data
        }else{
            throw new Error(data)
        }

    }
}