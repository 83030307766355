import './PhotoPreview.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import Slider from "react-slick";

function PreArrow(props) {
  const { onClick } = props;
  return (<div className='arrow-circle pre-arrow' onClick={onClick}>
    <ChevronLeftIcon style={{ color: "#FBCC7A", fontSize: "6vmin" }} />
  </div>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (<div className='arrow-circle next-arrow' onClick={onClick}>
    <ChevronRightIcon style={{ color: "#FBCC7A", fontSize: "6vmin" }} />
  </div>
  );
}

const PhotoPreview = (props) => {
  const [image, setImage] = useState(0)
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    lazyLoad: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PreArrow />,
    beforeChange: (current, next) => {
      setImage(next)
    },
  };
  // return <div className="photo-preview-bg" onClick={props.previewHandler}>
  return <div className="photo-preview-bg">
    <div className='arrow-circle back-button' onClick={props.previewHandler}>
      <ClearIcon style={{ color: "#FBCC7A", fontSize: "4.5vmin" }} onClick={props.previewHandler}/>
    </div>
    <Slider {...settings} className="preview-slider-wrapper">
      {
        props.img.map((element, i) => {
          return <div className="picture-wrapper" key={i}><div className="active-image-preview" style={{ background: `url(${element}) center / cover no-repeat`, borderRadius: "1vmin" }}></div></div>
        })
      }
    </Slider>
    <div className="select-image">
      {
        props.img.map((element, i) => {
          return <div className={image === i ? "select-element active-element" : "select-element"} key={i} style={{ background: `url(${element}) center / cover no-repeat`, borderRadius: "0.5vmin" }} ></div>
        })
      }
    </div>
  </div>
}

export default PhotoPreview;