// Action types for getPlacesToVisitById
export const PLACES_TO_VISIT_BY_ID_LOADING = '[package] places to visit by id loading';
export const PLACES_TO_VISIT_BY_ID_SUCCESS = '[package] places to visit by id success';
export const PLACES_TO_VISIT_BY_ID_FAILURE = '[package] places to visit by id failure';


export const placesToVisitByIdLoading =(id)=> ({
    type: PLACES_TO_VISIT_BY_ID_LOADING,
    payload:id
})

export const placesToVisitByIdSuccess = (data) => ({
    type: PLACES_TO_VISIT_BY_ID_SUCCESS,
    payload: data
})

export const placesToVisitByIdFailure = (error) => ({
    type: PLACES_TO_VISIT_BY_ID_FAILURE,
    payload: error
})
