import { useEffect,useMemo, useState } from 'react'

const useMemoizedState = (getStoreData) => {
    const [data, setData] = useState([]);
    const memoizedData = useMemo(() => getStoreData, [getStoreData])

    const updateData = () => {
        setData(memoizedData);
    }
    useEffect(() => {
        updateData();
    }, [memoizedData])

  return {data}
}

export default useMemoizedState