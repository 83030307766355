import { packageWishlistItemsUrl } from "../../../assets/urls";

export default {
    packageWishlistItems: async () => {
        const xtokens = JSON.parse(localStorage.getItem("xtoken"));

        const options = {
            method: "GET",
            headers: {
                "Authorization": xtokens.accessToken
            }
        }

        const response = await fetch(packageWishlistItemsUrl, options);
        const data = response.json();

        if (response.ok) {
            return data;
        } else {
            throw new Error(data);
        }
    }
}