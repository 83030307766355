// Action types for getNewPlacePackage
export const NEW_PLACE_PACKAGE_LOADING = '[package] new place package loading';
export const NEW_PLACE_PACKAGE_SUCCESS = '[package] new place package success';
export const NEW_PLACE_PACKAGE_FAILURE = '[package] new place package failure';

export const newPlacePackageLoading ={
    type: NEW_PLACE_PACKAGE_LOADING,
}

export const newPlacePackageSuccess = (data) => ({
    type: NEW_PLACE_PACKAGE_SUCCESS,
    payload: data
})

export const newPlacePackageFailure = (error) => ({
    type: NEW_PLACE_PACKAGE_FAILURE,
    payload: error
})

