import HeadingPara from '../../../../shared/components/heading-para/HeadingPara';
import './Destinations.css'
import TopPlaceCard from '../../components/top-place-card/TopPlaceCard';
import { useSelector } from 'react-redux';
import { getTrendingData } from '../../../../shared/application/selectors/homeData';
import useCustomSlice from '../../hooks/useCustomSlice';
import useMemoizedState from '../../hooks/useMemoizedState';

const Destinations = () => {
    const getDestinationData = useSelector(getTrendingData);
    const {Slice} = useCustomSlice();
    const { data} = useMemoizedState(getDestinationData);
    let destination = data;

    if (destination && destination.length === 0) {
        return (
            <div className="Loading">
                <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
            </div>
        )
    }
    
    return (
        <div className="destination-wrapper">
            <div className="destinations">
                <div className="destination-heading">
                    <HeadingPara heading={"Top Destinations"} subHeading={"Explore the top handcrafted places by TravlPoint Experts"} />
                </div>
                <div className="top-destination-cards">
                    {
                       destination
                            .slice(0, Slice)
                            .map((data, id) => (
                                <div className="destination-card" key={id}>
                                    <TopPlaceCard
                                        tpId={data.tpId}
                                        image={data.coverPicture}
                                        name={data.name}
                                        rating={data.rating}
                                        totalPlaces={data.totalPlaces}
                                        address={data.address}
                                    />
                                </div>
                            ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Destinations;