export const CART_DATA_LOADED = '[cart] cart data loaded';
export const CART_DATA_SUCCESS = '[cart] cart data success';
export const CART_DATA_FAILURE = '[cart] cart data failure';

export const cartDataLoaded = {
    type:CART_DATA_LOADED,
}

export const cartDataSuccess = (data) =>({
    type:CART_DATA_SUCCESS,
    payload:data,
})

export const cartDataFailure = (error) =>({
    type:CART_DATA_FAILURE,
    payload:error,
})