import React, { useEffect } from "react";
import "./PackagePage.css";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";

import Copyright from "../../shared/components/copyright/Copyright";
import { ScreenWidthUpdater } from "../../shared/utils/screenWidthUpdater";
import usePackageDetails from "../../shared/hooks/usePackageDetails";
import PromotedCard from "./views/promoted-card/PromotedCard";
import PlacesPage from "./PlacesPage";
import { trendingPackageLoading } from "./applications/actions/getTrendingPackage";
import { newPlacePackageLoading } from "./applications/actions/getNewPlacesPackage";
import { getTrendingPackageData, getNewPlacePackageData, getTrendingPackageDataError, getNewPlacePackageDataError } from "./applications/selectors/packageData";
import ErrorHandler from "../../shared/components/error/ErrorHandler";

const PackagePage = () => {
  const { packageDetails } = usePackageDetails();
  const { screenWidth } = ScreenWidthUpdater();
  let width = screenWidth;

  const dispatch = useDispatch();
  const trendPlaces = useSelector(getTrendingPackageData);
  const newPlaces = useSelector(getNewPlacePackageData);
  const trendError = useSelector(getTrendingPackageDataError);
  const newPlaceError = useSelector(getNewPlacePackageDataError);

  useEffect(() => {
    if (trendPlaces.length === 0 || newPlaces.length === 0) {
      dispatch(trendingPackageLoading);
      dispatch(newPlacePackageLoading)
    }

    // getTrendData 
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: width <= 600 ? 1.2 : 2,
    slidesToScroll: width <= 600 ? 1 : 2,
    autoplay: true,
    arrows: false,
    centerPadding: width <= 600 ? "36px" : "70px",
    className: "start",
    centerMode: width <= 600 ? true : false,
  };

  if (!(trendPlaces && newPlaces)) {
    return (
      <>
        <div className="Loading">
          <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
        </div>
      </>
    );
  }

  if (trendError && newPlaceError) {
    return <ErrorHandler />
  }

  return (
    <>
      <div className="package-wrapper">
        <div className="package-banner">
          <div className="package-info">
            <p className="title-1">Most Loved!</p>
            <h3 className="title-2">HandCrafted Packages</h3>
            <div className="package-search-icon">
              <TravelExploreIcon className="P-search-icon" />
            </div>
          </div>
          <div className="package-promoted-cards">
            {
              trendError && !trendPlaces.length>0 ?
                <ErrorHandler />
                :
                <Slider {...settings} style={{ width: "100%" }}>
                  {trendPlaces&&trendPlaces.slice(0, 5).map((data, i) => (
                    <div key={i} style={{ width: "280px" }}>
                      <PromotedCard
                        data={data}
                        star={i}
                        PackageDetails={packageDetails}
                      />
                    </div>
                  ))}
                </Slider>
            }
          </div>
        </div>
        <div className="packages-type">
          <div className="package-title">Popular Packages</div>
          {
            trendError && !trendPlaces ?
              <ErrorHandler />
              :
              <PlacesPage
                Places={trendPlaces}
                PackageDetails={packageDetails}
                width={width}
              />
          }
        </div>
        <div className="packages-type">
          <div className="package-title">Newly Added Packages</div>
          {
            newPlaceError && !(newPlaces.length>0) ?
              <ErrorHandler /> :
              <PlacesPage
                Places={newPlaces}
                PackageDetails={packageDetails}
                width={width}
              />
          }
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default PackagePage;
