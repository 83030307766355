import { SINGLE_PLACE_DATA_FAILURE, SINGLE_PLACE_DATA_REMOVED, SINGLE_PLACE_DATA_REMOVE_FAILURE, SINGLE_PLACE_DATA_SUCCESS } from "../actions/getSinglePlaceData"

const initialState = {
    singlePlaceData:{},
    error:null,
}

const SinglePlaceReducer = (state=initialState, action)=>{
    switch(action.type){
        case SINGLE_PLACE_DATA_SUCCESS:
            return{
                ...state,
                singlePlaceData:action.payload,
                error:null
            }

        case SINGLE_PLACE_DATA_FAILURE:
            return{
                ...state,
                singlePlaceData:action.payload,
                error:true,
            }
        
        case SINGLE_PLACE_DATA_REMOVED:
            return{
                singlePlaceData:{},
                error:null
            }
        
        case SINGLE_PLACE_DATA_REMOVE_FAILURE:
            return{
                singlePlaceData:action.payload,
                error:true
            }

        default:
            return state;

    }
}

export default SinglePlaceReducer;