import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import UseWhislist from "../../../features/wishlist/hooks/useWhislist";

function Whislist(props) {
  const { handlingWishlist, IsFavorite } = UseWhislist(props);

  const FavouriteSize =
    window.outerWidth < 600 ? (props.package ? 40 : 28) : 38;
    
  const FavouriteStyle = {
    fontSize: `${FavouriteSize}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#F24E1E",
    cursor:"pointer"
  };

  return (
    <>
      <div className="whish_list" onClick={handlingWishlist}>
        {IsFavorite ? (
          <FavoriteIcon sx={FavouriteStyle} />
        ) : (
          <FavoriteBorderIcon sx={FavouriteStyle} />
        )}
      </div>
    </>
  );
}

export default Whislist;
