import { useState } from "react";
import "./SearchPage.css";
import SearchBar from "../../../shared/components/search-bar/SearchBar";
import Footer from "../../../shared/components/footer/Footer";
import SearchFilter from "./search-filter/SearchFilter";
import SearchResult from "./search-result/SearchResult";
import MostVisited from "./most-visited/MostVisited";
import TuneIcon from "@mui/icons-material/Tune";
import { Close } from "@mui/icons-material";
// import { useLocalStorage } from "../hooks/useLocalStorage";
import Pagination from "../../../shared/components/pagination/Pagination";
import { useSelector } from "react-redux";
import { getNoOfResult } from "../application/selectors/getSearchData";
import {
  singlePlaceDetails,
} from "../../single-place-page/applications/selectors/getSinglePlaceData";
import SinglePlace from "../../single-place-page/views/SinglePlace";
import usePagination from "../hooks/usePagination";

const SearchPage = () => {
  const filterData = useSelector(getNoOfResult);
  const placedata = useSelector(singlePlaceDetails);

  const [mobfilter, SetMobFilter] = useState(false);
  const {
    visiblePageNumbers,
    handlePrevPage,
    handlePageChange,
    handleNextPage,
    currentPage,
  } = usePagination();

  const handelMobFilterToggle = () => {
    SetMobFilter(!mobfilter);
  };

  /*use local Storage*/
  // const { handleAddObject } = useLocalStorage();

  if (placedata&&placedata.status) {
    document.body.classList.add("scroll-lock");
  } else {
    document.body.classList.remove("scroll-lock");
  }

  if (placedata&&placedata.status&&!placedata.data ) {
    return (
      <>
        {/* <Navbar /> */}
        <div className="Loading">
          <img src="assets/images/Icon_Animation.gif" alt="logo"></img>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="search-page-wrapper">
        <div className="search-page">
          <div className="search-header">
            <div className="searchbar-filter">
              <SearchBar />
              <div className="mob-filter" onClick={handelMobFilterToggle}>
                <TuneIcon className="mob-filter-icon" />
              </div>
            </div>
          </div>
          <div className="search-body">
            <div className="search-body-content">
              <div className="search-filter-sec">
                <SearchFilter />
              </div>
              <div className="search-result">
                <SearchResult />
                {filterData ? (
                  <Pagination
                    visiblePageNumbers={visiblePageNumbers}
                    handlePrevPage={handlePrevPage}
                    handlePageChange={handlePageChange}
                    handleNextPage={handleNextPage}
                    currentPage={currentPage}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="seacrh-most-visited-content">
                <MostVisited />
              </div>
            </div>
          </div>
          <div className="search-footer">
            <Footer />
          </div>
        </div>
        {mobfilter ? (
          <div className="mob-search-filter-popup">
            <div className="filter-close" onClick={handelMobFilterToggle}>
              <Close className="filter-close-icon" />
            </div>
            <SearchFilter handelMobFilterToggle={handelMobFilterToggle} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {placedata&&placedata.status  && (
          <div className="search-popup">
            <SinglePlace />
          </div>
        )}
    </>
  );
};

export default SearchPage;
