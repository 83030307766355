//reducers
import { NEW_PLACE_PACKAGE_FAILURE, NEW_PLACE_PACKAGE_SUCCESS } from "../actions/getNewPlacesPackage";

const initialState = {
    newPlacePackage: [],
    error: null,
};

const NewPlacePackageReducers = (state = initialState, action) => {
    switch (action.type) {
        case NEW_PLACE_PACKAGE_SUCCESS:
            return {
                ...state,
                newPlacePackage: action.payload,
                error: null,
            };
        case NEW_PLACE_PACKAGE_FAILURE:
            return {
                ...state,
                newPlacePackage: action.payload,
                error: true,
            };

        default:
            return state;
    }
};

export default NewPlacePackageReducers;
