import { cartUrl, recomendedCartUrl } from "../../../assets/urls";

export default {
    cartData: async () => {
        if(localStorage.getItem("xtoken")){
            localStorage.removeItem("Selected-Places");
            localStorage.removeItem("TPID");

            let res = await fetch(cartUrl, {
                method: "GET",
                headers: {
                    "Authorization": JSON.parse(localStorage.getItem('xtoken')).accessToken
                }
            });

            let response = await res.json();
            if (res.status === 200) {
                return{
                    TPID: response.data["tpId"],
                    selectedPlaces: response.data["selectedPlaces"],
                };
            } else if (res.status === 401) {
                console.log("Access Token Expired")
            }else{
                throw new Error(response)
            }

        }else{
            return {
                TPID: localStorage.getItem("TPID"),
                selectedPlaces: JSON.parse(localStorage.getItem("Selected-Places")),
            };
        }
    },

    recomendedCartData:async (id)=>{
        const options = {
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            }
        }

        const response = await fetch(recomendedCartUrl(id),options).then(res => res.json());
        return response;
    }
}