import { setLoading } from "../../../../shared/application/actions/ui";
import { NEW_PLACE_PACKAGE_LOADING, newPlacePackageFailure, newPlacePackageSuccess } from "../actions/getNewPlacesPackage";

const newPlacePackageFlow = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    next(action);

    if (action.type === NEW_PLACE_PACKAGE_LOADING) {
        try {
            dispatch(setLoading(true));
            const response = await api.packageApis.newPlacePackage();
            dispatch(newPlacePackageSuccess(response));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(newPlacePackageFailure(error));
            dispatch(setLoading(false));
        }
    }
};

const newPackgeFLow = [newPlacePackageFlow];

export default newPackgeFLow
