import React from 'react'
import './AboutDetails.css'
import GroupsIcon from '@mui/icons-material/Groups';
import SpeedIcon from '@mui/icons-material/Speed';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import BugReportIcon from '@mui/icons-material/BugReport';

const AboutDetails = () => {
    return (
        <div className="AboutDetails-wrapper" id="aboutUs">
            <div className="Abt_Details">
                <div className="Abt-Details-left">
                    <div className="Abt-tilte">About us</div>
                    <div className="Abt-heading">We aim to revolutionize the tourism industry in India</div>
                    <div className="Abt-body">
                        <p>Travlpoint was founded by Abhijeet Bhardwaj, Vivek Kumar Gupta and Avinash Kumar in 2020 to develop tourism in India using Artificial Intelligence and Smart Guides and the purpose for which we are working reduce our environmental impact and promote our cultural heritage. We believe that consistent, ethical and quality performance with our customers, vendors, employees and community will result in loyalty and long lasting relationships.
                        </p>
                    </div>
                    <div className="Abt-bottom">
                    </div>
                    <div className="Abt-circle"></div>
                    <div className="Abt-info">
                        <div className="Abt-info-cards">
                            <div className="Abt-info-card">
                                <div className="Abt-info-logo">
                                    <GroupsIcon style={{ fontSize: "50px", color: "#f1c40f" }} />
                                </div>
                                <p id='Abt-card-details'>77</p>
                                <p id='Abt-card-title'>Members</p>
                            </div>
                            <hr />
                            <div className="Abt-info-card">
                                <div className="Abt-info-logo">
                                    <DomainAddIcon style={{ fontSize: "50px", color: "#f1c40f" }} />
                                </div>
                                <p id='Abt-card-details'>10</p>
                                <p id='Abt-card-title'>States</p>
                            </div>
                            <hr />
                            <div className="Abt-info-card">
                                <div className="Abt-info-logo">
                                    <BugReportIcon style={{ fontSize: "50px", color: "#f1c40f" }} />
                                </div>
                                <p id='Abt-card-details'>50</p>
                                <p id='Abt-card-title'>Alpha Tester</p>
                            </div>
                            <hr />
                            <div className="Abt-info-card">
                                <div className="Abt-info-logo">
                                    <SpeedIcon style={{ fontSize: "50px", color: "#f1c40f" }} />
                                </div>
                                <p id='Abt-card-details'>67</p>
                                <p id='Abt-card-title'>Beta Tester</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Abt-Details-right">
                    <div className="Abt-Cright">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutDetails