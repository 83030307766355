export const REFRESH_TOKEN_LOADED = '[token] refresh token loaded';
export const REFRESH_TOKEN_SUCCESS = '[token] refresh token success';
export const REFRESH_TOKEN_FAILURE = '[token] refresh token failure';

export const refreshTokenLoaded = ( rToken) =>({
    type: REFRESH_TOKEN_LOADED,
    payload:  rToken
})

export const refreshTokenSuccess = (data)=>({
    type:REFRESH_TOKEN_SUCCESS,
    payload:data
})

export const refreshTokenFailure = (error)=>({
    type:REFRESH_TOKEN_FAILURE,
    payload:error
})