// Action types for getPackageReviewById
export const PACKAGE_REVIEW_BY_ID_LOADING = '[package] package review by id loading';
export const PACKAGE_REVIEW_BY_ID_SUCCESS = '[package] package review by id success';
export const PACKAGE_REVIEW_BY_ID_FAILURE = '[package] package review by id failure';

export const packageReviewByIdLoading =(id)=> ({
    type: PACKAGE_REVIEW_BY_ID_LOADING,
    payload:id
}
)
export const packageReviewByIdSuccess = (data) => ({
    type: PACKAGE_REVIEW_BY_ID_SUCCESS,
    payload: data
})

export const packageReviewByIdFailure = (error) => ({
    type: PACKAGE_REVIEW_BY_ID_FAILURE,
    payload: error
})
