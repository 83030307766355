import { useState } from 'react'

const useCheckBoxFilter = () => {
    //////////////////////// Entry type Filtration function /////////////////////////////////////
    const [entryTypes, setEntryTypes] = useState([]);
    const [searchTypes, setSearchTypes] = useState([]);

    const handleCheckboxChange = (type, value) => {
        if (value === 'All') {
            // If 'All' checkbox is selected, select all checkboxes in the group
            if (type === 'entryTypes') {
                setEntryTypes(['All', 'Free', 'Paid']);
            } else if (type === 'searchTypes') {
                setSearchTypes(['All', 'Single Places', 'Packages']);
            }
        } else {
            // If any other checkbox is selected, update the respective group
            if (type === 'entryTypes') {
                const updatedEntryTypes = entryTypes.includes('All')
                    ? [value]
                    : entryTypes.includes(value)
                        ? entryTypes.filter(type => type !== value)
                        : [...entryTypes, value];

                setEntryTypes(updatedEntryTypes);
            } else if (type === 'searchTypes') {
                const updatedSearchTypes = searchTypes.includes('All')
                    ? [value]
                    : searchTypes.includes(value)
                        ? searchTypes.filter(type => type !== value)
                        : [...searchTypes, value];

                setSearchTypes(updatedSearchTypes);
            }
        }
    };

    const handleEntryClear=()=>{
        setEntryTypes([])
    }
    const handleSearchClear=()=>{
       setSearchTypes([])
    }
    return {entryTypes,searchTypes,handleCheckboxChange, handleEntryClear, handleSearchClear}
}

export default useCheckBoxFilter