import { BLOG_DATA_FAILURES, BLOG_DATA_SUCCESS } from "../actions/getBlogsData";

const initialState = {
    blogs:[],
    error:null
}

const BlogReducers = (state=initialState, action) =>{
    switch(action.type){
        case BLOG_DATA_SUCCESS:
            return {
                ...state,
                blogs:action.payload,
                error:null,
            }

        case BLOG_DATA_FAILURES:
            return {
                ...state,
                blogs:action.payload,
                error:true,
            }
        
        default:
            return state;
    }
}

export default BlogReducers;