import { useState } from "react"

const useCopyLinkPopupHelper = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');
  
    const handleClick = () => {
      setCurrentUrl(window.location.href);
      setPopupOpen(true);
    };
  
    const closePopup = () => {
      setPopupOpen(false);
    };
  return {closePopup,handleClick,isPopupOpen,currentUrl }
}

export default useCopyLinkPopupHelper