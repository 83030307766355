export const FILTER_DATA_LOADED = '[search] search data loaded';
export const FILTER_DATA_SUCCESS = '[search] search data success';
export const FILTER_DATA_FAILURE = '[search] search data failure';

export const filterDataLoaded = (filters)=>({
    type:FILTER_DATA_LOADED,
    payload:filters
})

export const filterDataSuccess = (data)=>({
    type:FILTER_DATA_SUCCESS,
    payload:data
})

export const filterDataFailure = (error)=>({
    type:FILTER_DATA_FAILURE,
    payload:error
})