import { getBlogSubscriptionUrl, getSubscriptionUrl } from "../../../../assets/urls";

export default {
    getSubscription : async (email) =>{
        let options = {
            method:"POST",
            headers:{
                'Content-Type': 'application/json;charset=utf-8',
            },

            body:JSON.stringify({
                Email:email
            })
        }

        const response = await fetch(getSubscriptionUrl,options);
        const data = await response.json();
        if(response.ok){
            return data;

        }else{
            throw new Error(data);
        }
    },

    getBlogSubscription : async (email) =>{
        let options = {
            method:"POST",
            headers:{
                'Content-Type': 'application/json;charset=utf-8',
            },

            body:JSON.stringify({
                Email:email
            })
        }

        const response = await fetch(getBlogSubscriptionUrl,options)
        if(response.ok){
            let data = await response.json();
            return data;
        }else{
            throw new Error(response.json());
        }
    }
}