export const UNLIKE_BLOG_LOADING = '[ blog unlike ] unlike blog loading';
export const UNLIKE_BLOG_SUCCESS = '[ blog unlike ] unlike blog success';
export const UNLIKE_BLOG_FAILURE = '[ blog unlike ] unlike blog failure';

export const unlikeBlogLoading = (blogId) => ({
    type: UNLIKE_BLOG_LOADING,
    payload: { blogId }
});

export const unlikeBlogSuccess = (blogId, updatedData) => ({
    type: UNLIKE_BLOG_SUCCESS,
    payload: { blogId, updatedData }
});

export const unlikeBlogFailure = (blogId, error) => ({
    type: UNLIKE_BLOG_FAILURE,
    payload: { blogId, error }
});