import { useState } from 'react'
import { useLocation } from 'react-router-dom';

const useSearchKey = () => {
  const location = useLocation();
  const [searchKey, setSearchKey] = useState(location.pathname==="/"?"":localStorage.getItem("SearchKey"));

  const SearchHandler = (e) => {
    setSearchKey(e.target.value)
    localStorage.setItem("SearchKey", e.target.value)
  }

  return { searchKey, SearchHandler }
}

export default useSearchKey;