import { useDispatch, useSelector } from "react-redux";
import { getToastData } from "../../features/Toast/applications/selectors/getToastData";
import { toastLoaded } from "../../features/Toast/applications/actions/getToast";

const useShowToast = () => {
    const toastlist = useSelector(getToastData);
    const dispatch = useDispatch();
    const showToast = (type, description) => {
        let id = toastlist.length + 1;
        dispatch(toastLoaded(id, type, description))
    }

    return {showToast};
}

export default useShowToast;
